<template>
  <div class="flex items-center cursor-pointer">
    <label class="text-gray-700 font-medium relative flex flex__align-center">
      <span v-if="title && titleLeft" class="text-sm leading-tight text-gray-700 truncate mr-3 vcheckbox-title">{{ title }}</span>

      <div class="relative">
        <input
          v-model="val"
          :id="id || name"
          :name="name"
          :value="value"
          type="checkbox"
          :disabled="disabled"
          class="hidden"
        >
        <div class="toggle__line w-10 h-5 bg-gray-300 mr-2 border-radius" :class="{'no-branding': ! branding}" />
        <div class="toggle__dot absolute w-4 h-4 bg-white rounded-full inset-y-0" />
      </div>
      <span v-if="title && !titleLeft" class="text-sm leading-tight text-gray-700 truncate vcheckbox-title">{{ title }}</span>

      <slot />
    </label>
  </div>
</template>

<script>
export default {
    name: 'V-Checkbox',

    props: {
        value: {
            type: [Number, Boolean],
            default: false
        },
        id: { type: String,},
        name: { type: [String, Number] },
        disabled: { type: Boolean, default: false },
        branding: { type: Boolean, default: true, required: false },
        returnNumber: { type: Boolean, default: true },
        title: { type: String },
        titleLeft:  { type: Boolean, default: false, }
    },
    model: {
        prop: 'value',
        event: 'click'
    },
    data() {
        return {
            val: this.value
        }
    },
    watch: {
        value() {
          if (this.value != this.val) {
            this.val = this.value ? 1 : 0
          }
        },
        val(value) {
            if (this.returnNumber) {
                this.$emit('click', value ? 1 : 0);

                return;
            }

            this.$emit('click', value);
        }
    }
}
</script>

<style lang="scss" scoped>
.toggle__dot {
  top: .13rem;
  left: .13rem;
  transition: all 0.3s ease-in-out;
}

.toggle__line {
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__line {
  @apply bg-gray-300;
}

input:checked ~ .toggle__dot {
  transform: translateX(125%);
}

input:not(:disabled) ~ .no-branding.toggle__line {
    @apply bg-blue-900 #{!important};
}
</style>
