<template>
    <svg
        fill="none"
        height="50"
        viewBox="0 0 54 27"
        width="140"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="bars">
            <g id="row05">
                <path
                    d="M16.437 27H13.563C13.2667 27 13 26.7447 13 26.3936V24.6064C13 24.2872 13.237 24 13.563 24H16.437C16.7333 24 17 24.2553 17 24.6064V26.3936C16.9704 26.7128 16.7333 27 16.437 27Z"
                    fill="#297131"
                    id="dot-05"
                />
                <rect fill="#297131" height="3" id="bar-05" rx="0.7" width="35" x="19" y="24" />
            </g>
            <g id="row04">
                <path
                    d="M32.437 21H29.563C29.2667 21 29 20.7447 29 20.3936V18.6064C29 18.2872 29.237 18 29.563 18H32.437C32.7333 18 33 18.2553 33 18.6064V20.3936C33 20.7128 32.7333 21 32.437 21Z"
                    fill="#20984B"
                    id="dot-04"
                />
                <rect fill="#20984B" height="3" id="bar-04" rx="0.7" width="19" x="35" y="18" />
            </g>
            <g id="row03">
                <path
                    d="M9.43704 15H6.56296C6.26667 15 6 14.7447 6 14.3936V12.6064C6 12.2872 6.23704 12 6.56296 12H9.43704C9.73333 12 10 12.2553 10 12.6064V14.3936C10 14.7128 9.73333 15 9.43704 15Z"
                    fill="#3DA248"
                    id="dot-03"
                />
                <rect fill="#3DA248" height="3" id="bar-03" rx="0.7" width="42" x="12" y="12" />
            </g>
            <g id="row02">
                <path
                    d="M22.437 9H19.563C19.2667 9 19 8.74468 19 8.39362V6.60638C19 6.28723 19.237 6 19.563 6H22.437C22.7333 6 23 6.25532 23 6.60638V8.39362C22.9704 8.74468 22.7333 9 22.437 9Z"
                    fill="#4CB855"
                    id="dot-02"
                />
                <rect fill="#4CB855" height="3" id="bar-02" rx="0.7" width="29" x="25" y="6" />
            </g>
            <g id="row01">
                <path
                    d="M18.437 3H15.563C15.2667 3 15 2.74468 15 2.39362V0.606383C15 0.287234 15.237 0 15.563 0H18.437C18.7333 0 19 0.25532 19 0.606383V2.39362C19 2.74468 18.7333 3 18.437 3Z"
                    fill="#7FC67C"
                    id="dot-01"
                />
                <rect fill="#7FC67C" height="3" id="bar-01" rx="0.7" width="33" x="21" />
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'SpinningClock',
}
</script>

<style lang="scss" scoped>
path {
    fill: var(--brand-color) !important;
}

rect {
    fill: var(--brand-color) !important;
}
</style>
