<template>
    <div class="modal-field flex flex-col" :class="{ compact, required }">
        <span v-if="required && compact" class="modal-field__required text-red-500">*</span>
        <div v-if="!compact" class="flex">
            <span class="text-additional color-text-secondary truncate text-draggable">
                {{ label }}
            </span>
            <span class="modal-field__required text-red-500" v-if="required">*</span>
        </div>
        <div :class="bodyClass">
            <slot/>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModalField',
    props: {
        label: String,
        noMargin: Boolean,
        required: Boolean,
        compact: Boolean,
        bodyClass: {
            required: false,
            default: 'relative text-body',
            type: String
        }
    },
}
</script>
<style lang="scss">
.modal-field {
    &.required {
        &.empty {
            input {
                &[type="text"], &[type="number"] {
                    border-color: #000;
                }
            }
    
            .autocomplete input:not(.autocomplete-input-invalid) {
                &[type="text"], &[type="number"] {
                    border-color: #000 !important;
                }
            }
    
            .multiselect__tags {
                border-color: #000;
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.modal-field {
    position: relative;

    &.compact {
        .modal-field__required {
            position: absolute;
            left: -8px;
            top: 0px;
        }
    }
}
</style>