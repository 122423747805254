<template>
    <div>
        <div :style="'position: relative; width:100%; height: ' + height + 'px'">
            <div v-if="loading" class="absolute w-full h-full flex  items-center justify-center">
                <fa :icon="['fad', 'spinner-third']" class="brand-primary fa-spin text-4xl" />
            </div>
            <vue-svg-gauge
                v-if="!loading"
                :separator-thickness="separatorThickness"
                :inner-radius="innerRadius"
                :start-angle="-angle"
                :end-angle="angle"
                :min="min"
                :max="max"
                :value="value"
                :gauge-color="gaugeColor">
                <div class="absolute bottom-0 left-0 right-0 text-center text-2xl mb-5">
                    {{ value }}%
                </div>
            </vue-svg-gauge>
        </div>
    </div>
</template>

<script>

    export default {

        props: {
            separatorThickness: {
                default: 0,
                required: false,
                type: Number
            },
            innerRadius: {
                default: 73,
                required: false,
                type: Number
            },
            angle: {
                default: 110,
                required: false,
                type: Number
            },
            min: {
                default: 0,
                required: false,
                type: Number
            },
            max: {
                default: 100,
                required: false,
                type: Number
            },
            value: {
                required: true,
                type: Number
            },
            height: {
                required: false,
                type: Number,
                default: () => 150
            },
            loading: {
                required: false,
                default: false,
                type: Boolean
            },
        },

        name: 'Gauge',

        computed: {
            gaugeColor() {
                return this.$cookies.get('brand_color') || '#2E457C';
            }
        }
    }
</script>
