<template>
    <div v-if="table.total > 0" class="relative flex flex__justify-between items-center w-full py-2 pl-6 pr-2">
        <span
            class="mr-auto text-body"
        >
            {{ table.from+1 }}-{{ table.to }} of {{ table.total }}

             <span class="ml-1" v-if="selected">(selected {{selected}})</span>
        </span>
        <div v-if="table.lastPage > 1" class="pagination pagination--center">
            <button
                class="page-item prev"
                :class="{'disabled': table.page === 1}"
                @click="table.goToPrev()"
            >
                <svg-importer icon-name="icons/arrow-left" key="pagniate-arrow-left" />
            </button>
            <button
                v-for="n in table.paginationItems"
                :key="n.value"
                class="page-item"
                :class="{'active': n.value === table.page }"
                @click="table.goToPage(n.value)"
            >{{ n.title }}</button>
            <button
                class="page-item next"
                :class="{'disabled': table.page === table.lastPage}"
                @click="table.goToNext()"
            >
                <svg-importer icon-name="icons/arrow-right" key="pagniate-arrow-right" />
            </button>
        </div>
        <select class="w-48 text-sm form-select ml-4 bg-transparent focus:bg-transparent" v-model="table.pageSize" @change="setPageSize()" :disabled="toggleTableTools">
            <option
                v-for="item in list"
                :key="item"
                :value="item"
            >
                {{ item }} rows
            </option>
        </select>
    </div>
</template>
<script>
    export default {
        props: {
            selected: {
                type: Number,
                default: 0,
            },
            toggleTableTools: {
                type: Boolean,
                default: false,
            },
            list: {
                type: Array,
                default: () => { return [10, 50, 100, 200, 500] },
            },
        },
        inject: ["table"],
        data() {
            return {
                pageSize: 50
            }
        },
        methods: {
            setPageSize(){
                this.table.page = 1;
                this.table.commitState()
            },
        },
        created() {
        },
    };
</script>