<template>
    <svg width="96" height="96" viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M54.7734 9.54008L54.7734 54.6836L9.69946 54.6836C7.98779 54.6836 6.65649 56.2074 6.65649 57.7312C6.65649 57.9217 6.65649 58.1122 6.65649 58.1122C9.88965 80.7792 29.6689 98.1127 53.4421 97.3508C77.0251 96.5889 96.6143 76.9696 97.375 53.3502C97.375 52.7788 97.375 52.2073 97.375 51.6359C97.375 28.588 80.4485 9.73056 58.1968 6.49242C56.4851 6.30194 54.7734 7.82577 54.7734 9.54008ZM91.2891 51.6359C91.2891 52.2073 91.2891 52.5883 91.2891 53.1597C90.7185 73.541 73.6018 90.6841 53.252 91.2555C34.2334 92.0174 17.6873 79.0649 13.313 60.7789L60.8594 60.7789L60.8594 13.1592C78.5466 17.3497 91.2891 33.3499 91.2891 51.6359ZM0.190183 42.4929C0.190183 42.4929 -2.39897e-06 42.4929 -2.39065e-06 42.6834C-2.32404e-06 44.2072 1.52148 45.5406 3.23315 45.5406L45.6445 45.5406L45.6445 3.0638C45.6445 1.34949 44.3132 -0.174336 42.6016 0.0161429C19.7793 1.53997 1.71167 19.6355 0.190183 42.4929ZM39.5586 39.4453L6.46631 39.4453C9.1289 22.4926 22.6321 8.96865 39.5586 6.30194L39.5586 39.4453Z" fill="currentColor"/>
        <circle cx="92.25" cy="92.25" r="35.875" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M101.767 83.1819L100.431 84.6388C99.6338 85.4364 98.604 85.3272 97.8692 84.9598L97.7454 84.8979L97.6412 84.8067C96.8907 84.15 95.8307 83.7576 94.7625 83.7576H89.3786C88.0356 83.7576 86.988 84.8543 86.988 86.0287C86.988 87.0791 87.7182 87.971 88.6832 88.2122L88.7103 88.219L97.3162 90.6099C100.907 91.5788 103.379 94.8861 103.379 98.591C103.379 103.09 99.7596 106.604 95.2432 106.726V110.435C95.2432 111.716 94.1913 112.472 93.2071 112.472H91.2929C90.7774 112.472 90.2384 112.313 89.827 111.901C89.4157 111.49 89.2568 110.951 89.2568 110.435V106.723C86.894 106.651 84.644 105.871 82.8577 104.471C81.8466 103.702 81.8375 102.239 82.6135 101.438L83.9489 99.9809C84.7466 99.1832 85.7764 99.2925 86.5112 99.6599L86.635 99.7218L86.7391 99.8129C87.4433 100.429 88.4771 100.742 89.6179 100.742H95.0018C96.3968 100.742 97.3924 99.7158 97.3924 98.591C97.3924 97.5405 96.6621 96.6486 95.6972 96.4074L95.67 96.4006L87.0273 93.9995C83.4936 92.9122 81.0015 89.763 81.0015 86.0287C81.0015 81.5128 84.6617 77.836 89.2568 77.7721V74.0645C89.2568 73.0804 90.0123 72.0284 91.2929 72.0284H93.2071C93.7973 72.0284 94.2972 72.3127 94.6281 72.6436C94.959 72.9744 95.2432 73.4743 95.2432 74.0645V77.7824C97.5958 77.8915 99.7939 78.7932 101.523 80.149C102.534 80.9179 102.543 82.3811 101.767 83.1819ZM61.5 92.25C61.5 75.2632 75.2632 61.5 92.25 61.5C109.237 61.5 123 75.2632 123 92.25C123 109.237 109.237 123 92.25 123C75.2632 123 61.5 109.237 61.5 92.25ZM67.4864 92.25C67.4864 106.011 78.4889 117.014 92.25 117.014C105.895 117.014 117.014 106.008 117.014 92.25C117.014 78.6086 105.891 67.4864 92.25 67.4864C78.4922 67.4864 67.4864 78.6053 67.4864 92.25Z" fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: 'InvoiceControlIcon',
};
</script>
