import Vue from 'vue'
import Snotify, { SnotifyPosition } from 'vue-snotify'

const options = {
  toast: {
    position: SnotifyPosition.rightBottom,
    showProgressBar: false,
  }
}

Vue.use(Snotify, options)
