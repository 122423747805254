<template>
  <transition name="fade">
    <div v-if="visible" class="overlay" @click.self="hide()">
      <transition name="fade">
        <div v-if="!loading" class="overflow-visible card modal" :class="{'modal-lg': size === 'lg'}">
            <div class="flex-grow-0 bg-white border-0 card-header">
                <h2 class="w-full px-2 py-2 font-normal -mz-4" v-if="name" slot="header">Remove {{ name }}</h2>

                <button class="ml-auto text-gray-500 close-modal-btn" @click="hide">
                    <svg-importer icon-name="icons/close" />
                </button>
            </div>

            <div slot="body" class="flex flex-wrap p-6 border-t border-gray-500">
                <div v-if="name == namesMap.get('organization')">
                    <p>
                        You are about to remove <strong>{{ selectedObject ? selectedObject.name : ''}}</strong>
                    </p>
                    <span>
                        The organization has <strong>{{ sitesLength }}</strong> sites and <strong>{{ usersLength }}</strong> users.
                    </span>
                </div>

                <span v-else-if="name == namesMap.get('site')">
                    You are about to remove <strong>{{ selectedObject ? selectedObject.name : ''}}</strong> from {{organization.name}}. <br>
                    The site has <strong>{{ selectedObject.users.length }} users.</strong>
                </span>

                <span v-else-if="name == namesMap.get('user')">
                    <p class="mb-4">You are about to remove <strong>{{ selectedObject ? selectedObject.name : ''}}</strong></p>
                    <p>Site: <strong>{{getUserSitesString(selectedObject.sites)}}</strong></p>
                    <p>Organization: <strong>{{selectedObject.organization.name}}</strong></p>
                </span>

                <span v-else-if="name == namesMap.get('site_user')">
                    <p class="mb-4">
                        You are about to remove <strong>{{ selectedObject ? selectedObject.name : ''}}</strong>
                        <span v-show="currentSite"> from <strong>{{ currentSite }}</strong></span>
                    </p>
                    <p>Site: <strong>{{getUserSitesString(selectedObject.sites)}}</strong></p>
                    <p>Organization: <strong>{{selectedObject.organization.name}}</strong></p>
                </span>

                <span v-else-if="name == namesMap.get('report')">
                    <p class="mb-4">
                        You are about to remove <strong>{{ selectedObject ? selectedObject.name : ''}}</strong>
                    </p>
                    <p>Organization: <strong>{{selectedObject.organization.name}}</strong></p>
                </span>

                <span v-else-if="name == namesMap.get('role')">
                    <p class="mb-4">
                        You are about to remove <strong>{{ selectedObject ? selectedObject.name : ''}}</strong> role
                    </p>
                </span>

                <span v-else-if="name == namesMap.get('markup')">
                    <p class="mb-4">
                        You are about to remove <strong>{{ selectedObject ? `${selectedObject.carrier.name} ${selectedObject.carrier_service.name}` : ''}}</strong> markup
                    </p>
                </span>

                <span v-else-if="name == namesMap.get('site_mapping_rule')">
                    <p class="mb-4">
                        You are about to remove a site mapping rule
                    </p>
                </span>

                <span v-else-if="name == namesMap.get('consignment_sharing_rule')">
                    <p class="mb-4">
                        You are about to remove a consignment sharing rule
                    </p>
                </span>

                <span v-else-if="name == namesMap.get('template')">
                    <p class="mb-4">
                        You are about to remove a booking template <b>{{ selectedObject.name }}</b>
                    </p>
                </span>

                <span v-else-if="name == namesMap.get('contact')">
                    <p class="mb-4">
                        You are about to remove a contact <b>{{ selectedObject.name }}</b>
                    </p>
                </span>

                <modal-field
                    v-if="sites && !exclusionNames.includes(name)" 
                    class="relative w-2/3 pl-5 mt-3">
                    <multiselect v-model="deleteAction.newSite" 
                        :options="filterSites()"
                        :multiple="false"
                        :disabled="deleteAction.command != 'move'"
                        placeholder="Please select" 
                        track-by="id"
                        label="name">
                        <span slot="noResult">
                        Oops! No elements found. Consider changing the search query.</span>
                    </multiselect>
                </modal-field>

                <modal-field v-if="namesMap.get('site') === name" class="relative flex w-full mt-4">
                    <input 
                        v-model="deleteAction.command"
                        value="delete"
                        type="radio"
                        class=""
                        id="deleteAll"
                    >
                    <label v-if="name == namesMap.get('organization')" for="deleteAll" class="ml-2">
                        {{ `Remove the organization${hasSites && hasUsers ? ', ' : ''}` }}
                        
                        <span v-if="(hasSites && !hasUsers) || (!hasSites && hasUsers)" v-text="' and '" />
                        <span v-if="hasSites" v-text="'sites'"/>
                        <span v-if="hasSites && hasUsers" v-text="' and '"/>
                        <span v-if="hasUsers" v-text="'users'"/>
                    </label>
                    <label v-else-if="name == namesMap.get('site')" for="deleteAll" class="ml-2">Remove the site and users</label>
                </modal-field>
            </div>
    
            <div class="flex p-4 mt-auto">
                <div class="flex w-full" slot="footer">
                    <!--<button @click="closeDeleteModal" class="ml-auto mr-4 btn-transparent">Close</button> -->
                    <button @click="initDelete" :disabled="!ableToDelete()" :class="{'btn-red' : ableToDelete()}" class="ml-auto btn-transparent">Remove</button>
                </div>
            </div>
        </div>
      </transition>

      <transition name="fade">
        <div
          v-if="loading"
          class="fixed inset-0 flex items-center justify-center text-5xl text-white loading"
          @click.self="hide()"
        >
          <fa :icon="['fal','circle-notch']" spin />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
const ENTITY_NAMES = [
    ['organization', 'Organization'],
    ['site', 'Site'],
    ['user', 'User'],
    ['site_user', 'Site User'],
    ['report', 'Report'],
    ['role', 'Role'],
    ['markup', 'Markup'],
    ['site_mapping_rule', 'site mapping rule'],
    ['consignment_sharing_rule', 'consignment sharing rule'],
    ['shipment_direction_rule', 'shipment direction mapping rule'],
    ['template', 'template'],
    ['contact', 'contact'],
    ['agreement', 'Agreement'],
];

export default {
  name: 'DeleteModal',
  props: {
    name: String,
    show: Boolean,
    loading: Boolean,
    size: String,
    inputs: Array,
    organization: Object,
    selectedObject: Object,
    organizations: Array,
    sites: Array,
  },

  data: () => ({
    visible: false,
    filteredSites: [],
    deleteAction: {
        command: '',
        newOrganization: null,
        newSite: null,
    },
    namesMap: new Map(ENTITY_NAMES),
  }),

  computed: {
    exclusionNames() {
        return [
            this.namesMap.get('site'),
            this.namesMap.get('user'),
            this.namesMap.get('site_user'),
            this.namesMap.get('report'),
            this.namesMap.get('role'),
            this.namesMap.get('markup'),
            this.namesMap.get('site_mapping_rule'),
            this.namesMap.get('contact'),
            this.namesMap.get('agreement'),
        ];
    },
    hasFooterSlot () {
      return this.$slots['footer']
    },
    currentSite() {
        if (!this.selectedObject && this.selectedObject.sites) {
            return '';
        } 

        const currentSite = this.selectedObject.sites.find(site => site.id === +this.$route.params.id);

        return currentSite ? currentSite.name : '';
    },
    sitesLength() {
        return this.selectedObject?.sites?.length || 0;
    },
    usersLength() {
        return this.selectedObject?.users?.length || 0;
    },
    hasSites() {
        return this.selectedObject?.sites?.length;
    },
    hasUsers() {
        return this.selectedObject?.users?.length;
    },
  },

  mounted() {
      this.setDeleteStatus();
      this.escape();
  },
  watch: {
    show (visible) {
      if (visible) {
        this.toggleBodyClass('add', 'modal-open')
        this.visible = true
        this.preSelectOrganization();
        this.setDeleteStatus();

        return;
      }

      this.toggleBodyClass('remove', 'modal-open')
      this.visible = false
    },
    newOrganization () {
        this.filterSitesOnOrganization();
    },
  },

  created () {
    this.toggleBodyClass('remove', 'modal-open')
    this.visible = false;
  },

  methods: {
    ableToDelete() {
        if (
            [
                this.namesMap.get('report'),
                this.namesMap.get('role'),
                this.namesMap.get('markup'),
                this.namesMap.get('site_mapping_rule'),
                this.namesMap.get('consignment_sharing_rule'),
                this.namesMap.get('shipment_direction_rule'),
                this.namesMap.get('template'),
                this.namesMap.get('contact'),
                this.namesMap.get('agreement'),
            ].includes(this.name)
        ) {
            return true;
        }
        
        if(this.name == this.namesMap.get('organization')) {
            if(this.deleteAction.command == 'delete') {
                return true;
            } else {
                if(this.deleteAction.newOrganization != null) {
                    return true;
                }
            }
        }

        if(this.name == this.namesMap.get('site')) {
            if(this.deleteAction.command == 'delete') {
                return true;
            } else {
                if(this.deleteAction.newSite != null) {
                    return true;
                }
            }
        }

        if([this.namesMap.get('user'), this.namesMap.get('site_user')].includes(this.name)) {
            return true;
        }
        return false;
    },
    filterOrganizations() {
        if(this.name == this.namesMap.get('organization')) {
            return this.organizations.filter(organization => organization.id != this.selectedObject.id);
        } else {
            return this.organizations;
        }
    },
    filterSites() {
        if(this.name == this.namesMap.get('site')) {
            return this.filteredSites.filter(site => site.id != this.selectedObject.id);
        } else {
            return this.filteredSites;
        }
    },
    setDeleteStatus() {
        if([this.namesMap.get('user'), this.namesMap.get('organization')].includes(this.name)) {
            this.deleteAction.command = 'delete';
        }
    },
    filterSitesOnOrganization() {
        if(this.sites != null) {
            this.filteredSites = this.sites.filter(site => this.organization.id == site.organization_id && site.id != this.selectedObject.id);
        }
    },
    hide () {
        this.$emit('hide')
    },
    clearNewSite() {
        this.newSite = null; 
    },
    preSelectOrganization() {
        this.deleteAction.newOrganization = this.organization;
    },
    initDelete() { 
        this.$emit('delete', this.deleteAction);
        setTimeout(() => {
            this.deleteAction.command = '';
            this.deleteAction.newSite = null;
            this.deleteAction.newOrganization = null;    
        }, 100);
    },
    escape() {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                this.hide();
            }
        });
    },
    getUserSitesString(sites) {
        return sites.map(site => site.name).join(', ');
    },
  },
}
</script>
