<template>
    <div class="chart-wrap" :style="'position: relative; width:100%;'">
        <div class="chart-container" :style="'position: relative; width:100%; height: ' + height + 'px'">
            <div v-if="loading" class="absolute w-full h-full flex  items-center justify-center">
                <fa :icon="['fad', 'spinner-third']" class="brand-primary fa-spin text-4xl" />
            </div>
            <canvas :id="'globlChart' + id" />
        </div>
        <div v-if="!!options.legendCallback && chart" v-html="chart.generateLegend()"></div>
    </div>
</template>
<script>
    import Chart from "chart.js";
    import "~/plugins/roundedBar";
    import "~/plugins/chart";

    export default {
        name: "Chart",

        props: {
            height: {
                required: false,
                type: Number,
                default: () => 150
            },
            series: {
                required: false,
                type: Object,
                default: () => ({})
            },
            options: {
                required: false,
                type: Object,
                default: () => ({})
            },
            type: {
                required: false,
                type: String,
                default: "roundedBar"
            },
            showLabels: {
                required: false,
                default: true,
                type: Boolean
            },
            showTicks: {
                required: false,
                default: true,
                type: Boolean
            },
            showGridLines: {
                required: false,
                default: true,
                type: Boolean
            },
            reverseGradients: {
                required: false,
                default: true,
                type: Boolean
            },
            loading: {
                required: false,
                default: false,
                type: Boolean
            },
        },

        data: () => ({
            id: null,
            chart: null,
            defaultStyles: {},
        }),

        computed: {
            barStacked() {
              return this.type === 'barStacked'
            },
            defaultsLine(){
                let vm = this;

                return {
                    maintainAspectRatio: false,
                    responsive: true,
                    barRoundness: 1,
                    legend: {
                        display: this.showLabels,
                        position: "bottom",
                        labels: {
                            usePointStyle: true,
                            boxWidth: 6,
                            fontSize: 10,
                            padding: 30
                        }
                    },
                    hover: {
                        intersect: false
                    },
                    scales: {
                        xAxes: [{
                            gridLines: false,
                            ticks: {
                                fontFamily: 'Roboto',
                                padding: 10
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                borderDash: [2, 4],
                                color: "#ddd"
                            },
                            ticks: {
                                fontFamily: 'Roboto',
                                beginAtZero: true,
                                callback: function(value) {
                                    return " " + vm.formatThousands(value);
                                }
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            label: function(t) {
                                return " " + vm.formatThousands(t.yLabel);
                            }
                        }
                    }
                }
            },
            defaultsHorizontalBar(){
                let vm = this;

                return {
                    responsive: true,
                    barRoundness: 1,
                    legend: {
                        display: this.showLabels,
                        position: "bottom",
                        labels: {
                            usePointStyle: true,
                            boxWidth: 6,
                            fontSize: 10,
                            padding: 30
                        }
                    },
                    scales: {
                        xAxes: [{
                            gridLines: false,
                            ticks: {
                                fontFamily: 'Roboto',
                                padding: 10,
                                display: vm.showTicks,
                                callback: function(value) {
                                    return " " + vm.formatThousands(value);
                                }
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                display: vm.showGridLines,
                                borderDash: [2, 4],
                                color: "#ddd",
                            },
                            ticks: {
                                display: vm.showTicks,
                                fontFamily: 'Roboto',
                                beginAtZero: true,
                                maxStepsLimit: 5,
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            label: function(t) {
                                return " " + vm.formatThousands(t.xLabel);
                            }
                        }
                    }
                }
            },
            defaultsBar(){
                let vm = this;

                return {
                    responsive: true,
                    barRoundness: 1,
                    legend: {
                        display: this.showLabels,
                        position: "bottom",
                        labels: {
                            usePointStyle: true,
                            boxWidth: 6,
                            fontSize: 10,
                            padding: 30
                        }
                    },
                    scales: {
                        xAxes: [{
                            stacked: this.barStacked,
                            barPercentage: 0.2,
                            gridLines: false,
                            ticks: {
                                fontFamily: 'Roboto',
                                padding: 10,
                                display: vm.showTicks,
                            }
                        }],
                        yAxes: [{
                            stacked: this.barStacked,
                            gridLines: {
                                display: vm.showGridLines,
                                borderDash: [2, 4],
                                color: "#ddd",
                            },
                            ticks: {
                                display: vm.showTicks,
                                fontFamily: 'Roboto',
                                beginAtZero: true,
                                maxStepsLimit: 5,
                                callback: function(value) {
                                    return " " + vm.formatThousands(value);
                                }
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            label: function(t) {
                                return " " + vm.formatThousands(t.yLabel);
                            }
                        }
                    }
                }
            },
            defaultsPie(){
                let vm = this;

                return {
                    cutoutPercentage: 80,
                    legend: {
                        display: this.showLabels,
                        position: "bottom",
                        labels: {
                            usePointStyle: true,
                            boxWidth: 8,
                            fontSize: 10,
                            padding: 20
                        }
                    },
                    tooltips: {
                        callbacks: {
                            label: function(t, d) {
                                return " " + vm.formatThousands(d['datasets'][0]['data'][t['index']]);
                            }
                        }
                    }
                }
            },

            colors() {
                return this.$cookies.get('brand_color') || '#2E457C';
            },

            primaryColor() {
                return this.$cookies.get('brand_color') || '#2E457C'
            },

            gradient() {
                return this.$cookies.get('brand_color') || '#2E457C'
            },

            chartType() {
                if (this.type === 'lineFilled') {
                    return 'line'
                }

                if (this.type === 'barStacked') {
                    return 'bar'
                }

                return this.type;
            }
        },

        watch: {
            type(type) {
                let self = this;

                if (type !== "table") {
                    this.chart.destroy();

                    this.$nextTick(() => {
                        self.initChart();
                    });
                }
            },
            loading() {
                if (! this.loading && ! this.chart) {
                    this.initChart();
                } else if (this.loading && this.chart) {
                    this.chart.clear();
                }
            },
        },

        created() {
            let self = this;
            this.id = this._uid;

            this.defaultStyles = {
                line: {
                    borderWidth: 5,
                    backgroundColor: "transparent",
                    pointRadius: 2,
                    pointHitRadius: 10,
                    pointHoverRadius: 8,
                    pointHoverBackgroundColor: this.colors,
                    pointBackgroundColor: this.colors
                },
                lineFilled: {
                    borderWidth: 1,
                },
                roundedBar: {
                    borderWidth: 1,
                    barThickness: 8,
                },
                horizontalBar: {
                    backgroundColor: this.gradient,
                },
                bar: {},
                barStacked: {
                    borderWidth: 5,
                },
                pie: {},
                doughnut: {
                    backgroundColor: this.gradient,
                    borderColor: '#fff',
                },
            }

            if (! this.loading) {
                this.$nextTick(() => {
                    self.initChart();
                });
            }
        },

        methods: {
            updateChart() {
                if (this.series) {
                    this.chart.data = this.styledSeries(this.series);
                    this.chart.update();
                }
            },

            styledSeries(series) {
                const vm = this;
                let datasetGradients = this.gradient.slice(0, series.datasets.length)

                if(this.reverseGradients) {
                    // datasetGradients = datasetGradients.reverse();
                }

                series.datasets = series.datasets.map((dataset, index) => {
                    if (series.datasets.length > 1) {
                        let colorIndex = index;

                        if (index > datasetGradients.length) {
                            colorIndex = 0;
                        }

                        if (!dataset.backgroundColor) {
                            dataset.backgroundColor = datasetGradients[colorIndex];
                        }
                        dataset.borderColor = dataset.backgroundColor;
                    } else {
                        dataset.backgroundColor = this.$cookies.get('brand_color') || '#2E457C';
                        dataset.borderColor = dataset.backgroundColor;
                    }

                    dataset = {...dataset, ...vm.defaultStyles[vm.type]};

                    return dataset;
                });


                return series;
            },
            initChart() {
                let el = document.getElementById("globlChart" + this.id);
                const ctx = el.getContext("2d");

                el.height = this.height

                let vm = this;

                let defaults = {}

                switch(this.type){
                    case 'line':
                        defaults = this.defaultsLine;
                        break;
                    case 'lineFilled':
                        defaults = this.defaultsLine;

                        this.series.datasets = this.series.datasets.map((dataset, index) => {
                            return {...dataset, ...{
                                fill: vm.series.datasets.length-1 === index ? 'origin' : index+1
                            }};
                        })

                        break;
                    case 'roundedBar':
                        defaults = this.defaultsBar
                        break;
                    case 'horizontalBar':
                        defaults = this.defaultsHorizontalBar
                        break;
                    case 'bar':
                        defaults = this.defaultsBar
                        break;
                    case 'barStacked':
                        defaults = this.defaultsBar
                        break;
                    case 'pie':
                    case 'doughnut':
                        defaults = this.defaultsPie
                        break;
                }

                this.chart = new Chart(ctx, {
                    type: this.chartType,
                    aspectRatio: 4,
                    data: this.styledSeries(this.series),
                    options: {
                        ...defaults,
                        ...this.options,
                    }
                });
            }
        }
    };
</script>
