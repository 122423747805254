<template>
    <div class="main-layout">
        <div class="container-fluid">
            <div class="row">
                <div class="px-10 pt-6 pb-24 bg-gray-200 content-page-mode">
                    <!--<navbar />-->
                    <child class="relative z-1" />
                </div>
            </div>
        </div>

        <vue-snotify />
    </div>
</template>

<script>
import { adjustColor } from '~/utils/color.js';

export default {
    name: "EmptyLayout",

    computed: {
        user() {
            return this.$store.getters["auth/user"];
        }
    },

    mounted() {
        this.setBranding();

    },
    methods: {
        setBranding() {
            const color = this.$cookies.get('brand_color') || '#2E457C';
            const logo = this.$cookies.get('logo_url') || '';

            document.documentElement.style.setProperty('--brand-color', color);
            document.documentElement.style.setProperty('--brand-color-secondary', adjustColor(color));
            document.documentElement.style.setProperty('--logo', logo);
        },
    },
}
</script>