<template>
    <transition name="fade">
        <div v-if="show" class="overlay" @click.self="hide()">
            <transition name="fade">
                <div class="overflow-visible modal card w-full" :class="[`modal-${size}`]">
                    <MarkupGeographySpecificsTable>
                        <template #tools>
                            <div class="flex justify-end pt-6">
                                <button class="px-10 btn-transparent" @click="hide">Cancel</button>
                                <button class="px-10 btn-primary ml-2" @click="save">Save</button>
                            </div>
                        </template>
                    </MarkupGeographySpecificsTable>
                </div>
            </transition>
        </div>
  </transition>
</template>
<script>
import MarkupGeographySpecificsTable from './MarkupGeographySpecificsTable.vue';

export default {
    name: 'MarkupModal',
    components: { MarkupGeographySpecificsTable },
    props: {
        show: Boolean,
        size: String,
    },

    computed: {
        id() {
            return this.$store.getters['markups/id'];
        },
        geographySpecificRates() {
            return this.$store.getters['markups/geographySpecificRates'];
        },
    },
    
    
    methods: {
        hide() {
            this.$emit('hide');
        },
        save() {
            this.$emit('save', { id: this.id, geography_specific_rates: this.geographySpecificRates });
            this.hide();
        },
    },
};
</script>