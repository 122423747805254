<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Tab',

  props: {
    title: String,
    disabled: Boolean
  },

  data: () => ({

  }),

  computed: {},

  created () {
  },

  methods: {}
}
</script>
