const state = {
    initialized: false,
    updateTrigger: 0,
    timer: null,
};

const getters = {
    initialized: state => state.initialized,
    updateTrigger: state => state.updateTrigger,
};

const mutations = {
    SET_INITIALIZED(state, bool) {
        state.initialized = bool;
    },
    SET_UPDATE_TRIGGER(state) {
        state.updateTrigger++;
    },
    SET_TIMER(state) {
        state.timer = setInterval(() => {
            if (document.hidden) {
                return;
            }

            state.updateTrigger++;
        }, 60000);
    },
};

const actions = {
    init({commit}) {
        commit('SET_INITIALIZED', true);
        commit('SET_UPDATE_TRIGGER');
        commit('SET_TIMER');
    },
};

export {
    state,
    getters,
    mutations,
    actions,
}