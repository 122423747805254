<template>
    <div>
        <div :style="`width: ${size}px; height: ${size}px;`" class="app-spinner"></div>
    </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 24,
    }
  }
}
</script>

<style lang="scss">
.app-spinner {
  text-indent: -9999em;
  border-top: 2px solid #F1F1F1;
  border-right: 2px solid #F1F1F1;
  border-bottom: 2px solid var(--brand-color);
  border-left: 2px solid var(--brand-color);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border-top-color: #F1F1F1;
  border-left-color: #F1F1F1;
  transform: translateY(-50%);
  -webkit-animation: load 0.5s infinite linear;
  animation: load 0.5s infinite linear;
}
@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>