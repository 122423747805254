<template>
  <select :value="value" @input="$emit('input', $event.target.value)">
    <option v-for="reseller in $store.getters['misc/resellers']" :key="reseller.id" :value="reseller.id">
      {{ reseller.name }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'ResellerSelect',
  props: ['value'],
  data: () => ({

  }),

  computed: {},

  created () {},

  methods: {}
}
</script>
