<template>
    <div class="w-full h-full relative" :style="'min-height:' + height + 'px'">
        <div :class="{'opacity-0' : creatingMap || loading, 'no-branding': !branding}" :id="mapId" class="map-box-map"></div>

        <div v-show="creatingMap || loading" class="absolute w-full h-full flex  items-center justify-center top-0">
            <fa :icon="['fad', 'spinner-third']" class="fa-spin text-4xl" :class="branding ? 'brand-primary' : 'no-branding'" />
        </div>
    </div>
</template>

<script>
    import mapboxgl from "mapbox-gl";

    export default {
        name: "MapBox",
        props: {
            branding: {
                required: false,
                default: true,
                type: Boolean
            },
            height: {
                type: Number,
                default: () => 200
            },
            center: {
                type: Array,
                default: () => [12.273617, 56.680279],
            },
            zoom: {
                type: Number,
                default: () => 6,
            },
            maxZoom: {
                type: Number,
                default: () => 12
            },
            loading: {
                required: false,
                default: false,
            },
            mapOptions: {
                type: Object,
                default: () => {}
            },
            fitBounds: {
                type: Object,
                default: () => {
                    return {
                        bounds: null, options: null
                    }
                }
            },
            markers: {
                type: Array,
                default: () => []
            },
            layers: {
                type: Array,
                default: () => []
                /*
                    This expects an array of objects with two properties per object, layer and source.
                    These will be merged and added to map upon init.

                    {
                        layer: {
                            id: "line",
                            type: "line",
                            source: source: {
                                type: "geojson",
                                data: {
                                    type: "FeatureCollection",
                                    features: [...FeatureCollection]
                                }
                            },
                            paint: {
                                "line-color": "#00c88e",
                                "line-width": 4
                            },
                            filter: ["==", "$type", "LineString"]
                        }
                    }
                */
            },
            onMapLoad: {
                type: Function,
                default: null
            }
        },
        data: () => ({
            mapbox : {
                token: "pk.eyJ1IjoiZm9hZyIsImEiOiIzd3g1VVRVIn0.ycNwNVN7_cCtDOfr1sjvIg"
            },
            bounds: [],
            map: null,
            creatingMap: true,
        }),
        created(){
            if (this.map) {
                this.map.remove();
            }

            this.$nextTick(() => {
                this.initMap();
            });
        },
        computed: {
            mapId() {
                return `map-${this._uid}`;
            }
        },
        watch: {
            layers: {
                handler(newData, oldData) {

                    if (JSON.stringify(newData) !== JSON.stringify(oldData)) {

                        if (this.map) {
                            this.map.remove();
                        }

                        this.$nextTick(() => {
                            this.initMap();
                        });
                    }
                }
            }
        },
        methods: {
            initMap() {

                if (this.loading) {
                    return false;
                }

                this.creatingMap = true;
                let vm = this;
                this.bounds = new mapboxgl.LngLatBounds();
                mapboxgl.accessToken = this.mapbox.token;

                //New Mapbox Instance
                this.map = new mapboxgl.Map({
                    style: "mapbox://styles/foag/ck394d2yt482c1cmupzcv5vzz",
                    // style: 'mapbox://styles/mapbox/dark-v9',
                    container: this.mapId,
                    center: this.center,
                    zoom: this.zoom,
                    maxZoom: this.maxZoom,
                    attributionControl: false,
                    maxBounds: [ [-180, -85], [180, 85] ],
                    ...this.mapOptions
                });

                this.map.on("load", () => {
                    vm.addLayers()
                    vm.addMarkers()
                    vm.zoomToBounds()

                    if(vm.onMapLoad){
                        vm.onMapLoad(vm.map)
                    }

                    vm.creatingMap = false;
                });
            },
            addLayers() {
                let sources = [];

                this.layers.forEach(layer => {
                    this.map.addLayer(layer)
                    sources.push(layer.source.data.features[0]);
                })
            },
            addMarkers(){
                this.markers.forEach(marker => {
                    new mapboxgl.Marker(marker.el)
                    .setLngLat([
                        marker.lon,
                        marker.lat
                    ]).addTo(this.map)
                })
            },
            zoomToBounds(){
                if(this.fitBounds && this.fitBounds.bounds){
                    this.map.fitBounds(this.fitBounds.bounds, this.fitBounds.options);
                }
            }
        }
    };
</script>

<style>
    .map-box-map {
        width: 100%;
        height: 100%;
        background-color: #C7C7C7;
    }

    .marker {
        background-size: cover;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        cursor: pointer;
        border: 2px solid;
    }

    .marker-consignor, .marker-consignee {
        border-width: 0!important;
    }

    .marker-consignor svg, .marker-consignee svg {
        box-shadow: none;
    }

    .marker-pickup {
        width: .75rem;
        height: .75rem;
    }

    .marker-delivery {
        border: 3px solid;
        cursor: pointer;
    }

    .marker-skew {
        transform: rotateX(45deg);
    }
</style>
