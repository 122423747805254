import * as types from '../mutation-types'

// state
export const state = {
  toggled: null,
  showPicker: false,
  adminMenu: 'admin',
  lastAdminPath: null,
  lastClientPath: null,
  updatedOrganizations: [],
  reload: 0,
}

// getters
export const getters = {
    toggled: state => state.toggled,
    showPicker: state => state.showPicker,
    adminMenu: state => state.adminMenu,
    lastAdminPath: state => state.lastAdminPath,
    lastClientPath: state => state.lastClientPath,
    updatedOrganizations: state => state.updatedOrganizations,
    reload: state => state.reload,
}

// mutations
export const mutations = {
  [types.TOGGLE_PICKER] (state, show) {
    state.showPicker = show
  },
  [types.TOGGLE] (state, id) {
    if(state.toggled == id ) {
        state.toggled = null;
    } else {
        state.toggled = id
    }
  },
  OPEN (state, id) {
      state.toggled = id
  },
  [types.ADMIN_MENU] (state, val) {
    state.adminMenu = val;
  },
  [types.LAST_ADMIN_PATH] (state, val) {
    state.lastAdminPath = val;
  },
  [types.LAST_CLIENT_PATH] (state, val) {
    state.lastClientPath = val;
  },
  [types.UPDATED_ORGANIZATIONS] (state, val) {
    if(val != 'empty') {
        if(!state.updatedOrganizations.includes(val)) {
            state.updatedOrganizations.push(val);
        }
    } else {
        state.updatedOrganizations = [];
    }
  },
  RELOAD(state) {
    state.reload++;
  }
}
