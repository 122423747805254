<template>
    <svg width="95" height="96" viewBox="0 0 124 125" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M78.1035 121.907H94.4868V117.93H83.5288C83.9585 114.432 94.1645 113.761 94.1645 106.908C94.1645 102.739 90.297 100.391 85.9997 100.391C80.6282 100.391 78.1035 104.177 78.1035 104.177L81.4339 106.812C81.4339 106.812 83.2602 104.608 85.6774 104.608C87.5575 104.608 89.0078 105.614 89.0078 107.1C89.0078 111.269 77.8887 111.652 77.8887 119.942C77.8887 120.517 77.9961 121.236 78.1035 121.907Z" fill="currentColor"/>
        <path d="M0 97.5477C0 108.269 8.03553 116.528 19.6603 116.528C29.3565 116.528 34.6063 110.629 34.6063 110.629L30.9636 105.858C30.9636 105.858 26.5172 110.475 19.9281 110.475C11.9997 110.475 7.12483 104.37 7.12483 97.4451C7.12483 90.6737 11.839 84.9282 19.8745 84.9282C25.928 84.9282 30.2672 88.7756 30.2672 88.7756L33.5885 83.851C33.5885 83.851 28.8208 78.875 19.5531 78.875C8.35695 78.875 0 86.9802 0 97.5477Z" fill="currentColor"/>
        <path d="M38.1374 97.4451C38.1374 108.166 46.6015 116.528 57.7976 116.528C68.9938 116.528 77.4579 108.166 77.4579 97.4451C77.4579 87.0315 68.9938 78.875 57.7976 78.875C46.6015 78.875 38.1374 87.0315 38.1374 97.4451ZM45.2622 97.4451C45.2622 90.2633 50.8335 84.9282 57.7976 84.9282C64.7617 84.9282 70.333 90.2633 70.333 97.4451C70.333 104.935 64.7617 110.475 57.7976 110.475C50.8335 110.475 45.2622 104.935 45.2622 97.4451Z" fill="currentColor"/>
        <path d="M100.408 102.901C100.408 110.006 105.625 115.452 113.236 115.452C119.027 115.452 122.617 112.042 122.617 112.042L120.703 108.679C120.703 108.679 117.687 111.474 113.523 111.474C109.215 111.474 105.434 108.822 105.195 103.564H122.856C122.856 103.564 123 102.191 123 101.575C123 95.3707 119.362 90.3503 112.518 90.3503C105.625 90.3503 100.408 95.2759 100.408 102.901ZM105.338 100.107C106.008 96.1758 108.88 93.9972 112.422 93.9972C115.533 93.9972 118.118 95.9864 118.261 100.107H105.338Z" fill="currentColor" stroke="currentColor" stroke-width="1.025"/>
        <path d="M101.699 32.7843C102.18 31.2972 102.5 29.81 102.5 28.1741C102.5 19.1024 94.332 11.5179 84.5625 11.5179C81.8398 11.5179 79.2773 12.1127 76.875 13.1537C71.75 6.4615 63.2617 2 53.8125 2C38.5977 2 26.2656 13.1537 25.625 27.1331C16.4961 30.1074 10.25 38.1381 10.25 47.2098C10.25 59.1071 20.5 68.625 33.3125 68.625H92.25C103.461 68.625 112.75 60.1482 112.75 49.5893C112.75 42.5996 108.586 36.2048 101.699 32.7843ZM92.25 63.8661H33.3125C23.3828 63.8661 15.375 56.4302 15.375 47.2098C15.375 38.8817 22.1016 31.892 30.9102 30.7023C30.75 29.9587 30.75 29.0664 30.75 28.1741C30.75 16.4255 41 6.75893 53.8125 6.75893C63.4219 6.75893 71.5898 12.2614 75.1133 20.1434C77.5156 17.764 80.7188 16.2768 84.5625 16.2768C91.6094 16.2768 97.375 21.6306 97.375 28.1741C97.375 30.9997 96.2539 33.5279 94.6523 35.6099C102.02 36.6509 107.625 42.5996 107.625 49.5893C107.625 57.4713 100.738 63.8661 92.25 63.8661Z" fill="currentColor" stroke="currentColor" stroke-width="2.5625"/>
    </svg>
</template>

<script>
export default {
    name: 'EmissionsIcon',
};
</script>
