<template>
  <fa :class="color" :icon="['fas',icon]" :transform="transform + rotation" />
</template>
<script>
export default {
  name: 'TrendIndicator',
  props: {
    value: {
      type: Number,
      default: 0
    },
    colorPositive: {
      type: String,
      default: 'text-green-500'
    },
    colorNegative: {
      type: String,
      default: 'text-red-600'
    },
    colorNeutral: {
      type: String,
      default: 'text-gray-500'
    },
    icon: {
      type: String,
      default: 'arrow-circle-up'
    }
  },
  data: () => ({
    transform: 'grow-10 '
  }),
  computed: {
    color () {
      if (this.value > 0) {
        return this.colorPositive
      }

      if (this.value < 0) {
        return this.colorNegative
      }

      return this.colorNeutral
    },
    rotation () {
      let value = this.value > 0 ? 100 - Math.min(100, this.value) : this.value
      let deg = Math.min((Math.abs(value) / 100) * 90, 90)

      if (this.value > 0) {
        return 'rotate-' + deg
      }

      if (this.value < 0) {
        return 'rotate-' + (90 + deg)
      }

      return ''
    }
  }
}
</script>
