<template>
    <div class="table-tools flex__grow">
        <div 
            v-if="toolsRight"
            class="relative flex ml-auto align-right tools items-center" 
            :class="{'flex__justify-between': $slots.panel, 'w__100-p': $slots.panel}"
        >
            <div class="flex">
                <slot v-if="$slots.panel" name="panel" />

                <slot v-if="$slots.export" name="export" />

                <v-columns-switch
                    v-if="showSettings"
                    v-model="toggleableColumns"
                    :toggle-table-tools="toggleTableTools"
                    @click="updated"
                    @show="toggleTableTools = $event"
                    @update="updateCheckbox"
                />
            </div>

            <div class="flex flex__align-center">
                <slot v-if="$slots.panel_right" name="panel_right" />

                <span v-if="table.total" class="mr-auto text-body">
                    {{ table.from+1 }}-{{ table.to }} of {{ table.total }}

                    <span class="ml-1" v-if="selected">(selected {{selected}})</span>
                </span>

                <select class="w-48 text-sm form-select ml-4 border-radius--input bg-transparent focus:bg-transparent" v-model="table.pageSize" @change="setPageSize()" :disabled="toggleTableTools">
                    <option :value="10">10 rows</option>
                    <option :value="50">50 rows</option>
                    <option :value="100">100 rows</option>
                    <option :value="200">200 rows</option>
                    <option :value="500">500 rows</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import VColumnsSwitch from './VColumnsSwitch.vue';

    export default {
        inject: ["table"],
        components: { VColumnsSwitch },
        props: {
            customExport: {
                type: Function,
                default: null
            },
            toolsRight: {
                type: Boolean,
                default: true
            },
            branding: {
                required: false,
                default: true,
                type: Boolean
            },
            selected: {
                type: Number,
                default: 0,
            },
            columns: {
                type: Array,
            },
            showSettings: {
                type: Boolean,
                default: true,
            },
            settingsIconsOutline: {
                type: Boolean,
                default: true,
            },
        },
        data: () => ({
            filterTerm: null,
            toggleTableTools: false,
            pageSize: 50,
            toggleableColumns: [],
            trigger: 0
        }),
        methods:{
            doExport(){
                if(this.table.customExport){
                    this.table.customExport(this.table);
                } else {
                    this.table.exportData();
                }
            },
            updateCheckbox(col) {
                this.table.loading = true;

                setTimeout(() => { col.toggled = !col.toggled });

                this.table.loading = false;
            },
            setPageSize(){
                this.table.page = 1;
                this.table.commitState()
            },
            clearSearch() {
                this.table.filter = ''
                this.table.commitState()
            },
            pushSearch() {
                //this.table.setFilter(this.filterTerm)
                this.table.page = 1
                this.table.commitState()
            },
            updated() {
                setTimeout(async () => {
                    await this.updateColumns();
                }, 250)
            },
            save() {
                this.toggleableColumns.forEach((item, index) => {
                    item.sort = index;
                })

                this.table.columns = this.toggleableColumns;
            },
            updateColumns() {
                const findFirstPin = this.toggleableColumns.findIndex(item => {
                    return !item.pin;
                });

                this.toggleableColumns.forEach((item, index) => {
                    if (findFirstPin != -1 && index > findFirstPin) {
                        item.pin = false;
                    }

                    if (index > 2) {
                        item.pin = false;
                    }

                    item.index = index;
                });

                this.table.columns = this.toggleableColumns;
            },
        },
        watch: {
            toggleTableTools(value) {
                this.$emit('toggleTableTools', value);
            },
            trigger() {
                this.updated();
            },
            'table.loading'() {
                this.updated();
            },
            'table.trigger'(value) {
                if (value <= 1) {
                    this.updated();
                }
            }
        },
        created() {
            this.toggleableColumns = this.table.toggleableColumns;  
        },
        mounted() {
            this.updated()
        }
    };
</script>

<style lang="scss">
input[type="checkbox"][disabled] {
    opacity: 0.5;
}
</style>
