import Vue from 'vue'

import {
  library
} from '@fortawesome/fontawesome-svg-core'

import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome'

// Pro Solid
import {
    faArrowAltUp,
    faArrowAltDown,
    faArrowCircleUp,
    faGripVertical,
    faCog,
    faSignOutAlt,
    faSortUp,
    faSortDown,
    faSort,
    faInfoCircle,
    faExclamationCircle,
    faBookmark as fasBookMark,
    faCheckCircle as faCheckCircleSolid,
    faPlusCircle,
    faFilePdf,
    faFileExcel,
    faExclamation,
    faThumbsUp,
    faEllipsisH as faEllipsisHSolid,
    faCircle as faCircleSolid,
    faTimes as faTimesSolid,
    faCheck as faCheckSolid, faEnvelopeOpenText,

} from '~/fa/pro-solid-svg-icons';

// Pro Regular
import {
  faCircle as faCircleRegular,
  faUsdCircle,
  faQuestionCircle,
} from '~/fa/pro-regular-svg-icons'

// Pro Light
import {
  faForward,
  faCopy,
  faFileInvoiceDollar,
  faArrowToTop,
  faBookmark,
  faFile,
  faCommentAlt,
  faContainerStorage,
  faFileExport,
  faCog as faCogLight,
  faFileChartLine,
  faGripLines,
  faChartPieAlt,
  faTrash,
  faPlaneAlt,
  faTrashAlt,
  faAddressBook,
  faBriefcase,
  faBoxes,
  faBox,
  faFilePdf as faFilePdfLight,
  faFileExcel as faFileExcelLight,
  faFileWord,
  faPaperPlane,
  faPrint,
  faMapMarkedAlt,
  faUndo,
  faFolderOpen,
  faSave,
  faHome,
  faPen,
  faPlus,
  faFilePlus,
  faFileMinus,
  faMinus,
  faMinusCircle,
  faEllipsisV,
  faLanguage,
  faBell,
  faSignalStream,
  faShoppingCart,
  faAngleUp,
  faAngleDown,
  faBagsShopping,
  faCube,
  faStore,
  faPhotoVideo,
  faNewspaper,
  faRss,
  faCloud,
  faUsers,
  faShare,
  faShareAlt,
  faUser,
  faUserCircle,
  faCircle,
  faBug,
  faExclamationTriangle,
  faExclamation as falExclamation,
  faTachometerAltFast,
  faCheck,
  faTimes,
  faCircleNotch,
  faAngleLeft,
  faAngleRight,
  faSearch,
  faTimesCircle,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faClock,
  faChartBar,
  faChartLine,
  faTable,
  faCheckCircle,
  faArrowToBottom,
  faInfoCircle as falInfoCircle,
  faTachometerAlt,
  faUniversalAccess,
  faBoxOpen,
  faSignOut,
  faBalanceScale,
  faPallet,
  faCalendarAlt,
  faTrain,
  faBoxAlt,
  faPlane,
  faTruckMoving,
  faTruck,
  faShippingFast,
  faShip,
  faQuestion,
  faComment,
  faLink,
  faSmile,
  faSlidersHSquare,
  faUserFriends,
  faBuilding,
  faCity,
  faServer,
  faFileAlt,
  faHandHoldingBox,
  faVial,
  faTree,
  faTreeAlt,
  faCoin,
  faSmoke,
  faShoePrints,
  faCubes,
  faChevronUp,
  faChevronRight,
  faChevronDown,
  faChevronLeft,
  faFileInvoice,
  faFileImport,
  faFileTimes,
  faEllipsisH,
  faReceipt,
  faSyncAlt,
  faBackspace,
  faCodeMerge,
  faBoxCheck,
  faFileCheck,
  faPaste,
  faEdit,
  faFileSearch,
  faFilter,
  faImage,
  faImages,
  faGasPump,
  faIndustryAlt,
  faTire,
  faGlobeEurope,
  faEuroSign,
  faRadar,
  faClipboardCheck,
  faVolumeMute,
  faExchange,
  faAlarmClock,
  faThumbtack,
  faFileDownload,
  faPlaneDeparture,
  faPlaneArrival,
  faArrowsAlt,
  faFileSignature,
  faDownload,
  faClipboardList,
  faEnvelopeOpenText as falEnvelopeOpenText,
  faDollarSign,
  faLeaf,
} from '~/fa/pro-light-svg-icons'

// Pro Duotone
import {
    faSpinnerThird
} from '~/fa/pro-duotone-svg-icons'

// import { } from '@fortawesome/fontawesome-free-regular/shakable.es'

library.add(
  faQuestionCircle,
  faClipboardList,
  faFileExcelLight,
  faDownload,
  faBox,
  faFileSignature,
  faForward,
  faCheckSolid,
  faImages,
  faTimesSolid,
  faBookmark,
  faPlaneAlt,
  faFilePdfLight,
  faMapMarkedAlt,
  faFile,
  faThumbsUp,
  faCalendarAlt,
  faFolderOpen,
  faAngleUp,
  faImage,
  faAngleDown,
  faBagsShopping,
  faCube,
  faBoxes,
  faAddressBook,
  faPlusCircle,
  faFilePlus,
  faFileMinus,
  faFileWord,
  faArrowToTop,
  faMinusCircle,
  faStore,
  faCommentAlt,
  faPhotoVideo,
  faNewspaper,
  faRss,
  faCloud,
  faClock,
  faUser,
  faUsers,
  faShare,
  faShareAlt,
  faUserCircle,
  faCircle,
  faBug,
  faCog,
  faSignOutAlt,
  faTachometerAltFast,
  faTimes,
  faCheck,
  faCircleNotch,
  faSortUp,
  faSortDown,
  faSort,
  faAngleLeft,
  faAngleRight,
  faInfoCircle,
  faExclamationTriangle,
  faTrash,
  faTrashAlt,
  faExclamationCircle,
  faSearch,
  faBriefcase,
  faTimesCircle,
  faShoppingCart,
  faBell,
  faLanguage,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faEllipsisV,
  faGripVertical,
  faPlus,
  faMinus,
  faPen,
  faHome,
  faArrowsAlt,
  faArrowAltUp,
  faArrowAltDown,
  faChartBar,
  faChartLine,
  faTable,
  faArrowCircleUp,
  faCheckCircle,
  falInfoCircle,
  faArrowToBottom,
  faFilePdf,
  faFileExcel,
  faTachometerAlt,
  faUniversalAccess,
  faBoxOpen,
  faSignOut,
  faBalanceScale,
  faPallet,
  faSave,
  faUndo,
  faFilter,
  faPaperPlane,
  faEllipsisHSolid,
  faTrain,
  faBoxAlt,
  faPlane,
  faTruckMoving,
  faTruck,
  faShippingFast,
  faShip,
  faQuestion,
  faCircleRegular,
  faCircleSolid,
  faComment,
  faLink,
  faSmile,
  faExclamation,
  fasBookMark,
  faCheckCircleSolid,
  faContainerStorage,
  faSlidersHSquare,
  faFileChartLine,
  faCogLight,
  faFileExport,
  faCopy,
  faUserFriends,
  faBuilding,
  faCity,
  faServer,
  faSpinnerThird,
  faFileAlt,
  faHandHoldingBox,
  faVial,
  faTree,
  faSignalStream,
  faTreeAlt,
  faCoin,
  faSmoke,
  faShoePrints,
  faCubes,
  faChevronUp,
  faChevronRight,
  faChevronDown,
  faChevronLeft,
  faFileInvoice,
  faFileImport,
  faEllipsisH,
  faReceipt,
  faSyncAlt,
  faBackspace,
  faCodeMerge,
  faBoxCheck,
  faFileCheck,
  faPrint,
  faPaste,
  faFileInvoiceDollar,
  faEdit,
  faFileSearch,
  faGasPump,
  faIndustryAlt,
  faTire,
  falExclamation,
  faGlobeEurope,
  faEuroSign,
  faChartPieAlt,
  faUsdCircle,
  faRadar,
  faClipboardCheck,
  faVolumeMute,
  faFileTimes,
  faExchange,
  faAlarmClock,
  faGripLines,
  faThumbtack,
  faFileDownload,
  faPlaneDeparture,
  faPlaneArrival,
  faEnvelopeOpenText,
  falEnvelopeOpenText,
  faLeaf,
  faDollarSign,
)

Vue.component('fa', FontAwesomeIcon)
Vue.component('faLayer', FontAwesomeLayers)
Vue.component('faText', FontAwesomeLayersText)
