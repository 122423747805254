export const cellTypeDictionary = {
    header: 'header',
    cell: 'cell',
};

export const cellValueDictionary = {
    DOM_DOM: 'DOM_DOM',
    DOM_REG: 'DOM_REG',
    DOM_WW: 'DOM_WW',
    REG_DOM: 'REG_DOM',
    REG_REG: 'REG_REG',
    REG_WW: 'REG_WW',
    WW_DOM: 'WW_DOM',
    WW_REG: 'WW_REG',
    WW_WW: 'WW_WW',
};

export const DEFAULT_MARKUP_GEOGRAPHY_SPECIFICS_VALUE = {
    DOM_DOM: null,
    DOM_REG: null,
    DOM_WW: null,
    REG_DOM: null,
    REG_REG: null,
    REG_WW: null,
    WW_DOM: null,
    WW_REG: null,
    WW_WW: null,
};

export const referenceTexts = [
    { text: '“Geographical mark-up variations” will be applied based on sender and receiver country codes.' },
    { text: 'Domestic country and domestic region are determined automatically based on consignment owner’s organization country code.' },
    { text: 'At least 1 of 9 boxes should be filled-in.' },
    {
        text: 'In case box of interest is not filled-in, system will use maximal mark-up for the category:',
        image: '',
        list: [
            { text: 'Domestic' },
            { text: 'Export' },
            { text: 'Import' },
            { text: 'Cross-shipment' },
        ]
    },
    { text: 'In case category of interest do not have any specified mark-up, system will use maximal mark-up from next one clockwise', image: '/images/markup-info.jpg' },
];