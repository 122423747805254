<template>
    <transition name="fade">
        <div v-if="show" class="overlay">
            <transition name="fade">
                <div class="overflow-visible card modal" :class="{'modal-lg': size === 'lg'}">
                    <div class="flex-grow-0 bg-white border-0 card-header">
                        <h2 class="w-full px-4 py-2 -mx-4 font-normal">Add new site</h2>

                        <button class="ml-auto text-gray-500 close-modal-btn" @click="hide">
                            <svg-importer icon-name="icons/close" />
                        </button>
                    </div>

                    <div slot="body" class="flex flex-wrap p-6 border-t border-gray-500">
                        <div v-for="(input, index) in inputs" :key="'input' + index" :class="input.width">
                            <modal-field v-if="input.type == 'text'" :label="input.title" class="flex-wrap w-full px-4 mt-4" :required="input.required"

                            >
                                <input type="text" class="w-full" v-model="input.value" :class="{'border-red-500' : validationErrors[input.key]}" :placeholder="input.value"/>

                                <transition name="fade">
                                    <span v-if="validationErrors[input.key]" class="w-full text-red-500 help-block">
                                        <span>{{ validationErrors[input.key][0] }}</span>
                                    </span>
                                </transition>
                            </modal-field>

                            <modal-field v-if="input.type == 'mask'" :label="input.title" class="w-full px-4 mt-4" :required="input.required">
                                <the-mask a type="text" v-model="input.value" :mask="mask[input.mask]" :class="{'border-red-500' : validationErrors[input.key]}" :placeholder="input.placeholder"/>

                                <transition name="fade">
                                    <span v-if="validationErrors[input.key]" class="w-full text-red-500 help-block">
                                        <span>{{ validationErrors[input.key][0] }}</span>
                                    </span>
                                </transition>
                            </modal-field>

                            <modal-field v-if="input.type === 'select'" :label="input.title" class="relative w-full px-4 mt-4">
                                <multi-select
                                    select-label="name"
                                    select-value="id"
                                    :allow-clear="false"
                                    :allow-empty="true"
                                    :branding="false"
                                    :multiple="true"
                                    :options="getOptions(input.key)"
                                    :disabled="input.disabled"
                                    v-model="input.value"
                                >
                                </multi-select>
                                <transition name="fade">
                                    <span v-if="validationErrors[input.key]" class="w-full text-red-500 help-block">
                                        <span>{{ validationErrors[input.key][0] }}</span>
                                    </span>
                                </transition>
                            </modal-field>

                            <modal-field v-if="input.type === 'checkbox'" :label="input.title" class="w-full px-4 mt-4">
                                <checkbox v-model="input.value" class="mt-2" />
                            </modal-field>
                        </div>
                    </div>

                    <div class="flex p-4 mt-auto">
                        <div slot="footer" class="w-full py-4 pr-6 text-right">
                            <button @click="saveSite" :class="[routeIncludesAdmin ? 'btn-primary' : 'btn']">
                                Add site
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>


<script>

import axios from 'axios';
import MultiSelect from '~/components/MultiSelect';

export default {
    name: 'AddSiteModal',
    components: {
        MultiSelect,
    },
    props: {
        show: Boolean,
        size: String,
        organization: Object,
        carriers: {
            type: Array,
            required: false,
            default: (() => []),
        },
        carrierServices: {
            type: Array,
            required: false,
            default: (() => []),
        },
    },

    data: () => ({
        visible: false,
        mask: {
            vat: 'AA-XXXXXXXXXXXX',
            phone: '+#############',
            zip: '##########',
        },
        loading: false,
        validationErrors: {},
        inputs: [
            {
                title: 'Name',
                key: 'name',
                value: null,
                type: 'text',
                width: 'w-1/3',
                required: true,
            },
            {
                title: 'E-mail',
                key: 'email',
                value: null,
                type: 'text',
                width: 'w-1/3',
                required: true,
            },
            {
                title: 'Identifier',
                key: 'key',
                value: null,
                type: 'text',
                width: 'w-1/3',
                required: true,
            },
            {
                title: 'Phone number',
                key: 'phone',
                value: null,
                type: 'mask',
                width: 'w-1/3',
                mask: 'phone',
                required: true,
            },
            {
                title: 'Address',
                key: 'address',
                value: null,
                type: 'text',
                width: 'w-1/3',
                required: true,
            },
            {
                title: 'Zip code',
                key: 'post_code',
                value: null,
                type: 'mask',
                width: 'w-1/3',
                mask: 'zip',
                required: true,
            },
            {
                title: 'City',
                key: 'city',
                value: null,
                type: 'text',
                width: 'w-1/3',
                required: true,
            },
            {
                title: 'VAT-number',
                key: 'vat_number',
                value: null,
                type: 'mask',
                width: 'w-1/3',
                mask: 'vat',
                required: true,
                placeholder: 'AB-12345679',
            },
            {
                title: 'Excluded carriers services',
                key: 'excluded_carrier_ids',
                options: [],
                value: null,
                type: 'select',
                width: 'w-1/3',
                required: false,
                multiple: true,
                disabled: true,
            },
            {
                title: 'Is active',
                key: 'is_active',
                options: [],
                value: 0,
                type: 'checkbox',
                width: 'w-1/3',
            },
        ],
    }),

    computed: {
        hasFooterSlot() {
            return this.$slots['footer'];
        },
        routeIncludesAdmin() {
            return this.$route.path.includes('admin');
        },
    },

    created() {
        this.toggleBodyClass('remove', 'modal-open');
        this.visible = false;
    },

    watch: {
        loading(newVal, oldVal) {
            this.$emit('loading', newVal);
        },
    },

    methods: {
        getOptions(inputKey) {
            if (inputKey === 'excluded_carrier_ids') {
                return this.carriers;
            }
            
            return [];
        },
        async saveSite() {
            this.loading = true;
            let site_data = {};
            let id = this.$route.params.id;

            this.inputs.forEach(input => {
                if (input.key === 'name' && input.value != null) {
                    site_data['key'] = input.value.split(' ').join('_');
                }
                if ((input.key === 'excluded_carrier_ids') && input.value != null) {
                    site_data[input.key] = input.value.map(element => element.id);
                } else if (input.key === 'is_active') {
                    site_data[input.key] = Number(input.value);
                } else {
                    site_data[input.key] = input.value;
                }
            });
            site_data['organization_id'] = this.organization.id;

            try {
                await axios.post(this.$apiUrl.sites.base, site_data);
                this.$snotify.success(`Site created`);
                this.validationErrors = [];
                this.loading = false;
                this.hide();
                this.clearSiteForm();
                this.$emit('success', true);

                this.$store.dispatch('auth/fetchUser');
            } catch (error) {
                if (error.response.status === 422) {
                    this.validationErrors = error.response.data.errors;
                }
            }
        },
        clearSiteForm() {
            this.inputs.forEach(element => {
                element.value = null;
            });
        },
        hide() {
            this.clearSiteForm();
            this.$emit('hide');
        },
    },
};
</script>
