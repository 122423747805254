import Vue from 'vue'
import Router from 'vue-router'
import store from '~/store'

import Meta from 'vue-meta'
import routes from './routes'
import middlewarePipeline from './pipeline'

Vue.use(Meta)
Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if (!to.meta?.middleware) {
        return next()
    }
    
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next,
        store
    }
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
});

export default router;