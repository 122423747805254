<template>
  <transition name="fade">
    <div v-if="visible" class="overlay" @click.self="hide()">
      <transition name="fade">
        <div v-if="!loading" class="card mega-modal" :class="{'tabbed':tabs}">
          <div v-if="!noHeader" class="card-header flex-grow-0">
            <slot name="header" />

            <ul v-if="tabs" class="tabs">
              <li v-for="(tab, index) in tabs" :key="index" class="tab" :class="{'active':activeTab == index}" @click="activeTab = index">
                {{ tab[0].componentOptions.propsData.title }}
              </li>
            </ul>

            <button class="text-gray-500 close-modal-btn ml-2" @click="hide">
              <svg-importer icon-name="icons/close" />
            </button>
          </div>

          <slot v-if="!tabs" name="body" />

          <slot v-if="tabs" :name="activeTab" />

          <div v-if="hasFooterSlot" class="card-footer flex mt-auto">
            <slot name="footer" />
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="loading" class="loading fixed inset-0 flex items-center justify-center text-white text-5xl" @click.self="hide()">
          <fa :icon="['fal','circle-notch']" spin />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'MegaModal',
  props: {
    show: Boolean,
    loading: Boolean,
    hideUrl: Boolean,
    noHeader: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    visible: false,
    activeTab: null
  }),

  computed: {
    hasFooterSlot () {
      return this.$slots['footer']
    },
    tabs () {
      let slots = {}

      if (Object.keys(this.$slots).length === 0) {
        return false
      }

      Object.keys(this.$slots).map((key) => {
        if (key.substr(0, 3) === 'tab') {
          slots[key] = this.$slots[key]
        }
      })

      if (Object.keys(slots).length === 0) {
        return false
      }

      return slots
    }
  },

  watch: {
    show (visible) {
      if (visible) {
        this.toggleBodyClass('add', 'modal-open')
        this.visible = true
      } else {
        this.toggleBodyClass('remove', 'modal-open')
        this.visible = false
      }
    }
  },

  created () {
    this.toggleBodyClass('remove', 'modal-open')
    this.visible = this.show
    this.setActiveTab()
  },

  methods: {
    setActiveTab () {
      this.activeTab = Object.keys(this.tabs)[0]
    },
    hide () {
      this.$emit('hide')
    }
  }
}
</script>
