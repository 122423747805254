<template>
    <tbody>
        <tr v-for="n in rows" :key="n">
            <td v-for="i in columns" :key="i">
                <span class="h-4 w-3/4 inline-block text-transparent linear-background">Loading</span>
            </td>
        </tr>
    </tbody>
</template>

<script>
    export default {
        inject: ["table"],
        computed: {
            rows(){
                return this.table.pageSize <= 50 ? this.table.pageSize : 50;
            },
            columns(){
                return this.table.columnsLength 
            }
        }
    };
</script>