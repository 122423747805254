<template>
    <emoji-picker @emoji="insert" :search="search">
        <div
            slot="emoji-invoker"
            slot-scope="{ events: { click: clickEvent } }"
            @click.stop="clickEvent"
        >
            <button type="button">open</button>
        </div>
        <div slot="emoji-picker" slot-scope="{ emojis, insert, display }" class="arrow-box dropdown border w-80 -ml-24 bg-white p-4">
            <div>
                <div>
                    <input type="text" v-model="search" />
                </div>
                <div class="h-48 overflow-auto">
                    <div v-for="(emojiGroup, category) in emojis" :key="category">
                        <h5>{{ category }}</h5>
                        <div>
                            <span
                                v-for="(emoji, emojiName) in emojiGroup"
                                :key="emojiName"
                                @click="insert(emoji)"
                                class="text-2xl mr-2 mb-1"
                                :title="emojiName"
                            >{{ emoji }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </emoji-picker>
</template>

<script>
    import EmojiPicker from "vue-emoji-picker";

    export default {
        name: "EmojiPopup",

        components: {
            EmojiPicker
        },

        data() {
            return {
                search: ""
            };
        },

        methods: {
            insert(emoji) {
                this.$emit("append", emoji);
            }
        },

        directives: {
            focus: {
                inserted(el) {
                    el.focus();
                }
            }
        }
    };
</script>

<style scoped>
    .emoji-container {
        top: -504px;
        left: -225px;
        height: 500px;
    }
</style>