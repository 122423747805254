<template>
    <label class="radio-input flex flex__align-center cursor-pointer">
        <input
            v-model="value"
            type="radio"
            :value="inputValue"
            :name="inputName"
            class="radio-input__input visually-hidden"
            @change="update"
        />

        <div class="radio-input__icon no-branding mr-3" />
        
        <span class="text-body">
            <slot />
        </span>
    </label>
</template>
<script>
export default {
    name: 'RadioInput',

    props: {
        modelValue: String,
        inputValue: String,
        inputName: String,
    },

    model: {
        prop: 'modelValue',
        event: 'change'
    },

    data() {
        return {
            value: this.modelValue,
        };
    },

    methods: {
        update(event) {
            this.$emit('change', event?.target?.value);
        },
    },
}
</script>
<style lang="scss">
.radio-input {
    &__input:checked ~ .radio-input__icon {
        border-color: var(--brand-color);

        &::before {
            display: block;
        }
    }

    &__icon {
        position: relative;
        width: 20px;
        height: 20px;
        border: 2px solid var(--color-text-secondary);
        border-radius: 20px;
        box-sizing: border-box;
    
        &::before {
            content: "";
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 10px;
            height: 10px;
            background-color: var(--brand-color);
            border-radius: 10px;
        }
    }

}
</style>