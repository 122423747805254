<template>
    <component
        :is="inactive ? 'div' : 'button'"
        :type="inactive ? '' : 'button'"
        :disabled="disabled"
        class="icon"
        :class="{ 'cursor-pointer': !inactive, 'ignore-disabled-style': hideDisabled }"
        @click="emit"
    >
        <svg-importer :icon-name="name" />
        <slot />
    </component>
</template>
<script>
export default {
    name: 'Icon',
    props: {
        name: String,
        disabled: Boolean,
        inactive: Boolean,
        hideDisabled: Boolean,
    },
    methods: {
        emit() {
            this.$emit('click')
        },
    },
}
</script>
<style lang="scss">
.icon {
    min-width: 40px;
    padding: 0 8px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 8px;
    background: #F7F7F7;
    box-sizing: border-box;

    transition: opacity ease .3s;

    &:not(.ignore-disabled-style) {
        &:disabled {
            opacity: .5;
        }
    }

    &.ignore-disabled-style {
        &:disabled {
            cursor: default !important;
        }
    }

}
</style>