<template>
    <div class="bg-white rounded">
        <div class="flex justify-between px-6 py-4 border-b">
            <div>
                <slot name="header"></slot>
            </div>
            <button @click="$modal.hide(name)" class="ml-auto text-gray-500 close-modal-btn">
                <svg-importer icon-name="icons/close" />
            </button>
        </div>
        <div class="p-6">
            <slot name="body"></slot>
        </div>
        <div class="p-6">
            <slot name="footer"></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModalContent',
    props: {
        name: {
            type: String,
            required: true
        }
    }
}
</script>
