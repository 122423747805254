<template>
    <component v-if="component" :is="component" />
</template>
<script>
import Shipments from './../../public/svg/menu/shipments.svg';

export default {
    name: 'SvgImporter',
    components: {
        Shipments,
    },
    props: {
        iconName: String,
    },
    data() {
        return {
            component: null,
        }
    },
    async created() {
        try {
            const component = await (() => import(`~/../public/svg/${this.iconName}.svg`))();

            this.component = component.default;
        } catch (e) {
            throw new Error(`Couldn't find the SVG file with name "${this.iconName}" in directory "svg"`);
        }
    },
}
</script>