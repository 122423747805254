import Vue from 'vue'

import EmissionsIcon from './EmissionsIcon';
import InvoiceControlIcon from './InvoiceControlIcon';
import LeadTimeIcon from './LeadTimeIcon';
import CustomReportsIcon from './CustomReportsIcon'

[
    EmissionsIcon,
    InvoiceControlIcon,
    LeadTimeIcon,
    CustomReportsIcon,
].forEach(Component => {
    Vue.component(Component.name, Component)
})
