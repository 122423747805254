<template>
  <tr @click="handleRowClick">
    <slot />
  </tr>
</template>

<script>

export default {
  name: 'VTr',
  props: {
    row: {
      required: true
    }
  },
  data: () => ({
  }),
  computed: {

  },
  mounted () {

  },
  beforeDestroy () {

  },
  methods: {
    handleRowClick () {
      this.$emit('clicked', this.row)
    }
  }
}
</script>
