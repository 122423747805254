import axios from 'axios';

const state = {
    list: [],
};

const getters = {
    list: state => state.list,
};

const mutations = {
    SET_LIST(state, list) {
        state.list = list;
    },
};

const actions = {
    async init({state, commit}) {
        if (state.list.length) {
            return;
        }

        try {
            const { data: { data } } = await axios.get('/api/countries?pageSize=1000');
            commit('SET_LIST', data);
        } catch (error) {
            console.error(error);
        }
    }
};

export {
    state,
    getters,
    mutations,
    actions,
};