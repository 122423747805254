<template>
  <div class="flex bg-red-100 h-full">
    <div class="w-full md:w-7/12 lg:w-1/2 xl:w-2/3 xs:hidden bg-gray-200 bg-center bg-no-repeat bg-cover p-48 pl-24 flex items-center">
      <div class="w-full login-logo flex justify-center">
        <svg-importer class="globl-logo" icon-name="globl-logo" />
      </div>
    </div>
    <div class="flex w-full h-full md:w-5/12 lg:w-1/2 xl:w-1/3 bg-white justify-center items-center">
      <child />
    </div>
    
  </div>
</template>

<script>
import { adjustColor } from '~/utils/color.js';

export default {
  name: 'LoginLayout',
  
  methods: {
    setBranding() {
        const color = this.$cookies.get('brand_color') || '#2E457C';
        const logo = this.$cookies.get('logo_url') || '';

        document.documentElement.style.setProperty('--brand-color', color);
        document.documentElement.style.setProperty('--brand-color-secondary', adjustColor(color));
        document.documentElement.style.setProperty('--logo', logo);
    },
  },

  mounted() {
    this.setBranding();
  }
}
</script>

<style lang="scss">
.basic-layout {
  color: #636b6f;
  height: 100vh;
  font-weight: 100;
  position: relative;

  .links > a {
    color: #636b6f;
    padding: 0 25px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.globl-logo {
  transform: scale(300%);

  @media screen and (max-width: 1279px) {
    transform: scale(200%);
  }

  @media screen and (max-width: 979px) {
    transform: scale(150%);
  }
}
</style>
