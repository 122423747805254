<template>
    <svg width="100" height="100" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 12.5858V4H3V21H21V19H5V15.4142L8 12.4142L12 16.4142L19 9.41421V11H21V6H16V8H17.5858L12 13.5858L8 9.58579L5 12.5858Z" fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: 'CustomReportsIcon',
};
</script>
