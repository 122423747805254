import store from '~/store'

export default ({to, from, next}) => {
    let allowed = ['super', 'organization-admin', 'site-admin'];
    if(allowed.includes(store.getters['auth/user']?.level)) {
        next();
    } else {
        next({ name: 'home' })
    }
}
