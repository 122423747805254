import * as pbi from 'powerbi-client';
const models = pbi.models;

export const DEFAULT_DASHBOARD_REPORT_SETTINGS = {
    panes: { 
        filters: { 
            visible: false,
        }, 
        pageNavigation: { 
            visible: false,
        } 
    }, 
    commands: [{
        seeData: {
            displayOption: models.CommandDisplayOption.Hidden,
        }, 
        drillthrough: { 
            displayOption: models.CommandDisplayOption.Hidden, 
        }, 
        clearSelection: { 
            displayOption: models.CommandDisplayOption.Hidden, 
        }, 
        includeExclude: { 
            displayOption: models.CommandDisplayOption.Hidden, 
        } 
    }],
};

export const DEFAULT_ANALYTICS_REPORT_SETTINGS = {
    panes: { 
        filters: { 
            visible: false,
        }, 
        pageNavigation: { 
            visible: false,
        } 
    },
};