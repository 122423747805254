<template>
    <multiselect 
        v-bind="$attrs"
        :value="value"
        :preselect-first="false"
        :clear-on-select="!hasMultiple" 
        :close-on-select="!hasMultiple"
        :preserve-search="true"
        :multiple="multiple"
        ref="multiselect"
        @input="input"
        @select="$emit('select', $event)"
        @remove="$emit('remove', $event)"
        @search-change="$emit('search-change', $event); searchValue = $event;"
        @tag="$emit('tag', $event)"
        @open="$emit('open', $event)"
        @close="close"
    >
        <template slot="selection" slot-scope="{ values, isOpen }">
            <span v-if="values.length && values.length > maxValues &&  !isTaggable" :title="selectionName(values)" class="multiselect__single">
                {{ selectionName(values) }}
            </span>
        </template>

        <template slot="beforeList">
            <li v-if="hasOptions && hasMultiple && !searchValue && !disabledSelectedAll" class="multiselect__element multiselect__option--all">
                <span v-if="hasMultiple" class="multiselect__option multiselect__option--all" @click="selectAll">
                    {{ beforeListName }}
                </span>
            </li>
        </template>

        <template slot="option" slot-scope="props">
            <div class="cursor-pointer flex">
                <input v-if="hasMultiple && !isGroup(props.option) && !isTaggable" type="checkbox" class="form-checkbox mr-2 cursor-pointer multiselect__checkbox" :checked="isChecked(props.option)" />

                <fa
                    v-if="isColors"
                    :class="getStatusColor(props.option.value)"
                    :icon="getStatusIcon(props.option.value)"
                    class="text-xs mr-1 multiselect__colors"
                    transform="shrink-5"
                />

                <span>{{ getNameOption(props.option) }}</span>
            </div>
        </template>  

        <template v-if="(!hasMultiple && isClear)" slot="clear">
            <div
                @mousedown.prevent.stop="clear"
                class="w-4 absolute top-0 bottom-0 right-0 z-10 flex items-center mr-10 cursor-pointer"
                v-show="value !== null && !hasMultiple && value !== ''"
            >
                <svg-importer icon-name="icons/close" />
            </div>
        </template>
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    name: 'FormMultiselect',
    
    props: {
        value: {
            default: null,
        },
        
        isColors: {
            type: Boolean,
            default: false,
        },

        multiple: {
            type: Boolean,
            default: false,
        },

        oldVersion: {
            type: Boolean,
            default: false,
        },

        disabledSelectAll: {
            type: Boolean,
            default: false,
        },
        
        isClear: {
            type: Boolean,
            default: false,
        },
        maxValues: {
            type: Number,
            default: 5,
        },
        maxSelectable: Number,
    },

    model: {
        prop: 'value',
        event: 'input',
    },

    data() {
        return { 
            searchValue: '',
        }
    },

    computed: {
        hasMultiple() {
            return this.multiple;
        },

        hasOptions() {
            return this.$attrs.options?.length > 1;
        },

        isSelectAll() {
            return this.$attrs.options?.length === this.value?.length;
        },

        beforeListName() {
            if (!this.hasMultiple) {
                return '';
            }

            if (this.isSelectAll) {
                return 'Unselect all';
            }

            return 'Select all';
        },

        trackBy() {
            return this.$attrs?.['track-by'];
        },

        label() {
            return this.$attrs?.['label'];
        },

        disabledSelectedAll() {
            return this.$attrs['group-values'] || this.$attrs['group-label'] || this.disabledSelectAll;
        },

        isTaggable() {
            return Boolean(this.$attrs.taggable);
        }
    },

    methods: {
        input(event) {
            if (event?.length < this.value?.length) {
                this.$refs.multiselect.search = '';
            }

            if (this.maxSelectable && event?.length > this.maxSelectable) {
                return;
            }

            this.$emit('input', event);
        },
        clear() {
            this.$emit('input', null);
            this.$refs.multiselect.search = ''
        },
        close($event) {
            this.$emit('close', $event);

            setTimeout(() => {
                this.$refs.multiselect.search = ''
            }, 100);
        },
        selectionName(values) {
            if (!values.length) {
                return '';
            }

            if (values.length >= 1 && values.length <= this.maxValues) {
                return values.map(item => {
                    return this.getNameOption(item);
                }).join(', ')
            }

            if (this.maxSelectable) {
                return `${values.length}/${this.maxSelectable} selected`;
            }

            return `${values.length} selected`;
        },

        isGroup(value) {
            return value?.$groupLabel;
        },

        getNameOption(value) {
            if (this.isGroup(value)) {
                return value.$groupLabel;
            }

            if (this.isTaggable) {
                return value.label;
            }

            if (this.label) {
                return value[this.label];
            }

            return value;
        },

        isChecked(value) {
            if (!this.hasMultiple) {
                return false;
            }

            if (!this.value) {
                return false;
            }

            if (this.trackBy) {
                return this.value?.find(item => { 
                    return item[this.trackBy] === value[this.trackBy];
                });
            }

            return this.value?.find(item => { 
                return item === value 
            });
        },  

        selectAll() {
            if (this.isSelectAll) {
                this.$emit('input', []);
                this.$emit('update-all', []);

                return;
            }

            let resultOptions = this.$attrs.options;

            if (this.$attrs['sort-options-all-by']) {
                const fieldName = this.$attrs['sort-options-all-by'];

                resultOptions = [...this.$attrs.options].sort((a, b) => {
                    if (a[fieldName] > b[fieldName]) {
                        return 1;
                    } else if (a[fieldName] < b[fieldName]) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            }

            this.$emit('input', resultOptions);
            this.$emit('update-all', resultOptions);
        }
    },

    components: {
        Multiselect,
    }
}
</script>