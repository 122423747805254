<template>
    <transition name="fade">
        <div v-if="show" class="overlay" @click.self="hide()">
            <transition name="fade">
                <div class="overflow-visible card modal" :class="{'modal-lg': size === 'lg'}">
                    <div class="flex-grow-0 bg-white border-0 card-header">
                        <h2 class="w-full px-4 py-2 -mx-4 font-normal">Add new user</h2>

                        <button class="ml-auto text-gray-500 close-modal-btn" @click="hide">
                            <svg-importer icon-name="icons/close" />
                        </button>
                    </div>

                    <div slot="body" class="flex flex-wrap p-6 border-t border-gray-500">
                        <div 
                            v-for="(input, index) in inputs"
                            :key="'input' + index"
                            :class="input.width"
                        >
                            <modal-field 
                                v-if="input.type == 'text'"
                                :label="input.title" 
                                class="flex-wrap w-full px-4 mt-4"
                                :required="input.required"
                                
                            >   
                                <input 
                                    type="text"
                                    class="w-full" 
                                    v-model="input.value"
                                    :class="{'border-red-500' : validationErrors[input.key]}"
                                    :placeholder="input.value"
                                />

                                <transition name="fade">
                                    <span
                                        v-if="validationErrors[input.key]"
                                        class="w-full text-red-500 help-block"
                                    >
                                        <span>{{validationErrors[input.key][0]}}</span>
                                    </span>
                                </transition>
                            </modal-field>

                            <modal-field 
                                v-if="input.type == 'mask'"
                                :label="input.title" 
                                class="w-full px-4 mt-4"
                                :required="input.required"
                            >   
                                <the-mask
                                    type="text" 
                                    v-model="input.value"
                                    :mask="mask[input.mask]"
                                    :class="{'border-red-500' : validationErrors[input.key]}"
                                    :placeholder="input.placeholder"
                                />

                                <transition name="fade">
                                    <span
                                        v-if="validationErrors[input.key]"
                                        class="w-full text-red-500 help-block"
                                    >
                                        <span>{{validationErrors[input.key][0]}}</span>
                                    </span>
                                </transition>
                            </modal-field>

                            <modal-field 
                                v-if="input.type == 'select'"
                                :label="input.title" 
                                class="relative w-full px-4 mt-4"
                            >
                                <multiselect 
                                    v-model="input.value" 
                                    :options="input.key === 'sites' ? sitesOptions : input.options "
                                    :multiple="input.multiple"
                                    :class="{'border-red-500' : validationErrors[input.key]}"
                                    placeholder="Please select"
                                    :disabled="input.key === 'sites' && isDisableSites"
                                    
                                    track-by="id"
                                    label="name">
                                    <span slot="noResult">
                                    Oops! No elements found. Consider changing the search query.</span>
                                </multiselect>

                                <transition name="fade">
                                    <span
                                        v-if="validationErrors[input.key]"
                                        class="w-full text-red-500 help-block"
                                    >
                                        <span>{{validationErrors[input.key][0]}}</span>
                                    </span>
                                </transition>
                            </modal-field>
                        </div>
                    </div>
                
                    <div class="flex p-4 mt-auto">
                        <div slot="footer" class="w-full py-4 pr-6 text-right">
                            <button 
                                @click="saveUser" 
                                :class="[routeIncludesAdmin ? 'btn-primary' : 'btn']">
                                Add user
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>


<script>

import axios from 'axios'

const GOD_ROLE = 'GOD USERS';   
const ADMIN_ROLES = ['GOD USERS', 'Organization Admin'];

export default {
    name: 'AddUserModal',
    props: {
        show: Boolean,
        size: String,
        organization: Object,
    },

    data: () => ({
        visible: false,
        mask: {
            vat: 'AA-XXXXXXXXXXXX' ,
            phone: '+#############',
            zip: '##########',
        },
        validationErrors: {},
        loading: false,
        sitesOptionsList: [],
        inputs:  [
            {
                title: 'Name',
                key: 'name',
                value: null,
                type: 'text',
                width: 'w-1/2',
            },
            {
                title: 'E-mail',
                key: 'email',
                value: null,
                type: 'text',
                width: 'w-1/2',
            },
            {
                title: 'Roles',
                key: 'roles',
                value: null,
                multiple: true,
                type: 'select',
                width: 'w-2/3',
                options: [],
            },
            {
                title: 'Sites',
                key: 'sites',
                value: null,
                type: 'select',
                width: 'w-2/3',
                multiple: true,
                options: [],
            },
        ],
    }),

    computed: {
        isAdminRoleChosen() {
            const rolesInputValue = this.inputs?.find(input => input.key === 'roles')?.value;
            return Boolean(
                rolesInputValue?.find(role => ADMIN_ROLES.includes(role.name))
            );
        },
        isDisableSites() {
            const rolesInputValue = this.inputs?.find(input => input.key === 'roles')?.value;
            return !rolesInputValue?.length || this.isAdminRoleChosen;
        },
        sitesValue() {
            const sitesInput = this.inputs.find(input => input.key === 'sites');
            return this.isAdminRoleChosen ? sitesInput?.options : sitesInput?.value;
        },
        isDisplayGodUserRole() {
            return this.$store.getters['auth/isGodUser'];
        },
        hasFooterSlot () {
            return this.$slots['footer']
        },
        routeIncludesAdmin() {
            return this.$route.path.includes('admin') ? true : false;
        },
        sitesOptions() {
            const roles = this.inputs
                ?.find(input => input.key === 'roles')
                ?.value;

            if (!roles?.length) {
                return [];
            }

            if (roles?.find(role => role.name === GOD_ROLE)) {
                return this.sitesOptionsList;
            }
            
            return this.sitesOptionsList.filter(site => site.organization?.id === this.organization?.id);
        },
    },

    async created () {
        this.toggleBodyClass('remove', 'modal-open')
        this.visible = false

        this.fetchRoles();   
    },

    watch: {
        loading(newVal) {
            this.$emit('loading', newVal);
        },
        isAdminRoleChosen(val, oldVal) {
            if (val === oldVal) {
                return;
            }

            this.inputs.forEach(input => {
                if (input.key !== 'sites') {
                    return;
                }

                if (!val) {
                    input.value = [];
                }

                input.value = this.sitesOptions;
            });
        },
        organization() {
            this.fetchSites();
        },
    },

    methods: {
        async fetchRoles() {
            const { data } = await axios.get(`${this.$apiUrl.roles.base}?pageSize=1000`);

            let roles = data.data;
            if (!this.isDisplayGodUserRole) {
                roles = roles?.filter(role => role.name !== 'GOD USERS');
            }

            this.inputs.forEach(input => {
                if (input.key === 'roles') {
                    input.options = roles;
                }
            });
        },
        async fetchSites() {
            if (!this.organization) {
                return;
            }

            const { data: { data } } = await axios.get(
                `${this.$apiUrl.sites.base}?filter[organization_id]=${this.organization.id}&pageSize=1000`
            );

            this.sitesOptionsList = data;
        },
        async saveUser() {
            this.loading = true;
                let user_data = {};
                let id = this.$route.params.id;

                this.inputs.forEach(element => {
                    if (element.key == 'roles') {
                        user_data['roles_ids'] = element.value?.map(role => role.id);
                    } else if (element.key == 'sites') {
                        user_data['site_ids'] = element.value?.map(site => site.id);
                    } else {
                        user_data[element.key] = element.value;
                    }
                });
            try {
                user_data['organization_id'] = this.organization.id;

                const { data } = await axios.post(this.$apiUrl.users.list, user_data);
                this.loading = false;
                this.validationErrors = [];
                this.$snotify.success(`User added`);
                this.clearUserForm();
                this.$emit('success', true);
                this.hide();
            }
            catch (error) {
                this.loading = false;
                if(error?.response?.status == 422) {
                    this.validationErrors = error.response.data.errors;
                }
            }
        },
        clearUserForm() {
            this.inputs.forEach(element => {
                element.value = null;
            });
        },
        hide () {
            this.clearUserForm();
            this.$emit('hide');
        },
        escape() {
            document.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.hide();
                }
            });
        }
    },
    mounted() {
        this.escape();
    }
}


</script>