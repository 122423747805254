<template>
    <div class="relative">
        <button
            type="button"
            class="sidebar__toggled-icon"
            :class="{ min: isMin }"
            :style="`margin-top: ${isAdmin ? defaultHideSidebarOffset : hideSidebarOffset }px`"
            @click="isMin = !isMin"
        >
            <svg-importer v-show="isMin" icon-name="icons/chevron-right" />
            <svg-importer v-show="!isMin" icon-name="icons/chevron-left" />
        </button>
        <nav class="flex flex-col border-r-2 border-gray-400 sidebar"
            :class="{ 'bg-gray-1000 sidebar-admin' : tabAdmin, 'sidebar-min': isMin }">
            <div ref="logoWr" class="flex flex__justify-center flex__align-center mb-0 logo-wr">
                <div v-if="isGodUser">
                    <svg-importer v-show="!isMin" icon-name="globl-logo" :class="{ 'logo-dark': tabAdmin }" />
                    <svg-importer v-show="isMin" icon-name="globl-logo-min" :class="{ 'logo-dark': tabAdmin }" />
                </div>
                <div v-else>
                    <svg-importer v-show="isDisplayDefaultLogo && !isMin" icon-name="globl-logo" :class="{ 'logo-dark': tabAdmin }" />
                    <svg-importer v-show="isDisplayDefaultLogo && isMin" icon-name="globl-logo-min" :class="{ 'logo-dark': tabAdmin }" />
                    <img
                        v-if="clientLogo"
                        v-show="!logoError"
                        :src="clientLogo"
                        class="mx-auto max-h-24"
                        :class="{ 'opacity-0': isMin }"
                        @error="clientLogoError"
                        @load="clientLogoLoad"
                    />
                </div>
            </div>

            <div class="sidebar__toggled mb-0">
            </div>

            <transition name="fade-sidebar">
                <div v-if="!isMin">
                    <div v-if="this.user.level === 'super'" class="flex mb-4 tabs-wr">
                        <div
                            @click="setMenu('admin', { redirect: true })"
                            class="w-1/2 py-2 text-center border-b-2 cursor-pointer"
                            :class="[tabAdmin ? 'border-brand-color text-white' : 'border-gray-300 text-gray-700']">
                            Admin
                        </div>
                        <div
                            @click="setMenu('client', { redirect: true })"
                            class="w-1/2 py-2 text-center border-b-2 cursor-pointer"
                            :class="[!tabAdmin ? 'border-brand-color text-gray-900' : 'border-gray-700 text-gray-700']">
                            Client
                        </div>
                    </div>    

                    <div
                        v-if="activeTab === 'client' && isAdmin"
                        class="px-4 multiselect-wr multiselect-update-site"
                        :class="{ 'hide-removes': !isAllowRemoveSites }"
                    >
                        <div class="text-body mb-1">
                            Organization sites
                        </div>
                        <multiselect
                            :value="chosenSite"
                            :options="useSitesOrOrganizationsList"
                            :multiple="true"
                            :close-on-select="false"
                            :group-select="false"
                            :internal-search="false"
                            :clear-on-select="false"
                            placeholder="Act as ..."
                            :allow-empty="false"
                            :group-values="this.user.level === 'super' ? 'sites' : ''"
                            :group-label="this.user.level === 'super' ? 'name' : ''"
                            @input="handleInput"
                            @search-change="searchChange = $event"
                            @open="openSelect"
                            track-by="id"
                            sort-options-all-by="id"
                            label="name"
                            :max-values="1"
                        >
                            <span slot="noResult">
                                Oops! No elements found. Consider changing the search query.
                            </span>
                            <template slot="tag" slot-scope="props">
                                <div
                                    class="multiselect__tag"
                                    :class="{ delete: isToDelete(props.option.id), add: isToAdd(props.option.id) }"
                                >
                                    <span>
                                        {{ props.option.name }}
                                    </span>
                                    <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="remove(props.option)"></i>
                                </div>
                            </template>
                            <template slot="option" slot-scope="props">
                                <div>
                                    {{ props.option.$groupLabel || props.option.name }}
                                </div>
                            </template>
                        </multiselect>
                    </div>

                    <div
                        v-if="activeTab === 'client' && !isAdmin"
                        class="px-4 multiselect-wr multiselect-update-site"
                        :class="{ 'hide-removes': !isAllowRemoveSites }"
                    >
                        <div class="text-body mb-1">
                            Organization sites
                        </div>
                        <multiselect
                            :value="chosenSite"
                            :options="clientSites"
                            :multiple="true"
                            :close-on-select="false"
                            placeholder="Act as ..."
                            :allow-empty="false"
                            @input="handleInput($event.length ? $event : [clientSites[0]])"
                            @search-change="searchChange = $event"
                            @open="openSelect"
                            track-by="id"
                            sort-options-all-by="id"
                            label="name"
                        >
                            <template slot="tag" slot-scope="props">
                                <div
                                    class="multiselect__tag"
                                    :class="{ delete: isToDelete(props.option.id), add: isToAdd(props.option.id) }"
                                >
                                    <span>
                                        {{ props.option.name }}
                                    </span>
                                    <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="remove(props.option)"></i>
                                </div>
                            </template>
                            <template slot="option" slot-scope="props">
                                <div>
                                    {{ props.option.$groupLabel || props.option.name }}
                                </div>
                            </template>
                        </multiselect>
                    </div>
                </div>
            </transition>

            <ul v-if="activeTab === 'client'" class="list-unstyled list mt-3">
                <nav-item
                    v-for="(item, index) in accessClientMenu"
                    :key="index + 200"
                    :index="index + 200"
                    :item="item"
                    :isAdmin="tabAdmin"
                    :isMin="isMin"
                    @path="setLastClientPath"
                    @isMin="isMin = $event"
                />
            </ul>
            <!-- v-if="!item.admin || (item.admin && user.level == 'organization-admin' )"-->

            <ul v-if="activeTab === 'admin'" class="list-unstyled list mt-3">
                <nav-item
                    v-for="(item, index) in adminMenu"
                    :key="index + 200"
                    :index="index + 200"
                    :item="item"
                    :isAdmin="tabAdmin"
                    :isMin="isMin"
                    class="admin"
                    @path="setLastAdminPath"
                    @isMin="isMin = $event"
                />
            </ul>

            <div class="flex flex__column items-center justify-center py-6 mt-auto text-2xl sidebar-icons" :class="{ admin: tabAdmin }">

                <router-link :to="{ name: 'settings.profile' }" class="p-4 border-radius icon-background relative  sidebar-icon-user">
                    <div
                        v-if="image"
                        class="sidebar-icon-user-avatar border-radius"
                        :style="`background-image: url(${image});`"
                    />
                    <svg-importer icon-name="menu/user" class="" />

                    <div @click.prevent="showNotifications = true" class="sidebar__notify">
                        <svg-importer icon-name="icons/notifications" />

                        <div v-if="hasNotifications">
                            <div class="sidebar__notify-count">
                                <span>{{ hasNotifications }}</span>
                            </div>
                        </div>
                    </div>
                </router-link>

                <div class="flex flex__column text-center w-full mt-3 user__info" :class="{ 'color-white': tabAdmin }">
                    <span class="f-size-14 f-w-medium text-ellipsis pl-1 pr-1">
                        {{ user.name }}
                    </span>
                    <span class="f-size-14 color-text-secondary text-ellipsis pl-1 pr-1">
                        {{ user.email }}
                    </span>
                </div>

                <button
                    @click.prevent="logout"
                    class="logout-btn btn-transparent exit-wr flex flex__align-center mt-8"
                    :class="[tabAdmin ? 'text-white' : 'text-gray-900']"
                >
                    <svg-importer icon-name="icons/logout" :class="{ 'stroke-white': tabAdmin }" />
                    <span class="f-size-14 ml-2 logout-text">Log Out</span>
                </button>
            </div>
        </nav>

        <notifications :toggled="showNotifications" @toggle="showNotifications = false" @hasNotifications="hasNotifications = $event" />
    </div>
</template>
<style lang="scss" scoped>
    .notifications{
        position:relative;
    }
    .has-notifications::before {
        content: " ";
        display:block;
        width: 8px;
        height: 8px;
        border-radius: 10px;
        background-color:#009DEC;
        position: absolute;
        overflow: hidden;
        bottom: 5px;
        right: -4px;
    }
</style>

<script>
    import clientMenu from "~/data/client-menu.json";
    import adminMenu from "~/data/admin-menu.json";
    import NavItem from "~/components/NavItem";
    import Notifications from "~/components/Notifications";
    import axios from "axios";

    import { adjustColor } from '~/utils/color.js';
    import hasEmergencyPltAccess from '@/utils/pltAccessor.js';

    const customReportReducer = (reports, report) => {
        if (report.is_default || report.name === 'analytics') {
            return reports;
        }

        reports.push({
            title: report.name,
            route: `reports.single`,
            routeParams: { id: report.id },
        });

        return reports;
    };

    export default {
        name: "Sidebar",
        components: {
            NavItem,
            Notifications,
        },
        data() {
            return {
                defaultHideSidebarOffset: 69,
                hideSidebarOffset: 0,
                awaitsFullfillment: [],
                fullfilled: [],
                pickerToggled: [],
                window: {
                    width: 0,
                    height: 0
                },
                organizations: [],
                sites: [],
                chosenSite: [],
                reloadPage: false,
                isMounted: false,
                searchChange: '',
                avatar: '',
                clientLogo: '',
                prevChosenSite: '',
                sOpenSelect: false,
                clientMenuItems: clientMenu,
                toAdd: [],
                toDelete: [],
                showNotifications: false,
                hasNotifications: 0,
                isMin: false,
                logoError: false,
                image: null,
                isClientLogoLoading: false,
            };
        },
        computed: {
            isDisplayDefaultLogo() {
                return !this.isClientLogoLoading && (!this.clientLogo || this.logoError);
            },
            isAllowRemoveSites() {
                return Boolean(
                    this.chosenSite
                        .filter(site => !this.toDelete.includes(site) && !this.toAdd.includes(site))
                        .length > 1
                );
            },
            useSitesOrOrganizationsList() {
                const list = this.useSitesOrOrganizations();

                if (!this.searchChange) {
                    return list;
                }

                const organizations = list.map(item => { return { id: item.id, name: item.name } });
                const sites = list.map(item => {  return item.sites }).flat();

                sites.forEach(item => { 
                    item.organization = organizations.find(_item => { return _item.id === item.organization_id });
                })

                const filter = sites.filter(item => {
                    return item.organization.name.toLowerCase().includes(this.searchChange.toLowerCase()) || item.name.toLowerCase().includes(this.searchChange.toLowerCase());
                });

                const organizationsIds = [...new Set(filter.map(item => { return item.organization.id }))];
                const sitesIds = [...new Set(filter.map(item => { return item.id }))];

                const filterOrganizations = list.filter(item => { return organizationsIds.some(id => { return item.id === id })});

                const result = filterOrganizations.map(item => { 
                    const value = { ...item };

                    value.sites = item.sites.filter(_item => { return sitesIds.some(id => { return _item.id === id })});

                    return value;
                });

                return result;
            },
            location() {
                return window.location.origin;
            },
            selectedCompany() {
                return this.$store.getters["auth/company"];
            },
            authSites() {
                return this.$store.getters["auth/sites"];
            },
            user() {
                return this.$store.getters["auth/user"];
            },
            isAdmin() {
                return this.$store.getters["auth/isAdmin"];
            },
            isGodUser() {
                return this.$store.getters['auth/isGodUser'];
            },
            adminMenu() {
                return adminMenu;
            },
            clientMenu() {
                if (this.user.organization.name === 'DEMOORGANIZATION1') {
                    return this.clientMenuItems.map(item => {
                        if (item.name === 'custom-reports') {
                            item.title = 'Ad-hoc reports';
                        }

                        return item;
                    });
                }
                return this.clientMenuItems;
            },
            activeTab() {
                return this.$store.getters["sidebar/adminMenu"];
            },
            avatarTrigger() {
                return this.$store.state.auth.avatar;
            },
            clientLogoTrigger() {
                return this.$store.state.auth.clientLogo;
            },
            toggled() {
                return this.$store.getters["sidebar/toggled"];
            },
            sideBarRealod() {
                return this.$store.getters["sidebar/reload"];
            },
            tabAdmin() {
                return this.activeTab === 'admin';
            },
            isAdmin() {
                return this.user.level === 'super';
            },
            isOrgAdmin() {
                return this.user.level === 'organization-admin';
            },
            selectedSite: {
                async set(selectedOption) {
                    // don't triger sites info update until fetching queue is not empty
                    if (!this.awaitsFullfillment.length) {
                        const idsFilter = selectedOption.map(option => option.id).join(',');
                        const { data: { data } } = await axios.get(this.$apiUrl.sites.base + `/?filter[id]=${idsFilter}`);
                        
                        selectedOption = data;
                    }

                    this.$store.dispatch('auth/changeSite', selectedOption);
                },
                get() {
                    return this.$store.getters['auth/site'];
                }
            },
            
            organizationLogo() {
                return this.$cookies.get('logo_url') || '';
            },

            clientSites() {
                return this.user.sites;
            },
            applicationsSet() {
                return this.authSites.reduce((accumSet, site) => {
                    const siteValue = Array.isArray(site) ? site[0] : site;
                    if (siteValue && siteValue.applications && siteValue.applications.length) {
                        siteValue.applications.forEach(app => accumSet.add(app));
                    }

                    return accumSet;
                }, new Set());
            },
            accessClientMenu() {
                return this.clientMenu.filter(item => {
                    return this.hasAccess(item) && this.hasPermission(item);
                });
            },
            sitesTrigger() {
                return this.$store.getters['auth/sitesTrigger'];
            },
            allowPltAccess() {
                return hasEmergencyPltAccess(this.user);
            },
        },
        async created() {
            this.isClientLogoLoading = true;
            this.isMin = Boolean(+this.$cookies.get('is-min'));

            this.initSites();
            
            this.initAvatar();
            this.initClientLogo();
            this.initReports();
        },

        async mounted() {
            this.isMounted = true;


            await this.setSite();

            await this.setBrandingSite();

            if(this.$route.name === 'home') {
                this.setMenu('client', { redirect: false });
            }

            if (!localStorage.getItem('role')) {
                localStorage.setItem('role', 'client');
            }

            const routeName = this.$route.name;

            if (!(routeName.includes('.edit') || routeName.includes('.create') || routeName.includes('.detail') || routeName.includes('documents.show'))) {
                this.setMenu(localStorage.getItem('role'), { redirect: false });
            }

            if (routeName.includes('admin')) {
                this.$store.commit("sidebar/ADMIN_MENU", 'admin');
            }

            window.addEventListener("resize", this.handleResize);
            this.handleResize();
            this.loadGlobals();
        },
        destroyed() {
            window.removeEventListener("resize", this.handleResize);
        },
        methods: {
            async initSites() {
                if(this.user.level === 'super') {
                    await this.fetchOrganizations();

                    return;
                }
                
                await this.fetchSites();
            },
            async initAnalytics() {
                if (!this.reports.length) {
                    return;
                }

                this.$store.commit('auth/SET_HAS_ANALYTICS_REPORT', Boolean(this.reports.find(report => report.name === 'analytics')));
            },
            toggledSizeMenu() {
                this.$cookies.set('is-min', this.isMin ? 1 : 0)

                this.$emit('isMin', this.isMin);
            },
            hasApplication(appKey){
                if (!this.authSites || !this.authSites.length) {
                    return;
                }

                if (!this.authSites || !this.applicationsSet.size){
                    return false;
                }

                return this.applicationsSet.has(appKey)
            },
            hasAccess(item) {
                if ('requires' in item) {
                    return this.$store.getters[`${item.requires.state}/${item.requires.getter}`]
                }

                if (item.title === 'Quote & Booking' && hasEmergencyPltAccess(this.user)) {
                    return true;
                }

                if (typeof item.super === 'boolean' && item.super && this.user.level != 'super') {
                    return false;
                }
                
                if (item.application) {
                    return this.hasApplication(item.application);
                }

                if(item.access === undefined) {
                    return true;
                }

                if(item.access === this.user.level || this.user.level === 'organization-admin' || this.user.level === 'super') {
                    return true;
                }
            },
            hasPermission(item) {
                if (!item.permission) {
                    return true;
                }

                return this.user.permissions[item.permission]
            },
            handleInput(value) {
                const toDelete = this.chosenSite.filter(
                    site => !value.find(val => !this.awaitsFullfillment.includes(site.id) && val?.id === site?.id)
                );
                this.toDelete = toDelete;

                const newSite = value.find(val => !this.chosenSite.find(site => site?.id === val?.id))
                this.toAdd = this.toAdd.concat(newSite);

                this.awaitsFullfillment = [...this.awaitsFullfillment, ...toDelete, newSite];

                this.chosenSite = value.sort((a, b) => { return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 })

                this.apply([...toDelete, newSite]);
            },
            isToDelete(id) {
                return Boolean(this.toDelete.find(site => site?.id === id));
            },
            isToAdd(id) {
                return Boolean(this.toAdd.find(site => site?.id === id));
            },
            async fetchSaveSites(site_ids) {
                await axios.post('/api/users/sites-filter', { site_ids });

                await this.$store.dispatch('auth/fetchUser');
            },
            async apply(fullfills) {
                this.$store.commit('auth/SET_SITES_LOADING', true);

                try {
                    const updatedSites = this.chosenSite.reduce((accum, current) => {
                        if (this.isToDelete(current.id)) {
                            return accum;
                        }
    
                        accum.push(current);
    
                        return accum;
                    }, []);
    
                    await this.fetchSaveSites(updatedSites.map(item => { return item.id }));
    
                    this.toDelete = this.toDelete.filter(siteId => !fullfills.includes(siteId));
                    this.toAdd = this.toAdd.filter(siteId => !fullfills.includes(siteId));
                    // queue awaiting for fetch end
                    this.awaitsFullfillment = this.awaitsFullfillment.filter(siteId => !fullfills.includes(siteId));
                    // list of fetched
                    this.fullfilled = [...this.fullfilled, ...fullfills.map(item => item.id)];
    
                    if (!this.awaitsFullfillment.length) {
                        this.chosenSite = updatedSites;
                        this.$store.commit('auth/SET_SITES_LOADING', false);
                        this.fullfilled = [];
                        
                        await this.runReloadPage();
                    }

                } catch(_) {
                    this.$store.commit('auth/SET_SITES_LOADING', false);
                }
            },
            openSelect() {
                this.isOpenSelect = true;
                this.prevChosenSite = JSON.stringify(this.chosenSite)
            },
            runReloadPage() {
                this.isOpenSelect = false;
                
                if (JSON.stringify(this.chosenSite) === this.prevChosenSite) {
                    return;
                }

                this.setSite()
            },
            remove(site) {
                if (!this.isAllowRemoveSites) {
                    return;
                }

                if (this.isToAdd(site.id)) {
                    const toAddIndex = this.toAdd.findIndex(toAddSite => toAddSite.id === site.id);
                    this.toAdd.splice(toAddIndex, 1);

                    const chosenSiteIndex = this.chosenSite.findIndex(chosenSite => chosenSite.id === site.id);
                    this.chosenSite.splice(chosenSiteIndex, 1);

                    return;
                }

                if (this.isToDelete(site.id)) {
                    const toDeleteIndex = this.toDelete.find(toDelete => toDelete.id === site.id);
                    this.toDelete.splice(toDeleteIndex, 1);

                    return;
                }

                this.toDelete.push(site);
            },
            clientLogoError() {
                this.logoError = true;
                this.isClientLogoLoading = false;
                this.hideSidebarOffset = this.defaultHideSidebarOffset;
            },
            clientLogoLoad() {
                this.logoError = false;
                this.isClientLogoLoading = false;
                this.hideSidebarOffset = this.$refs.logoWr.clientHeight;
            },
            getTransparentUrl() {
                let url = window.location.origin;

                if (window.location.origin.includes('.test')) {
                    url = 'https://globl.se'
                }

                const image = '/images/transparent.png';

                url = url + image;
                return url;
            },
            setLastAdminPath(item) {
                this.$store.commit("sidebar/LAST_ADMIN_PATH", item);
            },
            setLastClientPath(item) {
                this.$store.commit("sidebar/LAST_CLIENT_PATH", item);
            },
            async fetchOrganizations() {
                let url = null;

                if(this.user.level === 'super') {
                    url = "?pageSize=1000&include=sites"
                }

                if(url != null) {
                    const { data } = await axios.get(this.$apiUrl.organizations.active + url);

                    this.organizations = data.data;
                    this.setCurrentSite();
                }
            },
            async fetchSites() {
                let url = null;

                if(this.user.level === 'organization-admin') {
                    url = "/sites/" + this.user.organization.id;
                }

                if(url != null) {
                    const { data } = await axios.get(this.$apiUrl.organizations.base + url);

                    this.sites = data.data;
                    this.setCurrentSite();
                }
            },
            useSitesOrOrganizations() {
                if(this.user.level === 'super') {
                    return this.organizations;
                } else {
                    return this.sites
                }
            },
            setCurrentSite() {
                let sites = null;
                let sitesIds = [];
                let match = [];

                sites = this.authSites;
                sitesIds = this.authSites.filter(site => site).map(site => site.id);

                if (!sites.length || sites == null) {
                    match = this.sites.filter(item => sitesIds.includes(item.id));
                    this.chosenSite = match;

                    if(this.user.level === 'super') {
                        this.organizations.forEach(organization => {
                            match = organization.sites.filter(OrganizationSite => sitesIds.includes(OrganizationSite.id))
                            if(match.length > 0 ) {
                                this.chosenSite = match;
                            }
                        });
                    }

                    return;
                }

                if(this.user.level === 'super') {
                    const matchedSites = [];
                    this.organizations.forEach(organization => {
                        match = organization.sites.filter(OrganizationSite => sitesIds.includes(OrganizationSite.id))
                        if(match.length > 0 ) {
                            matchedSites.push(...match);
                        }
                    });
                    this.chosenSite = matchedSites;

                    return;
                }

                match = this.sites.filter(item => sitesIds.includes(item.id));

                if(match.length === 0) {
                    match = this.sites.filter(item => sitesIds.includes(item.id));
                }

                if(match.length > 0 ) {
                    this.chosenSite = match;
                }
            },
            updateSite() {
                this.selectedSite = this.site.id;
            },
            routeLock() {
                let params = (new URL(document.location)).searchParams;
                let name = parseInt(params.get("route-lock"));
                return name;
            },
            getIgnoreLinks() {
                // 'purchase.processes', 'purchase.users', 'purchase.companies',
                const patchs = ['transport.bookings'];

                const result = patchs.some(item => {
                    return item === this.$route.name;
                });
                
                return result;
            },
            setMenu(val, settings) {
                this.initAvatar();

                const ignore = this.getIgnoreLinks();

                if (ignore) {
                    return;
                }

                localStorage.setItem('role', val);

                this.$store.commit("sidebar/ADMIN_MENU", val);

                if (val === 'client' && this.sites?.length) {

                    const updatedOrganizations = this.$store.getters["sidebar/updatedOrganizations"];

                    if (this.sites.find(site => updatedOrganizations.includes(site.organization_id))) {
                        this.updateSite();
                    }
                }

                if(val === 'admin') {
                    const route = this.$store.getters["sidebar/lastAdminPath"];
                
                    if (this.$route.name === 'admin.shipments.detail') {
                        return;
                    } else if (route) {
                        if (!settings.redirect) {
                            return;
                        }

                        this.$router.push({ name: route })
                    } else {

                        if(this.$route.name !== 'admin.organization') {
                            this.$router.push({path: '/admin/organizations'});
                        }
                    }
                } else {
                    const route = this.$store.getters["sidebar/lastClientPath"];

                    if (route) {

                        let push = { name: route };

                        if (route === 'settings.sites.edit' && this.sites?.length) {
                            push.params = {id: this.sites[0].id}
                        }

                        if (route === 'settings.organization.edit' && this.sites?.length) {
                            push.params = {id: this.sites[0]?.organization_id}
                        }

                        if (!settings.redirect) {
                            return;
                        }

                        if (! this.routeLock()) {
                            this.$router.push(push)
                        }
                    } else {
                        if (this.$route.name !== 'home' && this.$route.name !== 'transport.shipments' && ! this.routeLock() ) {

                            this.$router.push({ name: 'home' })
                        }
                    }
                }
            },

            toggleMenu(id) {
                this.$store.commit("sidebar/TOGGLE", id);
            },

            togglePicker() {
                this.$store.commit("sidebar/TOGGLE_PICKER", true);
            },

            handleResize() {
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;
            },

            async fetchSitesList() {
                let url = null;

                if(this.user.level === 'organization-admin') {
                    url = "/sites/" + this.user.organization.id;
                }

                if(url != null) {
                    const { data } = await axios.get(this.$apiUrl.organizations.base + url);

                    return data.data;
                }

                return [];
            },

            loadGlobals() {},

            resetLS() {
                const tableDataKeys = Object.entries(localStorage).filter(entry => entry[0].indexOf('table_') === 0);

                localStorage.clear();

                tableDataKeys.forEach(entry => localStorage.setItem(entry[0], entry[1]));
            },

            async logout() {
                // Log out the user.
                await this.$store.dispatch("auth/logout");
                await this.resetLS();
                this.$store.commit("sidebar/LAST_CLIENT_PATH", 'home');
                this.$store.commit("sidebar/LAST_ADMIN_PATH", 'home');
                this.setBranding('clear')

                // Redirect to login.
                this.$router.push({ name: "login" });
            },

            async setBranding(id) {
                if (id === 'clear') {
                    this.$cookies.remove('brand_color');
                    this.$cookies.remove('logo_url')
                    document.documentElement.style.setProperty('--brand-color', '#2E457C');

                    return;
                }


                if (this.activeTab !== 'client') {
                    this.$cookies.set('brand_color', '#2E457C');
                    document.documentElement.style.setProperty('--brand-color', '#2E457C');

                    return;
                }
            },

            async setBrandingSite() {
                if (this.activeTab !== 'client') {
                    this.$cookies.set('brand_color', '#2E457C');
                    document.documentElement.style.setProperty('--brand-color', '#2E457C');

                    return;
                }

                const organization = this.isAdmin
                    ? this.organizations?.find(i => {
                        return i?.sites.find(site => site.id === this.authSites[0]?.id);
                    })
                    : this.user.organization;

                if (!organization) {
                    return;
                }

                const brandColor = organization.brand_color && (organization.brand_color != '#FFFFFF' && organization.brand_color != '#ffffff') 
                    ? organization.brand_color
                    : '#2E457C';

                this.$cookies.set('brand_color', brandColor);
                this.$cookies.set('logo_url', organization.logo_url);

                document.documentElement.style.setProperty('--brand-color', brandColor);
                document.documentElement.style.setProperty('--brand-color-secondary', adjustColor(brandColor))

                document.documentElement.style.setProperty('--logo', organization.logo_url);
            },

            async setSite() {
                if (!this.chosenSite || !this.chosenSite.length) {
                    return;
                }
                
                this.setBrandingSite();

                this.selectedSite = this.chosenSite;
            },

            currentRoutNameFinder(item) {
                const name = this.$route.name;

                let subitemMatch;
                if (item.items?.length) {
                    subitemMatch = item.items.find(this.currentRoutNameFinder);
                }

                return Boolean(subitemMatch || item.route === name || name.includes(item.route));
            },

            async watchActualRoute() {
                if (this.activeTab === 'client') {
                    const result = this.accessClientMenu.find(this.currentRoutNameFinder);

                    if (!result) {
                        this.$router.push({ name: 'home' })
                    }
                }
            },

            async initClientLogo() {
                try {
                    this.logoError = false;
                    this.clientLogo = `${process.env.VUE_APP_API}/api/organizations/${this.user.organization.id}/logo?${Date.now()}`;
                } catch (_) {
                    this.clientLogo = '';
                }
            },

            async initReports() {
                const reportsMenuItem = this.clientMenuItems.find(menuItem => menuItem.application === 'custom_reports');;

                reportsMenuItem.loading = true;

                try {
                    const { data: { data } } = await axios.get(
                        `${this.$apiUrl.powerBi.base}?organization_id=${this.user.organization.id}&pageSize=1000`
                    );
                    this.reports = data;

                    this.initAnalytics();

                    reportsMenuItem.items = data.reduce(customReportReducer, []);

                    if (reportsMenuItem.items.length) {
                        delete reportsMenuItem.route;
                    }
                } catch (error) {
                    console.error(error);
                }
                
                reportsMenuItem.loading = false;
            },

            async initAvatar() {
                try {
                    this.image = `${process.env.VUE_APP_API}/api/users/${this.user.id}/avatar?${Date.now()}`;
                } catch (_) {
                    this.image = '';
                }
            },

            redirectHome() {
                this.$router.push({ name: 'home' });
            },

            getSitesOptionsMatch(options) {
                return options.some(site => {
                    return this.chosenSite.some(chSite => chSite?.id === site?.id);
                });
            },
        },
        watch: {
            isMin() {
                this.toggledSizeMenu();
            },
            sideBarRealod() {
                this.setSite();

                this.selectedSite = this.user.sitesFilter.filter(site => site).map(site => ({ id: site.id }));
                this.chosenSite = this.user.sitesFilter.sort((a, b) => { return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 });
            },
            activeTab(val) {
                this.setBrandingSite();

                if (val === 'client') {
                    this.initReports();
                }
            },
            avatarTrigger() {
                this.initAvatar();
            },
            clientLogoTrigger() {
                this.initClientLogo();
            },
            authSites(sites) {
                this.setBrandingSite();

                if (this.isAdmin) {
                    return;
                }

                const applicationsSet = new Set(sites.map(site => site.applications).filter(applications => applications).flat());
                if (!applicationsSet.has('emissions') && this.$route.name === 'transport.emissions') {
                    this.redirectHome();

                    return;
                }

                if (!applicationsSet.has('proxio_price_leadtime') && ['transport.priceleadtime'].includes(this.$route.name) && !this.allowPltAccess) {
                    this.redirectHome();

                    return;
                }

                if (!applicationsSet.has('purchase') && this.$route.name.indexOf('purchase') !== -1) {
                    this.redirectHome();
                }
            },
            accessClientMenu: {
                handler: function() {
                    this.watchActualRoute();
                },
                deep: true,
            },
            async sitesTrigger() {
                await this.initSites();

                if (!this.chosenSite.length) {
                    this.chosenSite = this.user.level === 'super'
                        ? [this.organizations.map(org => org.sites).flat()[0]]
                        : [this.sites[0]];
                }

                this.fetchSaveSites(this.chosenSite.map(site => site.id));
            },
            clientSites(newSites) {
                if (this.isAdmin) {
                    return;
                }

                if (this.getSitesOptionsMatch(newSites)) {
                    return;
                }

                this.chosenSite = newSites[0] ? [newSites[0]] : [];
            },
            useSitesOrOrganizationsList(newOrganizations) {
                if (!this.isAdmin) {
                    return;
                }

                const sites = newOrganizations.map(organization => organization.sites).flat();
                if (this.getSitesOptionsMatch(sites)) {
                    return;
                }

                this.chosenSite = sites[0] ? [sites[0]] : [];
            },
        }
    };
</script>

<style lang="scss">
.sidebar {
  @apply fixed top-0 left-0 bottom-0 pt-4 px-0 bg-white border-0 overflow-y-auto;

  z-index: 998 !important;
  height: 100vh;
  overflow-x: hidden;

    &__toggled-icon {
        background: #FFFFFF;
        box-shadow: 0px 0px 9px rgba(38, 45, 51, 0.1);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 0;
        top: 16px;
        margin-left: 195px;
        cursor: pointer;
        z-index: 999;
        transition: margin ease .3s;

        &.min {
            margin-left: 56px;
        }
    }

  .hide-removes {
    .multiselect__tag {
        .multiselect__tag-icon {
            display: none;
        }

        &.add, &.delete {
            .multiselect__tag-icon {
                display: initial;
            }
        }
    }
  }

  .logo-wr {
      padding: 0 16px;
  }

  li a {
      padding-left: 9px;
  }

  li ul {
      a {
          white-space: nowrap;
      }
  }

  .user-wr {
      min-width: 4rem;
      min-height: 4rem;
      max-width: 4rem;
      max-height: 4rem;
      overflow: hidden;
      border-radius: 50%;

      img {
          height: auto;
          width: 100%;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
      }
  }

  .apply-button-wrapper {
    height: 36.5px;
  }

    .multiselect__tag {
        padding-right: 20px;
    }

  .multiselect__tag.delete {
      background: #000000 !important;
      opacity: .4;
  }

  .multiselect__tag.add {
      background: var(--brand-color) !important;
      opacity: .4;
  }

  .multiselect__tag-icon {
    position: absolute;
    right: 0;
  }

  .sidebar-item.admin {
    svg path {
        stroke: #FFFFFF;
    }
  }

  .sidebar-item__icon {
      min-width: 2.5rem;
  }

  .sidebar-item__text {
      white-space: nowrap;
  }

  .multiselect-wr {
      min-width: 182px;
  }

  a {
    @apply text-gray-900;
  }

  a:hover {
    //@apply underline;
  }

  .router-link-active, .router-link-exact-active {
    //@apply text-black font-bold;
  }
}

.sidebar-admin {
    @apply bg-gray-1000;

    a {
        color: #fff;
    }
}

.sidebar-icons.admin button:hover {
    svg {
        color: rgba(255,255,255,0.8) !important;
    }
}

.logout {
      &-text {
        display: initial;
      }
  
      &-btn {
        border: 1px solid rgba(197, 197, 197, var(--border-opacity));
        padding: .5rem 1.25rem .55rem;
      }
    }

.sidebar__notify {
    position: absolute;
    display: flex;
    align-items: center;
    top: 32px;
    left: 40px;
    height: 32px;
    padding: 0 4px;
    border-radius: 100px;
    box-shadow: 0px 0px 9px rgba(38, 45, 51, 0.1);
    background-color: white;
}

.sidebar__notify-count {
    background-color: #EB5757;
    color: white;
    padding: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    margin-left: 4px;
    border-radius: 100px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar__toggled {
    min-height: 32px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.sidebar-icon-user {
    position: relative;

    .sidebar-icon-user-avatar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
    }
}

.sidebar-min {
    width: 72px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;

    .sidebar__toggled-icon {
        margin-right: -28px;
    }

    .user__info {
        display: none;
    }

    .logo-wr {
        padding: 0;
    }

    .sidebar-link {
        display: flex;
        align-items: center;
        justify-content: center;

        .sidebar-item__icon {
           min-width: auto !important;
        }
        .sidebar-item__text {
            opacity: 0;
            display: none;
        }

        .text-2xl {
            opacity: 0;
            display: none;
        }
    }

    .logout-btn {
        margin-left: 16px;
        margin-right: 16px;
    }

    .logout-btn span {
        display: none;
    }

    ul.nav-item-sublist {
        display: none;
    }

    .logout-btn {
        padding: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sidebar-icons {
        padding-left: 3px;
        padding-right: 3px;
    }

    .sidebar__notify {
        left: 50%;
        transform: translateX(-50%);
        top: 31px;
    }

    .sidebar-icon-user {
        padding: 0 !important; 
        height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .sidebar__notify-count {
        padding: 8px;
        margin-left: 2px;
        font-size: 12px;
        display: flex;
        max-width: 30px;

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.sidebar {
    .sidebar-link {
        transition: padding .2s;

        .sidebar-item__text {
            transition: opacity .1s;
        }

        .text-2xl {
            transition: opacity .1s;
        }
    }
}

.fade-sidebar-enter-active, .fade-sidebar-leave-active {
    transition: height .2s;
}
.fade-sidebar-enter, .fade-sidebar-leave-to {
    opacity: 0;
    height: 0px;
}
</style>
