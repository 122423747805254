<template>
    <div class="card" @click="$emit('click')">
        <div
            v-if="title || hasTitleSlot"
            class="flex items-center px-6 py-5 card-header bg-white border-radius-t"
            :class="{'cursor-pointer': accordion, 'border-radius': !titleNoBorder }" @click="toggleAccordion()"
        >
            <div v-if="title && !hasTitleSlot" class="flex items-center">
                <fa :icon="icon" :class="iconClass" v-if="icon" />
                <h4 :class="titleClass">{{ title }}</h4>
            </div>

            <div v-if="hasTitleSlot && !title">
                <slot name="title" />
            </div>

            <div class="flex ml-auto" v-if="hasToolSlot || hasTabsSlot || accordion">

                <div v-if="hasTabsSlot" class="card-tabs">
                    <slot name="tabs" />
                </div>

                <div v-if="hasToolSlot">
                    <slot name="tools" />
                </div>

                <div v-if="accordion">
                    <div v-if="!accordionOpen">
                        <fa class="text-3xl" :icon="['fal','chevron-down']" />
                    </div>

                    <div v-if="accordionOpen">
                        <fa class="text-3xl" :icon="['fal','chevron-up']" />
                    </div>
                </div>

            </div>
        </div>

        <div class="flex px-6 card-body" :class="bodyClass" v-show="showBody">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Card',

    props: {
        title: { type: String, default: null },
        icon: { type: Array, default: null },
        bodyClass: { type: String, default: () => ''},
        iconClass: { required: false, default: 'text-2xl mr-2', type: String },
        titleClass: { required: false, default: 'capitalize', type: String },
        accordion: { required: false, default: false, type: Boolean },
        titleNoBorder: { required: false, default: false, type: Boolean },
    },

    data: () => ({
        accordionOpen: false,
    }),

    computed: {
        hasToolSlot () {
            return this.$slots['tools']
        },
        hasTitleSlot() {
            return this.$slots['title']
        },
        hasTabsSlot () {
            return this.$slots['tabs']
        },
        showBody() {
            if (this.accordion) {
                return this.accordionOpen;
            }

            return true;
        }
    },

    methods: {
        toggleAccordion() {
            this.accordionOpen = !this.accordionOpen
        }
    }
}
</script>

<style lang="scss" scoped>
    .card-tabs {
        @apply mt-auto flex;

        div, a {
            margin-bottom: -0.85rem;
            @apply inline-block mr-8 text-gray-500 pb-3 cursor-pointer;

            &.active {
                @apply border-b-2 border-gray-900 text-gray-900;
            }

            &:hover {
                @apply text-gray-600;

                text-decoration: none;
            }
        }

        div:last-child,a:last-child {
            @apply mr-0
        }
    }
</style>
