<template>
    <div class="main-layout" :class="{ 'main-layout--table': isTableLayout }">
        <div class="container-fluid">
            <div class="row">
                <sidebar v-if="initPage" @isMin="isMin = $event" />
                <div class="pl-8 pr-6 pt-6 pb-24 content content-page-mode" :class="{ 'is-min': isMin }">
                    <!--<navbar />-->
                    <child class="relative z-1" />
                </div>
            </div>
        </div>
        <vue-snotify />
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import Sidebar from "~/components/Sidebar";
import { adjustColor } from '~/utils/color.js';

export default {
    name: "MainLayout",

    data() {
        return {
            initPage: false,
            isMin: false,
        }
    },

    created() {
        this.initFrontChat();
    },

    computed: {
        user() {
            return this.$store.getters["auth/user"];
        },
        isTableLayout() {
            return this.$route.name === 'admin.shipments';
        },
    },

    async mounted() {
        this.isMin = Boolean(+this.$cookies.get('is-min'));
        this.setBranding();
        this.checkExpiredReminders();

        setTimeout(()=> { this.initPage = true });
    },

    components: {
        Sidebar,
    },

    methods: {
        async checkExpiredReminders() {
            if (this.user.level !== 'super') {
                return;
            }
            
            const currentDate = moment().format('YYYY-MM-DD');
            const monthDate = moment().subtract(30, 'days').format('YYYY-MM-DD');

            const result = await axios.get(`${this.$apiUrl.consignments.base}?filter[date_between]=${monthDate},${currentDate}&filter[under_reminder_expired]=1&pageSize=1`);

            const total = result.data.meta.total || 0;

            if (total <= 0) {
                return;
            }

            if (total === +this.$cookies.get('notify_expired_reminders_total')) {
                return;
            }

            this.$snotify.clear()

            this.$snotify.html(`
                <div class="snotify__link snotify__link_reminders">
                    <div class="snotifyToast__body">You have ${total} expired reminders</div>

                    <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times ml-2 snotify__link_reminders_close" style="transform-origin: 0.3125em 0.5em;  width: 16px; height: 16px"><g transform="translate(160 256)" class=""><g transform="translate(0, 0)  scale(1.375, 1.375)  rotate(0 0 0)" class=""><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" transform="translate(-160 -256)" class=""></path></g></g></svg>
                </div>
            `, {
                timeout: 0,
                showProgressBar: false,
            });

            setTimeout(() => {
                document.querySelector('.snotify__link_reminders')?.addEventListener('click', () => {
                    window.location = `/admin/shipments?filterParams=filter[date_between]=${monthDate},${currentDate}__filter[under_reminder_expired]=1`;
                    this.$snotify.clear();
                });

                document.querySelector('.snotify__link_reminders_close')?.addEventListener('click', (e) => {
                    e.preventDefault();
                    e.stopPropagation()

                    this.$cookies.set('notify_expired_reminders_total', total);
                    this.$snotify.clear();
                });
            });
        },
        setBranding() {
            const color = this.$cookies.get('brand_color') || '#2E457C';
            const logo = this.$cookies.get('logo_url') || '';

            document.documentElement.style.setProperty('--brand-color', color);
            document.documentElement.style.setProperty('--brand-color-secondary', adjustColor(color));
            document.documentElement.style.setProperty('--logo', logo);
        },
        initFrontChat() {
            if (process.env.VUE_APP_ENV != 'prod') {
                return;
            }

            window.FrontChat("init", {
                chatId: "29023b355ac73816baaf604c919748c7",
                useDefaultLauncher: true,
                email: this.user.email,
                userHash: this.user.frontdesk_key
            });
        },
    },
}
</script>

<style lang="scss">
.snotify__link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding-left: 15px;
    display: flex;
    align-items: center;
}
</style>