export function requestErrors(error) {
    const message = error?.response?.data?.errors || error?.response?.data?.message;

    if (Array.isArray(message)) {
        return message.join(', ');
    }

    if (typeof message === 'string') {
        return message;
    }

    const result = [];

    for (let key in message) {
        result.push(message[key]);
    }

    return result.join(', ');
}