<template>
  <div class="flex flex-wrap">
    <div v-for="(image, index) in images" :key="index" :style="`background-image: url('/storage/${image.small}')`" :class="itemClass" @click="setCurrent(image)" />

    <transition name="fade">
      <div v-if="inLightBox" class="overlay flex h-full flex items-center justify-center p-10 cursor-pointer" @click="inLightBox = false">
        <img :src="'/storage/' + currentImage.medium">
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Gallery',

  props: {
    images: {
      type: Array,
      default: () => ([])
    },
    itemClass: String,
    openBlank: Boolean
  },

  data: () => ({
    inLightBox: false,
    currentImage: {}
  }),

  methods: {
    setCurrent (image) {
      this.currentImage = image

      if (this.openBlank) {
        window.open(window.config.appUrl + '/storage/' + image.full, '_blank')
      } else {
        this.inLightBox = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
    img {
        object-fit: contain;
        max-height: 100%;
        max-width: 100%;
    }
</style>
