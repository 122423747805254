<template>
    <div class="overflow-y-auto">
        <transition name="fade">
            <div class="overlay" @click="toggle()" v-if="toggled"></div>
        </transition>

        <transition name="side">
            <div v-if="toggled" class="notifications bg-white fixed top-0 bottom-0 w-96 overflow-y-auto">
                <div class="text-sm px-6 py-6 flex border-b border-gray-400">
                    <h2 class="font-normal">Notifications</h2>

                    <div class="ml-auto text-lg flex items-center">
                        <button type="button" class="border-none bg-transparent" @click="toggle()">
                            <svg-importer icon-name="icons/close" />
                        </button>
                    </div>
                </div>

                <div v-if="notifications.length"> 
                    <div v-for="(item, index) in notifications" :key="index" class="px-8 py-5 border-b cursor-pointer notifications_item" :class="{ 'notifications_is-read': item.read_at }" @click="fetchRead(item)">
                        <div class="flex">
                            <p class="text-lg">{{ item.data.subject }}</p>
                        </div>

                        <p class="mt-4 text-gray-700">{{ formatDate(item.updated_at) }}</p>

                        <div class="mt-4 text-gray-700">
                            <span class="break-words" v-html="formatContent(item.data.content)"></span>
                        </div>
                    </div>
                </div>
                <p v-else class="text-gray-700 pl-6 pt-8">
                    Notification list is empty
                </p>
            </div>
        </transition>
    </div>
</template>

<script>
    import moment from 'moment'
    import axios from 'axios'

    export default {
        name: 'Notifications',

        props: {
            toggled: {
                type: Boolean,
                default: () => false
            }
        },

        data: () => ({
            notifications: [],
            fetchInterval: 60000,
            includeRead: false,
        }),

        computed: {
            initialized() {
                return this.$store.getters['notifications/initialized'];
            },
            fetchTrigger() {
                return this.$store.getters['notifications/updateTrigger'];
            },
        },

        watch: {
            fetchTrigger() {
                this.fetchNotifications();
            },
        },

        async created () {
            if (this.initialized) {
                return;
            }

            await this.$store.dispatch('notifications/init');
        },

        methods: {
            formatDate (dateString) {
                let m = moment(dateString).fromNow()

                return m
            },
            formatContent (content) {
                return content.replace(/\b(https?\:\/\/\S+)/mg, '<a target="_blank" href="$1">$1</a>');
            },
            toggle () {
                this.$emit('toggle')
            },
            async isHasNotifications() {
                const fetchHasNotification = await axios.get(`/api/front-notifications?pageSize=10000&filters[read]=0`);

                this.$emit('hasNotifications', fetchHasNotification.data.data.length);
            },
            async fetchRead(item) {
                if (item.read_at) {
                    return;
                }

                item.read_at = true;
                
                await axios.patch('/api/front-notifications/read', { ids: [item.id] });

                await this.isHasNotifications();
            },
            async fetchNotifications () {
                if (!this.initialized) {
                    this.$store.commit('notificationsf/SET_INITIALIZED', true);
                }

                try {
                    const { data } = await axios.get(`/api/front-notifications?pageSize=100`)

                    this.notifications = data.data;

                    await this.isHasNotifications();
                } catch (error) {
                    console.error('Couldn\'t fetch notifications')
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.overflow-y-auto{
    overflow-y: auto;
}

.notifications {
    z-index: 999;

    &_item {
        background-color: rgba(0,0,0,0.03);
    }

    &_is-read {
        background-color: #fff;
    }
}
</style>
