import axios from 'axios'
import ls from 'local-storage'
import { doSort, getProp, serializeParams } from './utils'
import moment from "moment";
import { serializeParamsResponse } from '~/utils/response';

export default {
    data: () => ({
        id: null,
        user: null,
        columns: [],
        // sortKey: 'updated_at',
        sortKey: '',
        sortOrder: -1, // 1 asc, -1 desc
        page: 1,
        pageSize: 50,
        maxPageLinks: 7,
        endpoint: null,
        exportEndpoint: null,
        rows: [],
        currentPage: null,
        loading: null,
        queryParams: false,
        hasDetail: false,
        urlPath: null,
        filter: null,
        exportKeys: [],
        router: null,
        route: null,
        allRowsChecked: false,
        checkedRows: [],
        checkable: false,
        filterParams: {},
        responseProperty: 'data',
        meta: null,
        noCache: false,
        onRowsLoaded: null,
        onRowsPreloaded: null,
        toggledColumns: [],
        trigger: 0,
        isLoading: true,
        sendResponse: false,
        serializeParams: false,
        filters: {},
        fetchRowsTimeout: null,
    }),

    watch: {
        checkedRows(to, from) {
            if (this.checkedRows.length < this.visibleRows.length) {
                this.allRowsChecked = false
            }else if(this.checkedRows.length === this.visibleRows.length && this.visibleRows.length > 0){
                this.allRowsChecked = true;
            }
        },
        columns: {
            handler() {
                this.setToggledColumns();

                if (this.id && !this.noCache){
                    ls.set(`${this.localStorageName}_v3`, JSON.stringify(this.columns));
                }
            },
            deep: true,
        },
        toggledColumnsWatch(value) {
            this.setToggledColumns();
        }
    },

    computed: {
        localStorageName() {
            return `table_${this.id}_columns`;
        },
        filteredRows() {
            if (!this.filter || this.queryParams) {
                return this.rows
            }

            return this.rows.filter(row => {
                let filters = this.filter.toLocaleLowerCase().split(' ')
                let rowString = Object.values(row).join(' ')
                let allMatch = true

                filters.forEach(filter => {
                    if (!rowString.toLocaleLowerCase().includes(filter)) {
                        allMatch = false
                    }
                })

                return allMatch
            })
        },
        visibleRows() {
            if (this.queryParams) {
                return this.rows
            }

            if (this.rows && this.rows.length > 0) {
                return doSort(this.filteredRows, this.sortKey, null, this.sortOrder);
            }

            return []
        },
        total() {
            if(this.meta){
                return this.meta.total;
            }

            return this.filteredRows ? this.filteredRows.length : 0
        },
        from() {
            if(this.meta){
                return this.meta.from - 1;
            }

            return (this.page - 1) * this.pageSize
        },
        to() {
            if(this.meta){
                return this.meta.to;
            }

            let from = this.from + this.pageSize
            return from > this.total ? this.total : from
        },
        lastPage() {
            if(this.meta){
                return this.meta.last_page;
            }

            return Math.ceil(this.total / this.pageSize)
        },
        toggledColumnsWatch() {
            if (!this.columns.length) {
                return [];
            }

            return this.columns;
        },
        toggleableColumns() {
            return this.columns.filter(col => !['', '#', 'actions'].includes(col.title));
        },
        columnsLength(){
            let length = this.toggledColumns.length;

            if(this.checkable){
                length++;
            }

            if(this.hasDetail){
                length++;
            }

            return length
        },
        paginationItems() {
            const maxPageLinks = this.maxPageLinks
            const displayPages = []

            const totalTiers = Math.ceil(this.lastPage / maxPageLinks)

            const activeTier = Math.ceil(this.page / maxPageLinks)

            const start = (activeTier - 1) * maxPageLinks + 1
            const end = start + maxPageLinks

            if (activeTier > 1) {
                displayPages.push({
                    title: '...',
                    value: start - 1
                })
            }

            for (let i = start; i < end; i++) {
                if (i > this.lastPage) {
                    break
                }

                displayPages.push({
                    title: i.toString(),
                    value: i
                })
            }

            if (activeTier < totalTiers) {
                displayPages.push({
                    title: '...',
                    value: end
                })
            }

            return displayPages
        },

    },

    methods: {
        setToggledColumns() {
            const findDraggable = this.columns.find(item => item.hasOwnProperty('pin'));

            if (findDraggable) {
                this.loading = true;

                this.toggledColumns = this.columns
                    .filter(col => {
                        return col.key === 'actions' || (col.toggled && (col.pin || !col.pin))
                    }).sort((a, b) => {
                        return a.index - b.index;
                    });

                this.loading = false;
            }

            this.toggledColumns = this.columns.filter(col => col.toggled);
        },
        getParams(refreshRfid) {
            let rfid = this.rfid();

            let params = {
                sortKey: this.sortKey,
                sortOrder: this.sortOrder,
                page: this.page,
                pageSize: this.pageSize,
                rfid: rfid
            };

            if (this.filter) {
                params['filter[search]'] = this.filter;
            }

            return params
        },
        setColumns(columns) {
            if (ls.get(`${this.localStorageName}_v3`)) {
                delete localStorage[`${this.localStorageName}_v3`];
            }

            const storedColumnsString = ls.get(`${this.localStorageName}_v4`);

            if (storedColumnsString && this.id) {
                const storedColumns = JSON.parse(storedColumnsString);
                const storedColumnsKeys = storedColumns.map(column => column.key);
                const availableColumnsKeys = columns.map(column => column.key);
                const newColumnsKeys = availableColumnsKeys.filter(columnKey => !storedColumnsKeys.includes(columnKey));
                this.columns = [
                    ...storedColumns.filter(column => availableColumnsKeys.includes(column.key)),
                    ...columns.filter(column => newColumnsKeys.includes(column.key)),
                ];
            } else {
                this.columns = columns;
            }
        },
        exportData() {
            let model = this.id;
            let data;
            let columns = this.toggledColumns;

            if (model === 'consignments') {
                data = this.visibleRows.map(i => i.consignment_id)
            } else {
                data = this.visibleRows.map(i => i.id)
            }

            let dataObject = {
                "data": data,
                "columns": columns,
                "model": model,
            };

            const url = this.exportEndpoint ? this.exportEndpoint : `/api/${model}/export`;

            axios({
                url: url,
                method: "POST",
                responseType: "blob",
                contentType: "multipart/form-data",
                data: dataObject,
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    `${model}-${moment().format("YYYY-MM-DD-HH-mm-ss")}.xlsx`
                );
                document.body.appendChild(link);
                link.click();
            });
        },
        syncState(query) {
            if (query) {
                this.sortKey = query.sortKey
                this.sortOrder = query.sortOrder
                this.page = parseInt(query.page)
                this.pageSize = parseInt(query.pageSize)
                this.filter = query['filter[search]']
            }
        },
        commitState(refreshRfid) {
            if (!this.queryParams) {
                return;
            }

            const scrollY = window.scrollY;

            this.router.push({
                query: this.getParams(refreshRfid)
            }).catch(err => {
                //console.error(err)
            }).finally(() => {
                window.scrollTo(0, scrollY)
            })
        },
        setPageSize(pageSize){
            this.pageSize = pageSize
            this.commitState()
        },
        setFilter (term) {
            // if (!term) return;

            this.filter = term
            this.commitState()
        },
        setExportKey(key) {
            let idx = this.exportKeys.indexOf(key)

            if (idx === -1) {
                this.exportKeys.push(key)
            }
        },
        setSort({
            sortKey,
            sortOrder
        }) {
            this.sortKey = sortKey
            this.sortOrder = sortOrder

            this.commitState()
        },
        goToNext() {
            if (this.page === this.last_page) {
                return
            }

            this.goToPage(this.page + 1);
        },
        goToPrev() {
            if (this.page === 1) {
                return
            }

            this.goToPage(this.page - 1);
        },
        goToPage(page) {
            if (this.page === page) return

            this.page = page
            // this.loading_page = page;
            localStorage.filterPage = page;
            this.commitState()
        },
        async fetchRowsMethod() {
            this.trigger++;

            if (this.onRowsPreloaded) {
                this.onRowsPreloaded();
            }

            if (!this.endpoint) {
                this.rows = []

                this.isLoading = false;
                return
            }

            this.loading = true

            let params = {
                ...this.filterParams,
                ...this.getParams()
            }

            try {
                let url = this.endpoint + '?' + serializeParams(params);

                if (this.serializeParams) {
                    url = this.endpoint + '?' + serializeParamsResponse(this.getParams, this.filters);
                }

                const { data } = await axios.get(url);

                this.rows = getProp(data,this.responseProperty)

                if (data.meta){
                    this.meta = data.meta;
                }

                this.loading = false
                this.$emit('loaded', 'hepp')

                if (this.onRowsLoaded) {
                    this.onRowsLoaded(data.data);
                }

                this.sendResponse = true;
                this.isLoading = false;
            } catch (error) {
                this.rows = []
                this.loading = false

                this.isLoading = false;
            }
        },
        async fetchRows() {
            if (this.fetchRowsTimeout) {
                clearTimeout(this.timeout);
            }

            this.fetchRowsTimeout = setTimeout(() => this.fetchRowsMethod(), 150);
        },
        async fetchRowsSilent() {
            if (!this.endpoint) {
                return false;
            }

            let params = {
                ...this.filterParams,
                ...this.getParams()
            }

            try {
                this.rows = this.rows.map(row => {
                    row.loading = true;
                    return row;
                });

                let url = this.endpoint + '?' + serializeParams(params);

                if (this.serializeParams) {
                    url = this.endpoint + '?' + serializeParamsResponse(this.getParams, this.filters);
                }

                const { data } = await axios.get(url);

                this.rows = getProp(data,this.responseProperty)

                if (data.meta){
                    this.meta = data.meta;
                }

                if (this.onRowsLoaded) {
                    this.onRowsLoaded(data.data);
                }
            } catch (error) {
                this.rows = this.rows.map(row => {
                    row.loading = false;
                    return row;
                });
                
                return false;
            }
        },
    },
}
