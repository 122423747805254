<template>
    <modal-field :label="label" :required="required">
        <multiselect
            :taggable="dynamic"
            :label="selectLabel"
            v-model="internalValue"
            :options="customOptions"
            :multiple="multiple"
            class="relative"
            :track-by="selectValue ? selectValue : null"
            :placeholder="placeholder"
            ref="multiselect"
            :disabled="disabled"
            :class="{'is-invalid' : error, 'no-branding': ! branding, 'multi-select_dynamic': dynamic }"
            :open-direction="openDirection"
            @tag="addTag"
            @search-change="emitSearch($event)"
            @remove="remove($event)"
            @select="select($event)"
            @update-all="updateAll"
        >

            <template slot="clear" v-if="allowClear">
                <div v-show="showAllowClear"
                     @mousedown.prevent.stop="clearInternalValue()"
                     class="w-4 absolute top-0 bottom-0 right-0 z-10 flex items-center mr-10 cursor-pointer"
                >
                    <svg-importer icon-name="icons/close" />
                </div>
            </template>


            <template slot="noOptions">
                <div class="opacity-50">
                    {{ noOptions }}
                </div>
            </template>

        </multiselect>

        <transition name="fade">
            <span v-if="error" class="w-full text-red-500 help-block">
                <span>{{ error[0] }}</span>
            </span>
        </transition>
    </modal-field>

</template>

<script>
    export default {
        name: 'MultiSelect',

        props: {
            branding: {
                required: false,
                default: true,
                type: Boolean
            },
            value: {},
            options: {},
            label: {},
            selectLabel: {
                default: '',
            },
            selectValue: {
                default: '',
            },
            error: {},
            placeholder: {
                required: false,
                default: 'Select option',
                type: String
            },
            dynamic: {
                required: false,
                default: false,
                type: Boolean
            },
            multiple: {
                required: false,
                default: false,
                type: Boolean
            },
            allowClear: {
                required: false,
                default: true,
                type: Boolean
            },
            noOptions: {
                required: false,
                default: 'List is empty.',
                type: String
            },
            required: {
                required: false,
                default: false,
                type: Boolean
            },
            numeric: {
                default: false,
                type: Boolean,
            },
            disabled: {
                default: false,
                type: Boolean,
            },
            openDirection: String,
        },

        data: () => ({
            internalValue: '',
            customOptions: [],
        }),

        computed: {
            showAllowClear() {
                return this.internalValue?.length;
            },
        },

        watch: {
            options() {
                this.customOptions = this.options;
            },
            internalValue() {
                if (this.multiple && this.internalValue === "") {
                    this.internalValue = [];
                }
                this.emitChange()
            },
            value() {
                this.internalValue = this.value
            }
        },

        created () {
            this.customOptions = this.options;
            this.internalValue = this.value;
        },

        mounted() {
            if (this.numeric) {
                this.$refs.multiselect.$el.querySelector('input').type = 'number'
            }
        },

        methods: {
            updateAll(value) {
                this.internalValue = value;

                this.emitChange();
            },

            emitSearch(data) {
                this.$emit('search', data)
            },

            select(data) {
                this.$emit('change', data)
            },

            emitChange() {
                this.$emit('input', this.internalValue)
            },

            clearInternalValue() {
                this.internalValue = '';
            },

            addTag(tag) {
                // If we should accept multiple values and internalValue
                // is not yet an array.
                if (! this.internalValue && this.multiple) {
                    this.internalValue = [];
                }

                let data = tag;

                // If we want the value as an array we map the value accordingly
                if (this.selectValue) {
                    data = {}
                    data[this.selectLabel] = tag
                    data[this.selectValue] = tag
                }

                // Add the new value as an option
                this.customOptions.push(data);

                // If we accept multiple values, we need to push it...
                if (this.multiple) {
                    this.internalValue.push(data)
                } else { // ...otherwise we just override it.
                    this.internalValue = data;
                }
            },

            remove(data) {
                this.$emit('remove', data);
            },
        }
    }
</script>

<style lang="scss">
.multiselect.no-branding .multiselect__option--highlight {
    @apply bg-blue-900 #{!important};
}

.multi-select_dynamic {
    .multiselect__tags {
        padding-right: 50px;
    }
}
</style>
