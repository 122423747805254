// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'
export const FETCH_USER_TREE = 'FETCH_USER_TREE'
export const CHANGE_SITE = 'CHANGE_SITE'
export const CHANGE_SITE_STOP = 'CHANGE_SITE_STOP'
export const SET_TOKEN_EXPIRED = 'SET_TOKEN_EXPIRED'
export const CHANGE_AVATAR = 'CHANGE_AVATAR'
export const CHANGE_CLIENT_LOGO = 'CHANGE_CLIENT_LOGO';
export const SET_SITES_LOADING = 'SET_SITES_LOADING';
export const SET_SITES_TRIGGER = 'SET_SITES_TRIGGER';

// lang.js
export const SET_LOCALE = 'SET_LOCALE'

// sidebar.js
export const TOGGLE = 'TOGGLE'
export const TOGGLE_PICKER = 'TOGGLE_PICKER'
export const ADMIN_MENU = 'ADMIN_MENU'
export const LAST_ADMIN_PATH = 'LAST_ADMIN_PATH'
export const LAST_CLIENT_PATH = 'LAST_CLIENT_PATH'
export const UPDATED_ORGANIZATIONS = 'UPDATED_ORGANIZATIONS'

// filter.js
export const SET_FILTER_CARRIERS        = 'SET_FILTER_CARRIERS'
export const SET_FILTER_TRANSPORT_MODES = 'SET_FILTER_TRANSPORT_MODES'
export const SET_FILTER_FROM_COUNTRIES  = 'SET_FILTER_FROM_COUNTRIES'
export const SET_FILTER_FROM_CITIES     = 'SET_FILTER_FROM_CITIES'
export const SET_FILTER_TO_COUNTRIES    = 'SET_FILTER_TO_COUNTRIES'
export const SET_FILTER_TO_CITIES       = 'SET_FILTER_TO_CITIES'
export const SET_FILTER_SITES           = 'SET_FILTER_SITES'
export const SET_FILTER_OPTIONS_FETCHED = 'SET_FILTER_OPTIONS_FETCHED'
export const SET_FILTER_STATE           = 'SET_FILTER_STATE'

// price-leadtime
export const SET_CONSIGNOR_SHIPMENT = 'SET_CONSIGNOR_SHIPMENT'
export const SET_INVOICE_DETAILS    = 'SET_INVOICE_DETAILS'
export const SET_SHIPMENT_DETAILS   = 'SET_SHIPMENT_DETAILS'
export const SET_SUMMARY            = 'SET_SUMMARY'
export const SET_ADDRESS            = 'SET_ADDRESS'
export const SET_GOODS              = 'SET_GOODS'
export const SET_GOODS_ITEMS        = 'SET_GOODS_ITEMS'
export const SET_CURRENT_STEP       = 'SET_CURRENT_STEP'
export const SET_AUTOCOMPLETE_LIST_BY_TYPE = 'SET_AUTOCOMPLETE_LIST_BY_TYPE'
export const SET_AUTOCOMPLETE_CONTACT_LIST_BY_TYPE = 'SET_AUTOCOMPLETE_CONTACT_LIST_BY_TYPE'
export const RESET_ADDRESS_BY_TYPE  = 'RESET_ADDRESS_BY_TYPE'
export const SET_LOCKED_FIELDS      = 'SET_LOCKED_FIELDS'
export const SET_CURRENT_TEMPLATE   =  'SET_CURRENT_TEMPLATE'
