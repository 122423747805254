<template>
  <label class="flex items-center cursor-pointer">
    <!-- toggle -->
    <div class="relative">
      <!-- input -->
      <input id="toogleA" v-model="checked" type="checkbox" class="hidden" @change.stop="$emit('change',checked)">
      <!-- line -->
      <div class="toggle__line w-10 h-5 bg-gray-300 rounded-full" />
      <!-- dot -->
      <div class="toggle__dot absolute w-4 h-4 bg-white rounded-full inset-y-0" />
    </div>
    <!-- label -->
    <div class="ml-3 text-gray-700 font-medium"><slot /></div>
  </label>
</template>

<script>
export default {
  name: 'TSwitch',
  props: ['value'],
  data: () => ({
    checked: false
  }),
  created () {
    this.checked = this.value
  }
}
</script>

<style lang="scss" scoped>
.toggle__dot {
  top: .12rem;
  left: .12rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__line {
  @apply bg-gray-800;
}

input:checked ~ .toggle__dot {
  transform: translateX(125%);
}
</style>
