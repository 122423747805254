import axios from 'axios';
import routes from '~/api/routes';

export const DEFAULT_MARKUP_GEOGRAPHY_SPECIFICS_VALUE = {
    DOM_DOM: null,
    DOM_REG: null,
    DOM_WW: null,
    REG_DOM: null,
    REG_REG: null,
    REG_WW: null,
    WW_DOM: null,
    WW_REG: null,
    WW_WW: null,
};

export const getDefaultMarkupGeographySpecificsValue = () => {
    return { ...DEFAULT_MARKUP_GEOGRAPHY_SPECIFICS_VALUE };
};

const state = {
    id: null,
    countryName: '',
    carrier: null,
    carrierOptions: [],
    carrierService: null,
    carrierServiceOptions: [],
    carrierServiceAll: [],
    rate: '',
    fixed: '',
    bookingEnabled: false,
    geographySpecificRates: getDefaultMarkupGeographySpecificsValue(),
};

const getters = {
    id: state => state.id,
    countryName: state => state.countryName,
    carrier: state => state.carrier,
    carrierOptions: state => state.carrierOptions,
    carrierService: state => state.carrierService,
    carrierServiceOptions: state => state.carrierServiceOptions,
    carrierServiceAll: state => state.carrierServiceAll,
    rate: state => state.rate,
    fixed: state => state.fixed,
    geographySpecificRates: state => state.geographySpecificRates,
    bookingEnabled: state => state.bookingEnabled,
};

const mutations = {

    SET_ID(state, value) {
        state.id = value;
    },
    SET_COUNTRY_NAME(state, value) {
        state.countryName = value;
    },
    SET_CARRIER(state, value) {
        state.carrier = value;
    },
    SET_CARRIER_OPTIONS(state, value) {
        state.carrierOptions = value;
    },
    SET_CARRIER_SERVICE(state, value) {
        state.carrierService = value;
    },
    SET_CARRIER_SERVICE_OPTIONS(state, value) {
        state.carrierServiceOptions = value;
    },
    SET_CARRIER_SERVICE_ALL(state, value) {
        state.carrierServiceAll = value;
    },
    SET_RATE(state, value) {
        state.rate = value;
    },
    SET_FIXED(state, value) {
        state.fixed = value;
    },
    RESET_GEOGRAPHY_RATES(state) {
        state.geographySpecificRates = getDefaultMarkupGeographySpecificsValue();
    },
    SET_GEOGRAPHY_RATES(state, value) {
        state.geographySpecificRates = value;
    },
    SET_GEOGRAPHY_RATES_FIELD(state, params) {
        state.geographySpecificRates[params.field] = params.value;
    },
    SET_BOOKING_ENABLED(state, value) {
        state.bookingEnabled = value;
    },
};

const actions = {
    updateId({commit}, value) {
        commit('SET_ID', value);
    },
    updateCountryName({commit}, value) {
        commit('SET_COUNTRY_NAME', value);
    },
    updateCarrier({state, commit}, value) {
        commit('SET_CARRIER', value);

        commit('SET_CARRIER_SERVICE_OPTIONS', state.carrierServiceAll.filter(service => service.carrier_id === value?.id));
    },
    updateCarrierOptions({commit}, value) {
        commit('SET_CARRIER_OPTIONS', value);
    },
    updateCarrierService({commit}, value) {
        commit('SET_CARRIER_SERVICE', value);
    },
    updateCarrierServiceOptions({commit}, value) {
        commit('SET_CARRIER_SERVICE_OPTIONS', value);
    },
    updateCarrierServiceAll({commit}, value) {
        commit('SET_CARRIER_SERVICE_ALL', value);
    },
    updateRate({state, commit}, value) {
        commit('SET_RATE', value);

        if (value && !state.geographySpecificRates.DOM_DOM) {
            commit('SET_GEOGRAPHY_RATES_FIELD', { field: 'DOM_DOM', value });
        }
    },
    updateFixed({commit}, value) {
        commit('SET_FIXED', value);
    },
    updateGeographyRates({commit}, value) {
        commit('SET_GEOGRAPHY_RATES', value);
    },
    updateGeographyRatesField({commit}, value) {
        commit('SET_GEOGRAPHY_RATES_FIELD', value);
    },
    updateBookingEnabled({commit}, value) {
        commit('SET_BOOKING_ENABLED', value);
    },
    async initState({commit, dispatch}) {
        dispatch('resetState');

        const promises = [
            axios.get(routes.carriers.base),
            axios.get(routes.carrierServices.base),
        ];

        const result = await Promise.all(promises);
        const carriers = result[0]?.data?.data?.filter(carrier => (
            carrier.name.toLowerCase().indexOf('vchain') === -1 && carrier.name.toLowerCase() !== 'egen transportör'
        ));

        commit('SET_CARRIER_OPTIONS', carriers || []);
        commit('SET_CARRIER_SERVICE_ALL', result[1]?.data?.data || []);
    },
    resetState({commit}) {
        commit('SET_ID', null);
        commit('SET_CARRIER', null);
        commit('SET_CARRIER_OPTIONS', []);
        commit('SET_CARRIER_SERVICE', null);
        commit('SET_RATE', '');
        commit('SET_FIXED', '');
        commit('RESET_GEOGRAPHY_RATES', );
        commit('SET_BOOKING_ENABLED', false);
        commit('SET_CARRIER_SERVICE_OPTIONS', []);
    },
    resetStateAll({commit, dispatch}) {
        dispatch('resetState');
        commit('SET_COUNTRY_NAME', '');
        commit('SET_CARRIER_SERVICE_ALL', []);
    },
};

export {
    state,
    getters,
    mutations,
    actions,
};