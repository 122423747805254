const splittedPageNameMapper = (chunk) => {
    if (matchesUuid(chunk)) {
        return 'uuid';
    }

    if (matchesNumber(chunk)) {
        return 'id';
    }

    return chunk;
};

export const parsePageName = (value) => {

    return value
        .split('/')
        .map(splittedPageNameMapper)
        .join('/');
}

const matchesNumber = (value) => {
    return /^\d+$/gi.test(value);
}

const matchesUuid = (value) => {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  
    return regexExp.test(value);
}
