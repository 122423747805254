<template>
    <transition name="fade">
        <div v-if="show" class="overlay" @click.self="hide()">
            <transition name="fade">
                <div class="overflow-visible card modal" :class="{'modal-lg': size === 'lg'}">
                    <div class="flex-grow-0 bg-white border-0 card-header">
                        <h2 class="w-full px-4 py-2 -mx-4 font-normal">Edit report</h2>

                        <button class="ml-auto text-gray-500 close-modal-btn" @click="hide">
                            <svg-importer icon-name="icons/close" />
                        </button>
                    </div>

                    <div slot="body" class="flex flex-wrap p-6 border-t border-gray-500">
                        <div v-for="(input, index) in inputs" :key="'input' + index" :class="input.width">
                            <modal-field
                                v-if="input.type == 'text'"
                                :label="input.title" class="flex-wrap w-full px-4 mt-4"
                                :required="input.required"
                            >
                                <input
                                    v-model="input.value"
                                    type="text"
                                    class="w-full"
                                    :class="{'border-red-500' : validationErrors[input.key]}"
                                    :placeholder="input.key === 'front_options' ? frontOptionsPlaceholder : ''"
                                    :disabled="input.key === 'front_options' && (isDefaultReport || isAnalyticsReport)"
                                    @blur="input.key === 'front_options' ? validateFrontOptions(input.value) : ''"
                                />

                                <transition name="fade">
                                    <span v-if="validationErrors[input.key]" class="w-full text-red-500 help-block">
                                        <span>{{ validationErrors[input.key][0] }}</span>
                                    </span>
                                </transition>
                            </modal-field>

                            <modal-field v-if="input.type === 'checkbox'" class="relative w-full px-4 mt-10">
                                <checkbox :name="input.key" v-model="input.value">{{ input.title }}</checkbox>
                            </modal-field>
                        </div>
                    </div>

                    <div class="flex p-4 mt-auto">
                        <div slot="footer" class="w-full py-4 pr-6 text-right">
                            <button @click="saveReport" :class="[routeIncludesAdmin ? 'btn-primary' : 'btn']">
                                Save report
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>


<script>
import axios from 'axios';
import Checkbox from "~/components/Checkbox";

import { DEFAULT_DASHBOARD_REPORT_SETTINGS, DEFAULT_ANALYTICS_REPORT_SETTINGS } from '@/data/power-bi.js';

export default {
    name: 'EditReportModal',
    components: {
        Checkbox,
    },
    props: {
        show: Boolean,
        size: String,
        organization: Object,
        report: Object,
    },

    data: () => ({
        visible: false,
        loading: false,
        validationErrors: {},
        inputs: [
            {
                title: 'Name',
                key: 'name',
                value: null,
                type: 'text',
                width: 'w-1/3',
                required: true,
            },
            {
                title: 'Workspace id',
                key: 'workspace_id',
                value: null,
                type: 'text',
                width: 'w-1/3',
                required: true,
            },
            {
                title: 'Report id',
                key: 'report_id',
                value: null,
                type: 'text',
                width: 'w-1/3',
                required: true,
            },
            {
                title: 'Options (JSON)',
                key: 'front_options',
                value: null,
                type: 'text',
                width: 'w-2/3',
                required: false,
            },
            {
                title: 'Is default report',
                key: 'is_default',
                value: null,
                type: 'checkbox',
                width: 'w-1/3',
                required: true,
            },
        ],
    }),

    computed: {
        routeIncludesAdmin() {
            return this.$route.path.includes('admin');
        },
        formData() {
            return this.report;
        },
        isDefaultReport() {
            return Boolean(this.inputs.find(input => input.key === 'is_default' && input.value == true));
        },
        isAnalyticsReport() {
            return Boolean(this.inputs.find(input => input.key === 'name' && input.value === 'analytics'));
        },
        frontOptionsPlaceholder() {
            return JSON.stringify(
                this.isDefaultReport ? DEFAULT_DASHBOARD_REPORT_SETTINGS : DEFAULT_ANALYTICS_REPORT_SETTINGS
            );
        },
    },

    created() {
        this.toggleBodyClass('remove', 'modal-open');
        this.visible = false;
    },

    watch: {
        loading(newVal) {
            this.$emit('loading', newVal);
        },
        report(value) {
            for (const key in value) {
                const match = this.inputs.find(input => input.key === key);
                if (match) {
                    match.value = value[key];
                }
            }
        },
    },

    methods: {
        validateFrontOptions(value) {
            try {
                JSON.parse(value);
                delete this.validationErrors.front_options;
            }  catch(error) {
                this.validationErrors.front_options = 'Invalid JSON value';
            }
        },
        async saveReport() {
            this.loading = true;
            let report_data = {};

            this.inputs.forEach(input => {
                report_data[input.key] = input.key === 'is_default' ? Number(input.value) : input.value;
            });

            report_data['organization_id'] = this.organization.id;

            if (this.isAnalyticsReport) {
                report_data.front_options = JSON.stringify(DEFAULT_ANALYTICS_REPORT_SETTINGS);
            }

            if (this.isDefaultReport) {
                report_data.front_options = JSON.stringify(DEFAULT_DASHBOARD_REPORT_SETTINGS);
            }

            try {
                await axios.post(`${this.$apiUrl.powerBi.base}/${this.report.id}`, { ...report_data, is_default: Number(!!report_data.is_default) });
                this.$snotify.success(`Report created`);
                this.validationErrors = [];
                this.loading = false;
                this.hide();
                this.clearReportForm();
                this.$emit('success', true);
            } catch (error) {
                if (error.response.status === 422) {
                    this.validationErrors = error.response.data.errors;
                }
            }
        },
        clearReportForm() {
            this.inputs.forEach(element => {
                element.value = null;
            });
        },
        hide() {
            this.validationErrors = {};
            this.clearReportForm();
            this.$emit('hide');
        },
    },
};
</script>
