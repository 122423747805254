<template>
    <div class="table-tools search">
        <div class="table-search border-radius" :class="{ 'w-full': !toolsRight }">
            <label for="table-search-input" class="cursor-pointer">
                <button class="bg-transparent border-none ml-2 mt-1" :class="table.filter ? 'text-orange-500' : 'text-gray-900'" @click="clearSearch">
                    <svg-importer v-if="table.filter" icon-name="icons/close" key="v-tools-close" />
                    <svg-importer v-else icon-name="icons/search" key="v-tools-search" />
                </button>
            </label>
            <input
                id="table-search-input"
                v-model="table.filter"
                type="text"
                placeholder="Search (press enter to search)"
                @keyup.enter="pushSearch"
            />
            <!--<input type="text" v-model="store.filter" placeholder="Search (press enter to search)"> -->
        </div>
        <div class="relative flex ml-auto align-right tools items-center" v-if="toolsRight">
            <slot name="tools" />

            <div v-if="$slots.tools" class="border h-6 w-px mx-4"></div>

            <select class="w-48 text-body form-select outline-default bg-transparent border-radius--input" v-model="table.pageSize" @change="setPageSize()" :disabled="toggleTableTools">
                <option
                    v-for="item in list"
                    :key="item"
                    :value="item"
                >
                    {{ item }} rows
                </option>
            </select>

            <button v-if="table.exportable" class="ml-4 text-xl" @click="doExport" :disabled="toggleTableTools || table.rows.length === 0">
                <icon name="icons/download" />
            </button>

            <icon name="icons/gear" ref="toggleTableToolsBtn" class="ml-2" @click="toggleTableTools = !toggleTableTools" />

            <transition name="slide">
                <div
                    v-if="toggleTableTools"
                    class="absolute right-3 p-2 -mr-3 border rounded arrow-box dropdown top-10 z-20"
                    v-closable="{
                        exclude: ['toggleTableToolsBtn'],
                        handler: 'onClose'
                    }"
                >
                    <h5 class="pb-2 pl-2 text-subheader border-b">Table settings</h5>
                    <ul class="overflow-y-auto" style="max-height: 300px">
                        <li v-for="(col, cIndex) in table.toggleableColumns" :key="cIndex" v-if="col.key != 'actions'">
                            <label class="flex items-center cursor-pointer">
                                <input
                                    v-model="col.toggled"
                                    type="checkbox"
                                    :disabled="disabledToggled && col.toggled"
                                    class="form-checkbox"
                                />
                                <span class="text-body ml-2">{{ col.title }}</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </transition>

            <div v-if="$slots['after-tools']" class="border h-6 w-px mx-4"></div>

            <slot name="after-tools" />
        </div>
    </div>
</template>

<script>
    export default {
        inject: ["table"],
        props: {
            customExport: {
                type: Function,
                default: null
            },
            exportable: {
                type: Boolean,
                default: true,
            },
            toolsRight: {
                type: Boolean,
                default: true
            },
            branding: {
                required: false,
                default: true,
                type: Boolean
            },
            list: {
                type: Array,
                default: () => { return [10, 50, 100, 200, 500] },
            },
        },
        data: () => ({
            filterTerm: null,
            toggleTableTools: false,
            pageSize: 50
        }),
        computed: {
            disabledToggled() {
                const result =  this.table.toggleableColumns.filter(item => {
                    return item.toggled;
                });

                return result.length <= 2;
            }
        },
        methods:{
            doExport(){
                if(this.table.customExport){
                    this.table.customExport(this.table);
                } else {
                    this.table.exportData();
                }
            },
            setPageSize(){
                this.table.page = 1;
                this.table.commitState()
            },
            clearSearch() {
                this.table.filter = ''
                this.table.commitState()
            },
            pushSearch() {
                //this.table.setFilter(this.filterTerm)
                this.table.page = 1
                this.table.commitState()
            },
            onClose() {
                this.toggleTableTools = false;
            },
        },
    };
</script>
