import Vue from 'vue'
import moment from 'moment'

Vue.mixin({
    data() {
        return {
            addressModalKeyNameDictionary: {
                'Sender': 'consignor',
                'Delivery': 'delivery',
                'Receiver': 'consignee',
                'Pickup': 'pickup',
            },
            companyTypeDictionary: [
                { name: 'Sender', value: 'SENDER' }, 
                { name: 'Pickup', value: 'PICK_UP' },
                { name: 'Receiver', value: 'RECEIVER' }, 
                { name: 'Delivery', value: 'DELIVERY' }
            ],
        };
    },
    methods: {
        getCarrierLogo(carrier) {
            let path = '/svg/carriers/';

            switch (carrier.toUpperCase()) {
                case 'BRING':
                case 'BRING PARCELS':
                    return path + 'bring.svg'
                case 'DACHSER':
                    return path + 'dachser.svg'
                case 'DHL':
                case 'DHL EXPRESS':
                case 'DHL SVERIGE':
                    return path + 'dhl.svg'
                case 'DSV':
                case 'DSV SVERIGE':
                    return path + 'dsv.svg'
                case 'FEDEX':
                    return path + 'fedex.svg'
                case 'POSTEN SVERIGE':
                case 'POSTNORD LOGISTICS':
                case 'POSTNORD LOGISTICS SVERIGE':
                    return path + 'postnord.svg'
                case 'SCHENKER':
                case 'SCHENKER SVERIGE':
                    return path + 'db_schenker.svg'
                case 'TNT':
                    return path + 'tnt.svg'
                case 'UPS':
                    return path + 'ups.svg'
                default:
                    return false
            }
        },
        getTransportModeIcon(mode) {
            let m = mode ? mode : '';

            switch (m.toUpperCase()) {
                case 'RAIL':
                    return 'rail'
                case 'COURIER':
                    return 'courier'
                case 'AIR':
                    return 'plane'
                case 'ROAD':
                    return 'truck'
                case 'EXPRESS':
                    return 'express'
                case 'SEA':
                case 'OCEAN':
                    return 'ocean'
                case 'MULTIMODAL':
                    return 'multimodal'
                default:
                    return 'question'
            }
        },
        getDirectionIcon(mode) {
            let m = mode ? mode : '';
            switch (m.toUpperCase()) {
                case 'UP':
                    return 'arrow-up'
                case 'DOWN':
                    return 'arrow-down'
                default:
                    return 'question'
            }
        },
        getInvoiceSurchargeTypes() {
            return ['Freight', 'Fuel surcharge', 'Road tax', 'Outer area', 'Congestion charge', 'Local Charges Destination', 'Local Charges Origin', 'Other'];
        },
        getStatusIcon(status) {
            if (status === null) {
                return ['fas', 'circle']
            }

            status = status ? status.toUpperCase() : '';

            if (status === 'CONFIRMED' || status === 'NOT_CONFIRMED') {
                return ['far', 'circle']
            }

            return ['fas', 'circle']
        },
        getStatusColor(status) {
            if (!status) {
                return 'text-gray-700'
            }

            switch (status.toUpperCase()) {
                //case 'IN_TRANSIT': return 'text-yellow-500';
                case 'EXCEPTION':
                    return 'text-red-500';
                default:
                    return 'text-green-500';
            }
        },
        getMediaPath(path) {
            //return 'https://stringfurniture.com/media/img/' + path
            return window.config.cdnUrl + path
        },
        getProp(object, keyPath) {
            keyPath = keyPath.replace(/\[(\w+)\]/g, '.$1')
            keyPath = keyPath.replace(/^\./, '')
            const a = keyPath.split('.')
            for (let i = 0, n = a.length; i < n; ++i) {
                let k = a[i]
                if (object && k in object) {
                    object = object[k]
                } else {
                    return
                }
            }
            return object
        },
        isStringExistsInArray(arr, value) {
            if (!Array.isArray(arr)) {
                return false;
            }
            return arr.indexOf(value) !== -1;
        },
        isNumeric(toCheck) {
            return !Array.isArray(toCheck) && !isNaN(parseFloat(toCheck)) && isFinite(toCheck)
        },
        groupBy(xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x)
                return rv
            }, {})
        },
        formatDate(date) {
            if (!date) {
                return '-';
            }
            
            return moment(date).format('YYYY-MM-DD')
        },
        formatThousands(number, precision = 0) {
            if (parseFloat(number) === undefined) {
                return number
            }

            let num = parseFloat(number);

            return (+(Math.ceil(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision)
        },
        cloneObject(obj) {
            return JSON.parse(JSON.stringify(obj));
        },
        isEmptyObject(obj) {
            return Object.keys(obj).length === 0;
        },
        kFormatter(num) {
            return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
        },
        priceWithCurrency(price, currency) {
            price = this.formatThousands(price)

            if (!currency || currency.symbol === undefined) {
                return price
            }

            if (currency.alignment === 2) {
                return currency.symbol + price
            }

            return price + ' ' + currency.symbol
        },
        formatNumber(number, currency = 'USD') {
            return new Intl.NumberFormat('sv-SE', {
                currency: currency,
            }).format(number);
        },
        rfid() {
            return '_' + Math.random().toString(36).substr(2, 9)
        },
        toggleBodyClass(addRemoveClass, className) {
            const el = document.body

            if (addRemoveClass === 'add') {
                el.classList.add(className)
            } else {
                el.classList.remove(className)
            }
        },
        subIsActive(input) {
            const paths = Array.isArray(input) ? input : [input]

            return paths.some(path => {
                return this.$route.path.indexOf(path) === 0 // current path starts with this path string
            })
        },
        capitalize(string) {
            string = string.toLowerCase()
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        objectToFormData(obj, form, namespace) {

            let fd = form || new FormData();
            let formKey;

            for (let property in obj) {
                if (property in obj) {

                    if (namespace) {
                        formKey = namespace + '[' + property + ']';
                    } else {
                        formKey = property;
                    }

                    // if the property is an object, but not a File,
                    // use recursivity.
                    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {

                        this.objectToFormData(obj[property], fd, formKey);

                    } else if (obj[property] === undefined) {
                        continue;
                    } else if (typeof obj[property] === 'boolean') {
                        fd.append(formKey, String(Number(obj[property])));
                    } else {

                        // if it's a string or a File object
                        fd.append(formKey, obj[property]);
                    }

                }
            }

            return fd;

        },

        findRefByName(refName) {
            let obj = this
            while (obj) {
                if (obj.$refs[refName]) {
                    return obj.$refs[refName]
                }

                obj = obj.$parent
            }

            return undefined
        },

        arrayHasValidationError(errors, key, index, valueKey, label = null, additionalReplace = null) {

            const validationKey = `${key}.${index}.${valueKey}`;

            label = label ? label : valueKey;

            if (errors[validationKey]) {
                errors[validationKey][0] = errors[validationKey][0].replace(validationKey, label);

                if (additionalReplace) {
                    const additionalReplaceKey = `${key}.${index}.${additionalReplace}`;
                    errors[validationKey][0] = errors[validationKey][0].replace(additionalReplaceKey, additionalReplace);
                }

                return errors[validationKey];
            }

            return false;
        },

        getDefaultAvatar() {
            let url = window.location.origin;
            const image = '/images/avatar-dark.png';

            if (window.location.origin.includes('.test')) {
                url = 'https://globl.se'
            }

            url = url + image;
            return url;
        },

        fileIconNameByExtension(extension) {
            switch(extension) {
                case "pdf":
                    return 'file-pdf'
                case "docx":
                    return 'file-word'
                case "png":
                    return 'image'
                case "jpg":
                    return 'image'
                case "jpeg":
                    return 'image'
                default:
                    return 'file'
            }

        },

        extractValueFromCollection(fieldName, collection) {
            return collection.map(object => object[fieldName])
        },

        getPickupTime(date) {
            if (!date) {
                return null;
            }

            const momentDate = moment(date)

            return momentDate.isValid() ? momentDate.format('HH:mm') : null;
        },

        getDisabledTime(key) {
            return (date) => {
                const isEarliest = key === 'pickupTimeEarliest';

                if (!isEarliest && !this.addressData.pickup.pickupTimeEarliest.value) {
                    false;
                }
                
                const diff = isEarliest
                    ? moment(this.addressData.pickup.pickupTimeLatest.value).diff(date, 'minutes')
                    : moment(date).diff(this.addressData.pickup.pickupTimeEarliest.value, 'minutes');

                // date-time-picker minute-step - 1
                return diff <= 14;
            }
        },
        getIsEmptyField(value) {
            return !value;
        },
    }
})
