const OPEN_TABS = 'open-tabs';
const SESSION_ID = 'session-id';

export const setOnBeforeUnload = function () {
    const openTabsCounter = +localStorage.getItem(OPEN_TABS);

    localStorage.setItem(OPEN_TABS, openTabsCounter + 1);
    
    window.onbeforeunload = function() {
      const newOpenTabsCounter = +localStorage.getItem(OPEN_TABS) - 1;
      if (newOpenTabsCounter > 0) {
        localStorage.setItem(OPEN_TABS, newOpenTabsCounter);

        return;
      }
      if (newOpenTabsCounter <= 0) {
        localStorage.removeItem(OPEN_TABS);
        localStorage.removeItem(SESSION_ID);
      }
    };
};