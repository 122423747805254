<template>
  <tr>
    <td>
      <img :src="getArticleImage(item.No)" class="bg-gray-200 h-16 w-16">
    </td>
    <td>
      <modal-field label="article">
        {{ item.Quantity + ' x ' + item.No }}
        <br>
        {{ getArticleName(item.No) }}
      </modal-field>
    </td>
    <td>
      <modal-field v-if="item.Commission" label="commission">
        {{ item.Commission }}
      </modal-field>
    </td>
    <td>
      <modal-field v-if="!hideShipping" label="shipping date">
        {{ item.Shipment_Date }}
      </modal-field>
    </td>
    <td>
      <modal-field
        label="unit price"
        class="text-right pr-4"
      >
        {{ priceWithCurrency(parseFloat(item.Unit_Price), currency) }}
      </modal-field>
    </td>
    <td>
      <modal-field class="text-right pr-4" label="discount">
        {{ item.Line_Discount_Percent }}%
      </modal-field>
    </td>
    <td>
      <modal-field
        label="line amount"
        class="text-right pr-4"
      >
        {{ priceWithCurrency(parseFloat(item.Line_Amount), currency) }}
      </modal-field>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'OrderRow',
  props: {
    item: Object,
    hideShipping: Boolean
  },

  computed: {
    currency () {
      let currency = this.pricelist.currency
      return currency
    },
    pricelist () {
      return this.$store.getters['reseller/pricelist']
    },
    articles () {
      return this.$store.getters['misc/articles']
    }
  },

  methods: {
    getArticle (articleNumber) {
      if (this.articles) {
        return this.articles.find(
          article => article.article_number === articleNumber
        )
      }

      return []
    },
    getArticleImage(articleNumber) {
      let article = this.getArticle(articleNumber)

      if(article){
        return this.getMediaPath('media/img/' + article.product_photo.files.thumb)
      }
    },
    getArticleName (articleNumber) {
      let article = this.getArticle(articleNumber)

      if (article) {
        return (
          article.article_name +
          ' ' +
          article.primary_color +
          (article.secondary_color ? '/' + article.secondary_color : '')
        )
      }

      return articleNumber
    }
  }
}
</script>
