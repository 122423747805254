import * as types from '../mutation-types'
import axios from 'axios'

import API_URL from '~/api/routes'

// state
export const state = {
    options: {
        carriers: [],
        transportModes: [],
        fromCountries: [],
        fromCities: [],
        toCountries: [],
        toCities: [],
        sites: [],
    },

    fetched: false,
    carriers: [
        'Agility',
        'Schenker',
        'DHL Freight',
        'DHL Express',
        'GEODIS',
        'Dachser',
        'TNT',
        'DSV Road',
        'PostNord',
        'DSV Road',
        'DANX',
        'Bring Parcels',
        'Gnosjö Trafik',
        'NTG East',
        'DHL Global Forwarding',
        'JumboTransport',
        'Greencarrier',
        'MTS Malmö Transport & Spedition',
        'UPS',
        'Alwex Transport',
        'Norman Global Logistics',
        'LIN Logistik',
        'Vortex Worldwide Logistics',
        'ScanAm Global Logistics',
        'NTG Air & Ocean',
        'Finnvedens Expresstransport',
        'Yusen Logistics',
        'Jetpak',
        'NTG Continent',
        'Björkmans Transport',
        'NTG Domestics',
        'Flexilast',
        'ÅK-Budet i Markaryd',
        'NTEX',
        'MX Transport',
    ],
    savedStates: {}
}

// getters
export const getters = {
    carriers:       state => state.options.carriers,
    transportModes: state => state.options.transportModes,
    fromCountries:  state => state.options.fromCountries,
    fromCities:     state => state.options.fromCities,
    toCountries:    state => state.options.toCountries,
    toCities:       state => state.options.toCities,
    sites:          state => state.options.sites,

    options:        state => state.options,
    fetched:        state => state.fetched,
    allCarriers:    state => state.carriers,
    savedStates:    state => state.savedStates,
}

// mutations

export const mutations = {
    [types.SET_FILTER_CARRIERS] (state, data) {
        state.options.carriers = data
    },

    [types.SET_FILTER_TRANSPORT_MODES] (state, data) {
        state.options.transportModes = data
    },

    [types.SET_FILTER_FROM_COUNTRIES] (state, data) {
        state.options.fromCountries = data
    },

    [types.SET_FILTER_FROM_CITIES] (state, data) {
        state.options.fromCities = data
    },

    [types.SET_FILTER_TO_COUNTRIES] (state, data) {
        state.options.toCountries = data
    },

    [types.SET_FILTER_TO_CITIES] (state, data) {
        state.options.toCities = data
    },

    [types.SET_FILTER_SITES] (state, data) {
        state.options.sites = data
    },

    [types.SET_FILTER_OPTIONS_FETCHED] (state) {
        state.fetched = true;
    },

    [types.SET_FILTER_STATE] (state, data) {
        state.savedStates[data.key] = data.data
    },
}

// actions
export const actions = {

    async setFilterState ({ commit }, payload) {
        commit(types.SET_FILTER_STATE, payload)
    },

    async fetchCarriers ({ commit }) {
        const { data } = await axios.get(API_URL.filters + '?options[]=carrier_from_source');
        commit(types.SET_FILTER_CARRIERS, data.data.carrier_from_source)
    },

    async fetchTransportModes ({ commit }) {
        const { data } = await axios.get(API_URL.filters + '?options[]=transport_mode_from_source');
        commit(types.SET_FILTER_TRANSPORT_MODES, data.data.transport_mode_from_source)
    },

    async fetchFromCountries ({ commit }) {
        const { data } = await axios.get(API_URL.filters + '?options[]=consignor_countrycode');
        commit(types.SET_FILTER_FROM_COUNTRIES, data.data.consignor_countrycode)
    },

    async fetchFromCities ({ commit }) {
        const { data } = await axios.get(API_URL.filters + '?options[]=consignor_city');
        commit(types.SET_FILTER_FROM_CITIES, data.data.consignor_city)
    },

    async fetchToCountries ({ commit }) {
        const { data } = await axios.get(API_URL.filters + '?options[]=consignee_countrycode');
        commit(types.SET_FILTER_TO_COUNTRIES, data.data.consignee_countrycode)
    },

    async fetchToCities ({ commit }) {
        const { data } = await axios.get(API_URL.filters + '?options[]=consignee_city');
        commit(types.SET_FILTER_TO_CITIES, data.data.consignee_city)
    },

    async fetchSites ({ commit }) {
        const { data } = await axios.get(API_URL.filters + '/organization/sites');
        commit(types.SET_FILTER_SITES, data.data)
    },

    async fetchAllOptions ({ commit, dispatch }) {
        dispatch('fetchCarriers')
        dispatch('fetchTransportModes')
        dispatch('fetchFromCountries')
        dispatch('fetchFromCities')
        dispatch('fetchToCountries')
        dispatch('fetchToCities')
        dispatch('fetchSites')

        commit(types.SET_FILTER_OPTIONS_FETCHED)
    },
}
