import Vue from 'vue'
import VTable from './VTable'
import VTh from './VTh'
import VTr from './VTr'
import VPagination from './VPagination'
import VLoading from './VLoading'
import VTools from './VTools'
import VDebug from './VDebug'
import VPanel from  './VPanel';
import VPaginate from './VPaginate';

export {
  VTable,
  VTh,
  VTr,
  VLoading,
  VDebug,
  VPagination,
  VTools,
  VPanel,
  VPaginate,
}

Vue.component('v-table', VTable)
Vue.component('v-loading', VLoading)
Vue.component('v-debug', VDebug)
Vue.component('v-th', VTh)
Vue.component('v-tr', VTr)
Vue.component('v-pagination', VPagination)
Vue.component('v-tools', VTools)
Vue.component('v-panel', VPanel)
Vue.component('v-paginate', VPaginate)
