// state
export const state = {
    filters: null,
    oFilters: null,
    columns: null,
    options: null,
    statistics: null
}

// mutations
export const mutations = {
  SET_FILTERS(state, { filters }) {
    state.filters = filters;
  },
  SET_COLUMNS(state, { columns }) {
    state.columns = columns;
  },
  SET_OPTIONS(state, { options }) {
    state.options = options;
  },
  SET_OFILTERS(state, { oFilters }) {
    state.oFilters = oFilters;
  },
  SET_STATISTICS(state, { statistics }) {
    state.statistics = statistics;
  }
}

// actions
export const actions = {
  setTable({ commit }, value) {
    commit('SET_FILTERS', { filters: value.filters });
    commit('SET_COLUMNS', { columns: value.columns });
    commit('SET_OPTIONS', { options: value.options });
    commit('SET_OFILTERS', { oFilters: value.oFilters });
    commit('SET_STATISTICS', { statistics: value.statistics });
  }
}
