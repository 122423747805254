const persistInterval = 24 * 60 * 60 * 1000 // 1 day
const triggers = [
  'shop/ADD_TO_CART',
  'shop/REMOVE_FROM_CART',
  'shop/ADD_COMMISSION',
  'shop/UPDATE_COMMISSION',
  'shop/REMOVE_CURRENT_COMMISSION',
  'shop/UPDATE_CART_PRICELIST',
  'shop/EMPTY_CART'
]

const plugin = store => {
  store.subscribe((mutation, state) => {
    // Save to local storage all the changes made in the cart state

    if (triggers.includes(mutation.type)) {
      let record = {
        data: state.shop.cart,
        ts: new Date().getTime() + persistInterval
      }
      try {
        localStorage.setItem('string_cart', btoa(JSON.stringify(record)))
      } catch (e) {
        console.error(e);
      }
    }
  })
}

export default plugin
