import axios from 'axios'
import Cookies from 'js-cookie'
import ls from 'local-storage'
import { Base64 } from 'js-base64'
import * as types from '../mutation-types'
import Vue from 'vue';

import API_URL from '~/api/routes'

// state
export const state = {
  user: null,
  token: Cookies.get('token'),
  sites: ls.get('sites'),
  userTree: [],
  tokenExpired: false,
  siteName: '',
  avatar: 0,
  clientLogo: 0,
  roles: [],
  sitesLoading: false,
  sitesTrigger: 0,
  hasAnalyticsReport: null,
}

// getters
export const getters = {
  user: state => state.user,
  token: state => state.token,
  check: state => state.user !== null,
  tokenExpired: state => state.tokenExpired,
  ssoUnifaunLink: state => state.user.ssoUnifaunLink,
  isAdmin: state => {
    if (!state.roles?.length) {
      return false;
    }

    return Boolean(state.roles.find(role => ['GOD USERS', 'Organization Admin'].includes(role.name)));
  },
  sites: state => {
    if (state.sites && typeof state.sites !== 'object') {
      try {
        return JSON.parse(Base64.decode(state.sites))
      } catch(_) {
        return [];
      }
    }

    return state.sites
  },
  sitesLoading: state => state.sitesLoading,
  userTree: state => state.userTree,
  actingAsAgent: state => state.company && state.company.is_agent,
  countryCode: state => state?.user?.organization?.country_code || 'unknown',
  roles: state => state?.roles,
  isGodUser: state => Boolean(state?.roles?.find(role => role.name === 'GOD USERS')),
  isOrgAdmin: state => Boolean(state?.roles?.find(role => role.name === 'Organization Admin')),
  sitesTrigger: state => state.sitesTrigger,
  hasAnalyticsReport: state => state.hasAnalyticsReport,
}

// mutations
export const mutations = {
  [types.SET_TOKEN_EXPIRED] (state, isExpired) {
    state.tokenExpired = isExpired
  },
  [types.SAVE_TOKEN] (state, { token, remember }) {
    state.token = token
    Cookies.set('token', token, { expires: remember ? 365 : null })
  },

  async [types.FETCH_USER_SUCCESS] (state, { user }) {
    state.user = user
    let isUseSitesFromUser = false;

    const stateSites = typeof state.sites !== 'object' ? JSON.parse(Base64.decode(state.sites)) : state.sites;
    
    if (stateSites === null) {
      isUseSitesFromUser = true;
    } else if (!stateSites && user.sitesFilter) {
      isUseSitesFromUser = true;
    } else if (stateSites.length !== user.sitesFilter) {
      isUseSitesFromUser = true;
    } else if (!isUseSitesFromUser) {
      return;
    }

    state.sites = Array.isArray(user.sitesFilter) ? user.sitesFilter : [];
    ls.set('sites', Base64.encode(JSON.stringify(user.sitesFilter)))

    state.roles = user.roles;
  },

  [types.FETCH_USER_FAILURE] (state) {
    state.token = null
    Cookies.remove('token')
  },

  [types.LOGOUT] (state) {
    state.user = null;
    state.token = null;
    state.sites = null;
    state.siteName = '';

    ls.set('sites', null);

    Cookies.remove('token')
    Cookies.remove('shipments-init-values')
  },

  [types.UPDATE_USER] (state, { user }) {
    state.user = user
  },

  [types.CHANGE_SITE] (state, sites) {
    state.sites = sites;

    if (sites != null) {
      ls.set('sites', Base64.encode(JSON.stringify(sites)))
    }
  },

  [types.CHANGE_SITE_STOP] (state, sites) {
    state.sites = sites

    if (sites != null) {
      ls.set('site', Base64.encode(JSON.stringify(sites)))
    }
  },

  [types.FETCH_USER_TREE] (state, { tree }) {
    state.userTree = tree
  },

  [types.CHANGE_AVATAR] (state) {
    state.avatar++;
  },

  [types.CHANGE_CLIENT_LOGO] (state) {
    state.clientLogo++;
  },

  [types.SET_SITES_LOADING] (state, loading) {
    state.sitesLoading = loading;
  },

  [types.SET_SITES_TRIGGER](state) {
    state.sitesTrigger++;
  },
  SET_HAS_ANALYTICS_REPORT(state, value) {
    state.hasAnalyticsReport = value;
  },
}

// actions
export const actions = {

  setTokenExpired ({ commit }, isExpired) {
    commit(types.SET_TOKEN_EXPIRED, isExpired)
  },

  saveToken ({ commit }, payload) {
    commit(types.SAVE_TOKEN, payload)
  },

  async changeSite ({ commit }, value) {
    Vue.$cookies.set('sites', value.map(item => item?.key).join(','));

    commit(types.CHANGE_SITE, value);
  },

  async changeSiteStop ({ commit }, value) {
    Vue.$cookies.set('sites', value.map(item => item?.key).join(','));

    commit(types.CHANGE_SITE_STOP, value)
  },

  async fetchUser ({ commit }) {
    try {
      const { data } = await axios.get(API_URL.whoami)

      await commit(types.FETCH_USER_SUCCESS, { user: data.data });
    } catch (e) {
      commit(types.FETCH_USER_FAILURE)
    }
  },

  updateUser ({ commit }, payload) {
    commit(types.UPDATE_USER, payload)
  },

  async logout ({ commit }) {
    try {
      await axios.post(API_URL.logout)
    } catch (e) {
      commit(types.LOGOUT);
    }

    commit(types.LOGOUT);
  },

  async fetchOauthUrl (ctx, { provider }) {
    const { data } = await axios.post(API_URL.oauth + `/${provider}`)

    return data.url
  }
}
