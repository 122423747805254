<template>
    <div class="relative">
        <dropdown dropdownClass="mr-2 p-2">
            <button slot="trigger">
                <slot />
            </button>
             <modal-field :label="title">
                <div class="flex w-full mt-2">
                    <button class="btn btn-primary btn-sm mr-4" :class="{'no-branding': ! branding}" @click="emitConfirm">
                        {{ confirmText }}
                    </button>
                    <button class="mr-2 text-xs" @click="toggleConfirm">Cancel</button>
                </div>
            </modal-field>
        </dropdown>
    </div>
</template>

<script>
export default {
    name: 'Confirm',
    props: {
        buttonClass: String,
        branding: {
            required: false,
            default: true,
            type: Boolean
        },
        confirmText: {
            type: String,
            default: () => 'Confirm'
        },
        title: {
            type: String,
            default: () => 'Are you sure?'
        }
    },
    data: () => ({
        shouldConfirm: false
    }),
    methods: {
        toggleConfirm(){
            this.shouldConfirm = !this.shouldConfirm;
        },
        emitConfirm(){
            this.$emit('confirmed')
            this.shouldConfirm = false;
        },
        onCloseConfirm(){
            this.shouldConfirm = false;
        }
    }
}
</script>