<template>
    <modal-field :label="label" :required="required">
        <input
            v-if="type !== 'textarea'"
            v-model="internalValue"
            ref="tinput"
            type="text"
            :min="min"
            :step="step"
            :placeholder="placeholder"
            :autofocus="autofocus"
            :readonly="readOnly ? 'readonly' : false"
            :disabled="disabled"
            :class="{'is-invalid' : error}"
            @blur="blur()"
            @keyup="emitKeyUp()"
        />

        <textarea
            :type="type"
            v-if="type === 'textarea'"
            :value="value"
            :rows="rows"
            :placeholder="placeholder"
            @blur="blur()"
            :readonly="readOnly ? 'readonly' : false"
            @input="updateValue($event.target.value)"
            :disabled="disabled"
            :class="{'is-invalid' : error}">
        </textarea>

        <transition name="fade">
            <span v-if="error" class="w-full text-red-500 help-block">
                <span>{{ error[0] }}</span>
            </span>
        </transition>
    </modal-field>
</template>

<script>
    const sanitizeAndParseNumeric = (inputString) => {
        // firefox fix
        let sanitizedString = inputString.replace(/[^0-9.,]/g, '');
        
        if (sanitizedString.indexOf(',') !== -1) {
            sanitizedString = sanitizedString.replace(/,/g, '.');
        }

        if (sanitizedString.indexOf('.') !== -1) {
            let [intPart, floatPart] = sanitizedString.split('.');

            if (floatPart.length > 2) {
                floatPart = floatPart.substring(0, 2);
            }

            sanitizedString = `${intPart}.${floatPart}`;
        }

        return sanitizedString;
    };

    export default {

        name: 'TInput',

        props: {
            value: {},
            error: {},
            label: {},
            placeholder: {},
            autofocus: {
                required: false,
                default: false,
                type: Boolean,
            },
            min: {
                required: false,
                type: Number,
            },
            type: {
                required: false,
                default: 'text',
                type: String,
            },
            step: {
                required: false,
                default: false,
                type: [String, Boolean],
            },
            readOnly: {
                required: false,
                default: false,
                type: Boolean,
            },
            rows: {
                required: false,
                default: 4,
                type: Number
            },
            required: {
                required: false,
                default: false,
                type: Boolean
            },
            disabled: {
                type: [String, Boolean],
                default: false,
            }
        },

        model: {
            prop: 'value',
            event: 'input',
        },

        computed: {
            internalValue: {
                set(value) {
                    if (this.type === 'float') {
                        const sanitized = sanitizeAndParseNumeric(value);
                        
                        this.$refs.tinput.value = sanitized;
                        this.$emit('input', sanitized);

                        return;
                    }

                    if (this.type === 'number') {
                        const integer = !isNaN(parseInt(value)) ? parseInt(value) : '';

                        this.$refs.tinput.value = integer;
                        this.$emit('input', integer);

                        return;
                    }

                    this.$emit('input', value);
                },
                get() {
                    return this.value;
                },
            }
        },

        methods: {
            blur() {
                this.$emit('blur')
            },
            emitKeyUp() {
                this.$emit('keyup')
            }
        }
    }
</script>
