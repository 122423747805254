export function adjustColor(hex) {
    try {
        const rgb = hexToRGB(hex);
        
        
        return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)`;
    } catch(error) {
        console.error(error);
        
        return '#FFFFFF';
    }
}
  
function hexToRGB(hex) {
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
    
    return {r, g, b};
}