import Vue from 'vue'
import store from '~/store'
import router from '~/router'
import i18n from '~/plugins/i18n'
import App from '~/components/App'
import interceptorSetup from '~/plugins/axios'
import * as Sentry from "@sentry/vue";
import VueTextareaAutosize from 'vue-textarea-autosize'

Vue.use(VueTextareaAutosize);

require('es6-promise').polyfill()

import '~/plugins'
import '~/directives'
import '~/components'
import '~/svgs'
import '~/styles/app.scss'
import { setOnBeforeUnload } from './utils/windowEvents'

const env = process.env.VUE_APP_ENV;
const apiRoutes = require('~/api/routes');

Vue.config.productionTip = false

// app.config.globalProperties.$apiUrl = import('~/api/routes');
Vue.prototype.$apiUrl = apiRoutes;

if (env === 'prod') {
  Sentry.init({
    Vue,
    dsn: 'https://afac5ff0f20e11ecb30e0242ac130018@sentry.corp.code-pilots.com/27'
  });
}

/* eslint-disable no-new */
const app = new Vue({
  i18n,
  store,
  router,
  ...App
})

setOnBeforeUnload();

interceptorSetup(app)
store.$app = app
