<template>
    <div v-if="table.total > 0" class="flex items-center w-full py-2 pl-6 pr-2">
        <span
            class="mr-auto text-body color-text-secondary"
        >{{ table.from+1 }} to {{ table.to }} of {{ table.total }}</span>
        <div v-if="table.lastPage > 1" class="pagination z-10">
            <button
                class="page-item prev"
                :class="{'disabled': table.page === 1}"
                @click="table.goToPrev()"
            >
                <svg-importer icon-name="icons/arrow-left" key="pagniate-arrow-left" />
            </button>
            <button
                v-for="n in table.paginationItems"
                :key="n.value"
                class="page-item"
                :class="{'active': n.value === table.page }"
                @click="table.goToPage(n.value)"
            >{{ n.title }}</button>
            <button
                class="page-item next"
                :class="{'disabled': table.page === table.lastPage}"
                @click="table.goToNext()"
            >
                <svg-importer icon-name="icons/arrow-right" key="pagniate-arrow-right" />
            </button>
        </div>
    </div>
</template>
<script>
    export default {
        inject: ["table"],
        created() {
        }
    };
</script>