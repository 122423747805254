import * as types from '../mutation-types'

const JSONClone = (data) => JSON.parse(JSON.stringify(data));

const getModulesStateDictionary = (stateNames) => {
    return stateNames.reduce((output, name) => {
        output[name] = name;

        return output;
    }, {});
}

const getAdditionalServiceTrigger = ({ children, carrier, carrier_service, ...object }) => object;

const MODULES_UPDATE_TRIGGER_TYPES = ['radio', 'checkbox', 'hidden', 'tel'];

const MODULES_STATE_DICTIONARY = getModulesStateDictionary(['hidden', 'visible', 'disabled']);

const DEFAULT_MODULES = {
    'custom-clearance-module': MODULES_STATE_DICTIONARY.hidden,
    'custom-value-module': MODULES_STATE_DICTIONARY.hidden,
    'custom-data-module': MODULES_STATE_DICTIONARY.hidden,
    'load-meters-module': MODULES_STATE_DICTIONARY.visible,
    'pickup-time-module': MODULES_STATE_DICTIONARY.visible,
    'non-stackable-module': MODULES_STATE_DICTIONARY.hidden,
}

const ADDRESS_DEFAULT ={
    address: '',
    address2: '',
    city: '',
    contact_person: '',
    country: '',
    email: '',
    name: '',
    phone: '',
    state: '',
    zip: '',
    active: true,
    company_model: {},
    types: [],
    autocomplete_list: [],
    autocomplete_name: [],
    autocomplete_email: [],
    unifaun_address_id: null,
    default_contact: null,
};

const PICKUP_TIME_DEFAULT = {
    pickup_time: null,
    pickupTimeEarliest: {
        key: 'pickupTimeEarliest',
        label: 'Earliest',
        value: null,
    },
    pickupTimeLatest: {
        key: 'pickupTimeLatest',
        label: 'Latest',
        value: null,
    },
};

export const DELIVERY_INSTRUCTIONS_DEFAULT = {
    instruction: '',
};

const getDefaultAddressSection = (sectionName) => {
    if (sectionName === 'pickup') {
        return JSONClone({ ...ADDRESS_DEFAULT, ...PICKUP_TIME_DEFAULT, section: sectionName });
    }

    return JSONClone({ ...ADDRESS_DEFAULT, section: sectionName });
};

const stepsKeysDict = {
    address: 'address',
    goods: 'goods',
    result: 'result',
    create_booking: 'create_booking',
    finalize_goods_description: 'finalize_goods_description',
    delivery_terms: 'delivery_terms',
    summary: 'summary',
    confirmation: 'confirmation',
};

const STEPS = {
    [stepsKeysDict.address]: {
        step: 0,
        key: stepsKeysDict.address,
        title: "Address",
        dataKey: stepsKeysDict.address,
        valid: null,
        errors: {},
        validationEndpoint: stepsKeysDict.address
    },
    [stepsKeysDict.goods]: {
        step: 0,
        key: stepsKeysDict.goods,
        title: "Goods",
        dataKey: stepsKeysDict.goods,
        valid: null,
        errors: {},
        validationEndpoint: stepsKeysDict.goods
    },
    [stepsKeysDict.result]: {
        step: 0,
        key: "price_leadtime",
        title: "Price & leadtime",
        dataKey: stepsKeysDict.result,
        errors: {},
        valid: null,
        validationEndpoint: stepsKeysDict.result
    },
    [stepsKeysDict.create_booking]: {
        step: 0,
        key: stepsKeysDict.create_booking,
        title: 'Finalize form & create booking',
        dataKey: stepsKeysDict.create_booking,
        valid: null,
        errors: {},
        validationEndpoint: 'create-booking',
    },
    [stepsKeysDict.finalize_goods_description]: {
        step: 0,
        key: stepsKeysDict.finalize_goods_description,
        title: 'Finalize goods description',
        dataKey: stepsKeysDict.finalize_goods_description,
        valid: null,
        errors: {},
        validationEndpoint: 'price-lyt',
    },
    [stepsKeysDict.delivery_terms]: {
        step: 0,
        key: stepsKeysDict.delivery_terms,
        title: "Delivery terms",
        dataKey: stepsKeysDict.delivery_terms,
        valid: null,
        errors: {},
        validationEndpoint: stepsKeysDict.delivery_terms
    },
    [stepsKeysDict.summary]: {
        step: 0,
        key: stepsKeysDict.summary,
        title: "Summary",
        dataKey: stepsKeysDict.summary,
    },
    [stepsKeysDict.confirmation]: {
        step: 0,
        key: stepsKeysDict.confirmation,
        title: "Confirmation",
    },
};

const SHORT_FLOW_STEPS_KEYS = [
    stepsKeysDict.address,
    stepsKeysDict.goods,
    stepsKeysDict.delivery_terms
];

const PLT_STEPS_KEYS = [
    stepsKeysDict.address,
    stepsKeysDict.goods,
    stepsKeysDict.result,
];

const STEPS_KEYS = [
    stepsKeysDict.address,
    stepsKeysDict.goods,
    stepsKeysDict.result,
    stepsKeysDict.create_booking,
    stepsKeysDict.finalize_goods_description,
    stepsKeysDict.delivery_terms,
    stepsKeysDict.summary,
    stepsKeysDict.confirmation,
];

export const FLOW_TYPE_MAP = {
    // FLOW_TYPE_MAP.quote: standard flow - includes 'Select booking scenario' screen & allows to use short flow
    'quote': 'quote',
    // FLOW_TYPE_MAP.short: includes 'Select booking scenario' screen & forces to use short flow
    'short': 'short',
    // FLOW_TYPE_MAP.plt: excludes 'Select booking scenario' screen & forces to finish at stepsKeysDict.result step (proxio request page)
    // creates no booking, only displays proxio results
    'plt': 'plt',
};

// state
export const getDefaultState = () => {
    return {
        goodsTypeList: [],
        currentTemplate: null,
        bookingModule: false, // If the booking functionality is active.
        currentStep: 0,
        currencies: ['SEK'],
        isSetupProxio: false,
        priceLeadTimeResult: {
            carrier_model: null,
            carrier_service_model: null,
        },
        flowType: FLOW_TYPE_MAP.quote,
        shortSteps: SHORT_FLOW_STEPS_KEYS.map((key, index) => ({ ...STEPS[key], step: index })),
        pltSteps: PLT_STEPS_KEYS.map((key, index) => ({ ...STEPS[key], step: index })),
        quoteSteps: STEPS_KEYS.map((key, index) => ({ ...STEPS[key], step: index })),
        invoiceDetails: {
            invoice_number: null,
            invoice_date: null,
            currency: null,
            freight_charges: null,
            insurance: null,
            reason_for_export: null,
            declaration_statement: null,
            delivery_term: null,
            vat_no_sender: null,
            vat_no_receiver: null,
            vat_no_buyer: null,
            articles: [],
            valid: false
        },
        shipmentDetails: {
            references: {
                consignor_reference: null,
                consignee_reference: null,
                purchase_order_number: null,
                sales_order_number: null,
                project_number: null,
            },
            terms: {
                type: null,
                location: null,
            },
            goods_information: {
                content: null,
                value: null,
                currency: null,
                invoice_details: false,
            },
            payment_details: {
                account_number: null,
                account_type: 'import',
            },
            documents: [],
        },
        isDutiable: false,
        summary: {
            label: "PNG",
        },
        lockedFields: {},
        address: {
            pickup: getDefaultAddressSection('pickup'),
            delivery: getDefaultAddressSection('delivery'),
            consignor: getDefaultAddressSection('consignot'),
            consignee: getDefaultAddressSection('consignee'),
        },
        result: {
            item: null
        },
        goods: {
            items: [
                {
                    goods_type: null,
                    units: 1,
                    pallet_size: null,
                    weight: null,
                    load_meters: null,
                    non_stackable: false,
                    pallet_spaces: null,
                    deptch: null,
                    width: null,
                    height: null,
                    volume: null,
                    stackable: null,
                    container_size: null,
                    required_fields: [],
                    package_type: null,
                    description: null,
                    shipping_mark: null
                }
            ]
        },
        payer: {
            consignee: null,
            consignor: null,
        },
        delivery_terms: {
            payer: 'sender',
            pickup_instructions: { ...DELIVERY_INSTRUCTIONS_DEFAULT },
            delivery_instructions: { ...DELIVERY_INSTRUCTIONS_DEFAULT },
            senders_reference: '',
            recipients_reference: '',
        },
        consignorShipment: null,
        modals: {
            dutiableServices: {
                name: 'dutiableServices',
                show: false,
            },
            dutiableServicesNoOptions: {
                name: 'dutiableServicesNoOptions',
                show: false,
            },
            dutiableServicesNotFound: {
                name: 'dutiableServicesNotFound',
                text: 'Please change the template, change the cargo parameters or contact support team',
                show: false,
            },
            dutiableServicesError: {
                name: 'dutiableServicesError',
                text: 'Please change the template or contact manager',
                show: false,
            },
            goodsInvalid: {
                name: 'goodsInvalid',
                text: 'Please change the template, change cargo parameters or contact manager',
                show: false,
            },
        },
        additionalServices: [],
        lastChangedAdditionalService: null,
        modulesStateDictionary: MODULES_STATE_DICTIONARY,
        modules: { ...DEFAULT_MODULES },
        attachments: [],
    }
}

export const state = getDefaultState()

// getters
export const getters = {
    modulesUpdateTriggers: state => state.additionalServices.reduce((output, service) => {
        if (service.children.length) {
            // TODO: MODULES_UPDATE_TRIGGER_TYPES - is obsolete, usage to be removed
            const matches = service.children.filter(child => MODULES_UPDATE_TRIGGER_TYPES.includes(child.type));
            matches.forEach(match => output.push(getAdditionalServiceTrigger(match)));
        }

        if (MODULES_UPDATE_TRIGGER_TYPES.includes(service.type)) {
            output.push(getAdditionalServiceTrigger(service));
        }

        return output;
    }, []),
    modulesVisibility: state => Object.fromEntries(
        Object.entries(state.modules)
            .map(entry => [entry[0], entry[1] === MODULES_STATE_DICTIONARY.visible])
    ),
    steps: state => state[`${state.flowType}Steps`],
    activeSteps: state => state[`${state.flowType}Steps`].filter((step) => {
        if (! state.bookingModule) {
            return step.step <= 6;
        }

        if (step.key === 'invoice') {
            return state.shipmentDetails.goods_information.invoice_details
        }

        return true;
    }),
    lockedFields: state => state.lockedFields,
    bookingModule: state => state.bookingModule,
    currentStep: state => state.currentStep,
    currencies: state => state.currencies,
    invoiceDetails: state => state.invoiceDetails,
    consignorShipment: state => state.consignorShipment,
    shipmentDetails: state => state.shipmentDetails,
    summary: state => state.summary,
    result: state => state.result,
    address: state => state.address,
    goods: state => state.goods,
    hasSetupProxio: state => state.isSetupProxio,
    getPriceLeadTimeResult: state => state.priceLeadTimeResult,
    delivery_terms: state => state.delivery_terms,
    flowType: state => state.flowType,
    isShortFlow: state => state.flowType === FLOW_TYPE_MAP.short,
    isPltFlow: state => state.flowType === FLOW_TYPE_MAP.plt,
    all: state => {
        return {
            address: state.address,
            goods: state.goods,
            result: state.result,
            shipmentDetails: state.shipmentDetails,
            invoiceDetails: state.invoiceDetails,
            summary: state.summary,
            deliveryTerms: state.delivery_terms,
        }
    },
    payer: state => state.payer,
    validation: state => {
        return {
            address: state.address,
            goods: state.goods,
            result: {
                item: state.result.item,
                goods: state.goods.items
            },
            invoiceDetails: state.invoiceDetails,
            summary: state.summary,
            shipmentDetails: {
                shipment_details: state.shipmentDetails,
                result: state.result.item
            }
        }
    },
    currentTemplate: state => state.currentTemplate,
    modals: state => state.modals,
}

// mutations
export const mutations = {
    [types.SET_CURRENT_TEMPLATE] (state, data) {
        state.currentTemplate = data;
    },
    [types.SET_LOCKED_FIELDS](state, data) {
        state.lockedFields = data;
    },
    [types.SET_AUTOCOMPLETE_LIST_BY_TYPE] (state, params) {
        state.address[params.type].autocomplete_list = params.list;
    },
    [types.SET_AUTOCOMPLETE_CONTACT_LIST_BY_TYPE] (state, { type, list, field }) {
        if (!list.length) {
            state.address[type][`autocomplete_${field}`] = list;
        }

        let newList = list;

        const defaultContact = state.address[type].default_contact;
        let defaultContactIndex = -1;

        if (defaultContact) {
            newList.unshift({ ...defaultContact, default: true });
            defaultContactIndex = newList.findIndex(
                item => item.name == defaultContact.name
                    && item.email == defaultContact.email
                    && item.phone == defaultContact.phone
            );
        }

        if (defaultContactIndex !== -1) {
            newList.splice(defaultContactIndex, 1);
        }

        state.address[type][`autocomplete_${field}`] = newList;
    },
    [types.SET_CONSIGNOR_SHIPMENT] (state, data) {
        state.consignorShipment = data;
    },
    [types.SET_INVOICE_DETAILS] (state, data) {
        state.invoiceDetails = data;
    },
    [types.SET_SHIPMENT_DETAILS] (state, data) {
        state.shipmentDetails = data;
    },
    [types.SET_SUMMARY] (state, data) {
        state.summary = data;
    },
    [types.SET_ADDRESS] (state, data) {
        state.address = data;
    },
    [types.SET_GOODS] (state, data) {
        state.goods = data;
    },
    [types.SET_GOODS_ITEMS] (state, data) {
        if (!state.goods) {
            return;
        }

        state.goods.items = data;
    },
    SET_GOODS_ITEM_BY_INDEX(state, { index, fieldName, value }) {
        if (!state.goods.items[index] || state.goods.items[index][fieldName]) {
            console.error('[store][mutation][SET_GOODS_ITEM_BY_INDEX]: no data found by {field} at {index}', fieldName, index);

            return;
        }

        state.goods.items[index][fieldName] = value;
    },
    [types.SET_CURRENT_STEP] (state, data) {
        state.currentStep = data;
    },
    [types.RESET_ADDRESS_BY_TYPE] (state, type) {
        state.address[type] = getDefaultAddressSection(type);
    },
    resetState (state, preserveFields) {
        Object.assign(state, preserveFields ? { ...getDefaultState(), ...preserveFields } : getDefaultState());
    },
    SETUP_PROXIO (state) {
        state.isSetupProxio = true;
    },
    UPDATE_PRICE_LEAD_TIME_RESULT(state, data) {
        state.priceLeadTimeResult = data;
    },
    SET_CONSIGNOR_PAYER(state, value) {
        state.payer.consignor = value;
    },
    SET_FLOW_TYPE(state, value) {
        state.flowType = value;
    },
    SET_CONSIGNEE_PAYER(state, value) {
        state.payer.consignee = value;
    },
    SET_CARRIER_MODEL(state, value) {
        state.priceLeadTimeResult.carrier_model = value;
    },
    SET_CARRIER_SERVICE_MODEL(state, value) {
        state.priceLeadTimeResult.carrier_service_model = value;
    },
    SET_PICKUP_TIME(state, { key, value }) {
        state.address.pickup[key].value = value;
    },
    SET_MODAL_SHOW_BY_TYPE(state, { type, value }) {
        state.modals[type].show = value;
    },
    SET_DELIVERY_TERMS(state, { key, value }) {
        if (!(key in state.delivery_terms)) {
            return;
        }

        state.delivery_terms[key] = value;
    },
    SET_ADDITIONAL_SERVICES(state, value) {
        if (state.additionalServices.length) {
            value.forEach(newService => {
                const stateService = state.additionalServices.find(service => service.id === newService.id);
                
                newService.value = stateService?.value || newService.value;
            })
        }
        
        state.additionalServices = value;
    },
    LAST_CHANGED_ADDITIONAL_SERVICE(state, params) {
        state.lastChangedAdditionalService = params;
    },
    SET_ADDITIONAL_SERVICE_BY_ID(state, { id, value }) {
        let addService;
        let isChildServiceMutated = false;

        const additionalServicesCopy = JSON.parse(JSON.stringify(state.additionalServices));

        for (const service of additionalServicesCopy) {
            if (service.id === id) {
                addService = service;

                break;
            }

            if (!service.children) {
                continue;
            }

            const match = service.children.find(child => child.id === id);

            if (match) {
                addService = match;
                isChildServiceMutated = true;

                break;
            }
        }

        if (!addService) {
            return;
        }

        addService.value = value;

        if (isChildServiceMutated) {
            // fix: vuex getter does not track deep changes
            state.additionalServices = additionalServicesCopy;
        }
    },
    SET_MODULES(state, data) {
        state.modules = data;
    },
    RESET_MODULES(state) {
        state.modules = { ...DEFAULT_MODULES };
    },
    SET_IS_DUTIABLE(state, value) {
        state.isDutiable = value;
    },
    SET_DELIVERY_TERMS_FIELD(state, { field, value }) {
        if (!(field in state.delivery_terms)) {
            return;
        };

        state.delivery_terms[field] = value;
    },
    ADD_ATTACHMENT(state, data) {
        state.attachments.push(data);
    },
    REMOVE_ATTACHMENT(index) {
        state.attachments.splice(index, 1);
    },
    SET_GOODS_TYPE_LIST(state, list) {
        state.goodsTypeList = list;
    },
}

export const actions = {
    setModules({ state, commit }, value) {
        if (!value) {
            commit('SET_MODULES', value || { ...DEFAULT_MODULES });

            return;
        }

        if (state.modules['non-stackable-module'] === MODULES_STATE_DICTIONARY.hidden && value['non-stackable-module'] === MODULES_STATE_DICTIONARY.visible) {
            state.goods.items.forEach(item => item.non_stackable = Boolean(item.load_meters));
        }
        
        if (value['load-meters-module'] === MODULES_STATE_DICTIONARY.hidden) {
            state.goods.items.forEach(item => item.load_meters = null);
        }

        commit('SET_MODULES', value || { ...DEFAULT_MODULES });
    },
    async updateAdditionalServiceById({commit}, params) {
        await commit('LAST_CHANGED_ADDITIONAL_SERVICE', params);
        commit('SET_ADDITIONAL_SERVICE_BY_ID', params);
    },
};
