<template>
  <div class="alert" :class="colorClass">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    color: {
      type: String,
      default: 'green'
    },
    show: Boolean
  },
  data: () => ({}),
  computed: {
    colorClass () {
      if (!this.color) return ''

      return 'alert-' + this.color
    }
  }
}
</script>
