import store from '~/store'

export default ({to, from, next}) => {
  if (!store.getters['auth/isGodUser'] && to.name === 'admin.shipments.detail') {
    next({ name: 'transport.shipments.detail', params: to.params });
  } else if (!store.getters['auth/isGodUser']) {
    next({ name: 'home' });
  } else {
    next()
  }
}
