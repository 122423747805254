<template>
    <div class="specifics-wrapper pb-6 pl-4 pr-4 pt-2 relative">
        <div class="f-size-18 text-center f-w-bold mb-6">Geographical mark-up variations</div>
        <div class="specifics-info__button cursor-pointer" @click="isShowInfo = !isShowInfo">
            <svg-importer width="16" icon-name="icons/info-question-mark" class="stroke-brand" />
        </div>
        <div v-show="!isShowInfo">
            <div class="flex mt-4">
                <div class="specifics-table">
                    <div v-for="(row, rowIndex) in rows" :key="`markup-row_${rowIndex}`" class="flex">
                        <div
                            v-for="(cell, cellIndex) in row"
                            :key="`markup-cell_${rowIndex}_${cellIndex}`"
                            class="w-1/4"
                            :class="{ 'mr-2': cellIndex !== row.length - 1, 'mb-2': rowIndex !== rows.length - 1 }"
                        >
                            <div
                                v-if="cell.type === 'header'"
                                class="specifics-cell"
                                :class="{
                                    'text-center': rowIndex === 0,
                                    'text-right': rowIndex !== 0,
                                    'mr-2': rowIndex !== 0,
                                }"
                            >
                                {{ cell.value }}
                            </div>
                            <span v-else-if="cell.type === 'cell'">
                                <input
                                    type="number"
                                    :value="geographySpecificRates[cell.value]"
                                    placeholder="%"
                                    @input="updateGeographySpecificRates(cell.value, $event.target.value)"
                                >
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <slot name="tools" />
        </div>
        <div v-show="isShowInfo">
            <div class="relative">
                <ul class="relative specifics-info__list">
                    <li v-for="(item, index) in infoData" :key="`item_${index}`" :class="{ image: 'image' in item }">
                        {{ item.text }}
                        <ul v-if="item.list && item.list.length">
                            <li v-for="(subitem, subindex) in item.list" :key="`subitem_${subindex}`">
                                {{ subitem.text }}
                            </li>
                        </ul>
                    </li>
                </ul>
                <img src="/images/markup-info.jpg" class="specifics-info__image">
            </div>

            <div class="flex justify-center mt-2">
                <button class="btn-transparent" @click="isShowInfo = false">
                    Close
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { referenceTexts, cellTypeDictionary, cellValueDictionary } from './constants';

export default {
    name: 'MarkupGeographySpecificsTable',
    data() {
        return {
            isShowInfo: false,
            infoData: referenceTexts,
            rows: [],
        }
    },
    computed: {
        countryName() {
            return this.$store.getters['markups/countryName'];
        },
        rate() {
            return this.$store.getters['markups/rate'];
        },
        fixed() {
            return this.$store.getters['markups/fixed'];
        },
        geographySpecificRates() {
            return this.$store.getters['markups/geographySpecificRates'];
        },
        tableHeaders() {
            return {
                dom: this.countryName,
                reg: 'Europe',
                ww: 'World-wide',
            };
        },
    },
    methods: {
        updateGeographySpecificRates(fieldName, value) {
            this.$store.dispatch('markups/updateGeographyRatesField', { field: fieldName, value });
        },
    },
    created() {
        this.rows = [
            [
                { type: '', value: '' },
                { type: cellTypeDictionary.header, value: this.tableHeaders.dom },
                { type: cellTypeDictionary.header, value: this.tableHeaders.reg },
                { type: cellTypeDictionary.header, value: this.tableHeaders.ww },
            ],
            [
                { type: cellTypeDictionary.header, value: this.tableHeaders.dom },
                { type: cellTypeDictionary.cell, value: cellValueDictionary.DOM_DOM },
                { type: cellTypeDictionary.cell, value: cellValueDictionary.DOM_REG },
                { type: cellTypeDictionary.cell, value: cellValueDictionary.DOM_WW },
            ],
            [
                { type: cellTypeDictionary.header, value: this.tableHeaders.reg },
                { type: cellTypeDictionary.cell, value: cellValueDictionary.REG_DOM },
                { type: cellTypeDictionary.cell, value: cellValueDictionary.REG_REG },
                { type: cellTypeDictionary.cell, value: cellValueDictionary.REG_WW },
            ],
            [
                { type: cellTypeDictionary.header, value: this.tableHeaders.ww },
                { type: cellTypeDictionary.cell, value: cellValueDictionary.WW_DOM },
                { type: cellTypeDictionary.cell, value: cellValueDictionary.WW_REG },
                { type: cellTypeDictionary.cell, value: cellValueDictionary.WW_WW },
            ],
        ];
    },
};
</script>
<style lang="scss" scoped>
.specifics-header {
    line-height: 33px;
}

.specifics-table {
    position: relative;
    margin-top: 30px;
    padding-top: 15px;

    &:after, &:before {
        display: block;
        position: absolute;
        text-align: center;
        border-bottom: 1px solid var(--brand-color);
        line-height: 2;
        font-weight: bold;
    }
    
    &::before {
        content: 'From';
        left: -30px;
        width: 120px;
        transform: rotate(-90deg) translate(-65%, -20px);
    }

    &::after {
        content: 'To';
        top: -30px;
        right: 0;
        width: 75%;
    }
}

.specifics-cell {
    &:first-child {
        padding-left: 30px;
    }
}

.specifics-info {
    &__button {
        position: absolute;
        top: 10px;
        right: 20px;

        svg path {
            fill: #20984B;
        }
    }

    &__list {
        li {
            padding-left: 2em;
            position: relative;
            font-size: 16px;

            &::before {
                content: '';
                position: absolute;
                top: .5em;
                left: 1em;
                display: block;
                width: 5px;
                height: 5px;
                background-color: #000;
                border-radius: 5px;
            }

            &.image {
                padding-right: 350px;
            }
        }
    }

    &__image {
        position: absolute;
        right: 0;
        bottom: 0;
    }
}
</style>