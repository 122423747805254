
import Vue from 'vue'

import VModal from 'vue-js-modal'

const options = {
    componentName: 'v-modal'
}

Vue.use(VModal, options)
