<template>
    <div :style="'position: relative; width:100%; height: ' + height + 'px'">
        <div v-if="loading" class="absolute w-full h-full flex  items-center justify-center">
            <fa :icon="['fad', 'spinner-third']" class="brand-primary fa-spin text-4xl" />
        </div>
        <v-trend
            :stroke-width="strokeWidth"
            :height="height"
            :data="data"
            :gradient="color"
            :smooth="smooth"
            :auto-draw="autoDraw"
        />
    </div>
</template>

<script>

    export default {

        props: {
            strokeWidth: {
                default: 2,
                required: false,
                type: Number
            },
            height: {
                default: 100,
                required: false,
                type: Number
            },
            data: {
                required: true,
                type: Array
            },
            smooth: {
                default: 100,
                required: true,
                type: Boolean
            },
            autoDraw: {
                required: true,
                type: Boolean
            },
            loading: {
                required: false,
                default: false,
                type: Boolean
            },
        },

        name: 'Trend',

        computed: {
            color() {
                return [this.$cookies.get('brand_color') || '#2E457C'];
            }
        }
    }
</script>
