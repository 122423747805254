<template>
  <div id="app">
    <loading ref="loading" />

    <transition name="page" mode="out-in">
      <component :is="layout">
        <router-view/>
      </component>
    </transition>
  </div>
</template>

<script>
import Loading from './Loading'

export default {
  el: '#app',

  components: {
    Loading
  },

  computed: {
    layout() {
      return this.$route.meta.layout || 'static'
    }
  },

  metaInfo () {
    const { appName } = window.config

    return {
      title: appName,
      titleTemplate: `%s · ${appName}`
    }
  },
}
</script>
