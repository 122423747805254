<template>
  <div>
    <child />
  </div>
</template>

<script>
export default {
  name: 'StaticLayout'
}
</script>
