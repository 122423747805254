<template>
    <modal-field :label="title" class="mb-2 mt-2">
        <h5 class="text-base" :class="{'text-gray-400' : !text}">{{ text || 'Not specified' }}</h5>
    </modal-field>
</template>

<script>
export default {
    name: "DetailModalField",
    props: {
        title: String,
        text: {
            type: [String, Object, Number]
        }
    }
}
</script>