export function parseRangeDate(range) {
    if (range === 'all' || range.startDate === 'all' || range.endDate === 'all') {
        return 'all';
    }
    
    if (range && !range.startDate) {
        return '';
    }

    return range.startDate.format('YYYY-MM-DD') + ',' + range.endDate.format('YYYY-MM-DD');
}

export function toParams (paramsValue, paramsKey, paramsIndex, returnObject) {
    const key = paramsValue.key || paramsKey;
    const operator = paramsValue.operator || '=';

    let value = paramsValue.value;
    let resultValue = paramsValue.value;

    if (!value) {
        return null;
    }

    if (value === null || value === undefined) {
        return null;
    }

    if (typeof value === 'string' && !value.length) {
        return null
    }

    if (typeof value === 'number' && value === 0) {
        return null;
    }

    if (Array.isArray(value) && !value.length) {
        return null;
    }

    if (Array.isArray(value)) {
        const result = value.map(item => {
            const keyValue = paramsValue.keyValue;

            if (keyValue) {
                return item[keyValue]
            }

            return item;
        })

        resultValue = result.join(',');
    }

    if (!Array.isArray(value) && Object.keys(value) && paramsValue.keyValue) {
        resultValue = paramsValue.value[paramsValue.keyValue];
    }

    if (!Array.isArray(value) && Object.keys(value) && 'startDate' in value && 'endDate' in value) {
        if (!value.startDate || !value.endDate) {
            return null;
        }

        resultValue = parseRangeDate(value)
    }

    if (returnObject) {
        return { key, value };
    }

    return `filters[${paramsIndex}][name]=${key}&filters[${paramsIndex}][value]=${resultValue}&filters[${paramsIndex}][operator]=${operator}`;
}

export function filterParams(filters) {
    const result = [];

    for (let key in filters) {
        const data = toParams(filters[key], key, result.length)

        if (!data) {
            continue;
        }

        result.push(data);
    }

    return result.join('&');
}

export function filterParamsWatcher(filters) {
    const result = {};

    for (let key in filters) {
        const data = toParams(filters[key], key, null, true);

        if (!data?.value) {
            continue;
        }

        result[`filter[${data.key}]`] = data.value;
    }

    return result;
}

export function queryParams(params) {
    return Object.keys(params).map(key =>  key + '=' + params[key]).join('&');
}

export function serializeParamsResponse(query, filter) {
    const queryResult = query();

    delete queryResult.rfid;

    return [queryParams(queryResult), filterParams(filter)].filter(item => { return item }).join('&');
}