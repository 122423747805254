<template>
  <transition name="fade">
    <div v-if="visible " class="overlay" @click.self="hide(true)">
      <transition name="fade">
        <div v-if="isLoader" class="loader">
            <div class="flex p-1 bg-white rounded-full">
                <fa :icon="['fad', 'spinner-third']" class="text-4xl leading-none brand-primary fa-spin" />
            </div>
        </div>

        <div v-if="!isLoader" class="w-full overflow-y-auto overflow-x-hidden card modal" :class="{'modal-lg': size === 'lg', 'modal-sm': size === 'sm', 'modal-md': size === 'md', 'modal-2xl': size === '2xl', 'modal-3xl': size === '3xl', 'modal-middle': size === 'middle', 'modal-s': size === 's', 'modal-600': size === '600'}">
          <div v-if="!hideHeader" class="flex-grow-0 px-6 bg-white border-0 border-b card-header" :class="{'mb-5': removeHeaderBottomMargin !== true}">
            <slot name="header" />

            <button v-if="!isLoader && closeable" class="ml-auto text-gray-500 close-modal-btn" @click="hide(false)">
              <svg-importer icon-name="icons/close" />
            </button>
          </div>

            <div :class="bodyClass" class="modal__body">
                <slot class="" name="body" />
            </div>

          <div v-if="hasFooterSlot" class="flex p-6 mt-auto modal__footer">
            <slot name="footer" />
          </div>
        </div>
      </transition>

    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    show: Boolean,
    size: String,
    loader: Boolean,
    loading: Boolean, // NO NOT USE. Use loader prop instead.
    clickToClose: {
        type: Boolean,
        required: false,
        default: true,
    },
    closeable: {
      type: Boolean,
      required: false,
      default: true,
    },
    escapeable: {
      type: Boolean,
      default: true,
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    removeHeaderBottomMargin: Boolean,
    bodyClass: {
        required: false,
        type: String,
        default: 'px-6'
    }
  },

  data: () => ({
    visible: false
  }),

  computed: {
    hasFooterSlot () {
      return this.$slots['footer']
    },

    isLoader() {
        // As there was two props doing the same thing, we're using this as a failsafe. Use loader
        if (this.loader) {
            return true;
        }
        if (this.loading) {
            return true;
        }

        return false;
    }
  },

  watch: {
    show (visible) {
      if (visible) {
        this.toggleBodyClass('add', 'modal-open')
        this.visible = true
      } else {
        this.toggleBodyClass('remove', 'modal-open')
        this.visible = false
      }
    }
  },

  created () {
    this.toggleBodyClass('remove', 'modal-open')
    this.visible = this.show;
  },

  methods: {
    hide(outside) {
      if (outside && !this.clickToClose) {
        return;
      }

      this.$emit('hide')
    },
    escape() {
      document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape' && this.show && this.escapeable) {
          this.hide();
        }
      });
    }
  },
  mounted() {
    this.escape();
  }
}
</script>

<style lang="scss">
.modal__body {
  overflow: auto;
}
</style>