<template>
    <div @click="toggleDropdown" class="relative" ref="toggleDropdown">
        <slot name="trigger" />

        <transition name="slide">
            <div
                :class="dropdownClass"
                class="absolute right-0 z-20 mt-2 -mr-3 border rounded shadow-xl arrow-box dropdown"
                v-closable="{
                    exclude: ['toggleDropdown'],
                    handler: 'onCloseDropdown'
                }"
                v-if="showDropdown"
            >
                <slot name="default" />
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'Dropdown',

    props: {
        dropdownClass: String
    },

    data: () => ({
        showDropdown: false
    }),

    methods: {
        toggleDropdown() {
            this.showDropdown = !this.showDropdown
        },
        onCloseDropdown() {
            this.showDropdown = false
            this.$emit('hide')
        }
    }
}
</script>
