export function doSort (toSort, sortKey, customSort, sortOrder) {
    let local = [...toSort]
  
    return local.sort((a, b) => {
      if (typeof customSort === 'function') {
        return customSort(a, b) * sortOrder
      }
  
      let val1
      let val2
  
      if (typeof sortKey === 'function') {
        val1 = sortKey(a, sortOrder)
        val2 = sortKey(b, sortOrder)
      } else {
        val1 = getProp(a, sortKey)
        val2 = getProp(b, sortKey)
      }
  
      if (val1 === null || val1 === undefined) val1 = ''
      if (val2 === null || val2 === undefined) val2 = ''
  
      if (isNumeric(val1) && isNumeric(val2)) {
        return (val1 - val2) * sortOrder
      }
  
      const str1 = val1.toString()
      const str2 = val2.toString()
  
      return str1.localeCompare(str2) * sortOrder
    })
  }
  
  export function calculateTotalPages (totalItems, perPage) {
    return totalItems <= perPage ? 1 : Math.ceil(totalItems / perPage)
  }

  export function serializeParams (params) {
    return Object.keys(params)
      .map(key => {
        let value = params[key];
        
        if (['filter[consignee_name]', 'filter[consignor_name]'].includes(key)) {
          value = params[key].join('||')
        }

        let result = key + '=' + value;

        if (result.indexOf('&') !== -1) {
          result = result.replace(/&/g, encodeURIComponent('&'));
        }
        
        return result;
      }).join('&');
  }
  
  export function getProp (object, keyPath) {
    keyPath = keyPath.replace(/\[(\w+)\]/g, '.$1')
    keyPath = keyPath.replace(/^\./, '')
    const a = keyPath.split('.')
    for (let i = 0, n = a.length; i < n; ++i) {
      let k = a[i]
      if (object && k in object) {
        object = object[k]
      } else {
        return
      }
    }
    return object
  }
  
  export function isNumeric (toCheck) {
    return !Array.isArray(toCheck) && !isNaN(parseFloat(toCheck)) && isFinite(toCheck)
  }
  
  export function uuid () {
    return '_' + Math.random().toString(36).substr(2, 9)
  }
  