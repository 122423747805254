import Vue from 'vue'

const GetOrFetch = {
  install (Vue) {
    Vue.shouldGet = function (input) {
      if (!input) {
        return true
      }

      if (input.length === 0) {
        return true
      }

      return false
    }

    Vue.prototype.$getOrFetch = function (getter, action) {
      let getterValue = this.$store.getters[getter]
      let shouldGet = Vue.shouldGet(getterValue)

      if (shouldGet) {
        this.$store.dispatch(action)
      }

      return getterValue
    }
  }
}

Vue.use(GetOrFetch)
