import axios from 'axios'
import store from '~/store'
import router from '~/router'
import Swal from 'sweetalert2'
import i18n from '~/plugins/i18n'
import { v4 as uuidv4 } from 'uuid';
import { parsePageName } from '~/utils/page-address-parser.js'

export default function setup (vm) {
  // Request interceptor
  axios.interceptors.request.use(request => {
    const token = store.getters['auth/token']
    if (token) {
      request.headers.common['Authorization'] = `Bearer ${token}`
    }

    const locale = store.getters['lang/locale']
    if (locale) {
      request.headers.common['Accept-Language'] = locale
    }

    const sites = store.getters['auth/sites']

    if (Array.isArray(sites) && sites.filter(site => site).length) {
      request.headers['X-Globl-Sites'] = sites.map(item => (item.id)).join(',')
    }

    if (!localStorage.getItem('session-id') && token) {
      localStorage.setItem('session-id', uuidv4());
    }

    if (localStorage.getItem('session-id') && token) {
      request.headers['X-SESSION-ID'] = localStorage.getItem('session-id');
    }

    request.headers['X-PAGE-NAME'] = parsePageName(window.location.pathname);

    return request
  })

  axios.defaults.baseURL = process.env.VUE_APP_API;

  // Response interceptor
  axios.interceptors.response.use(response => response, error => {
    const status = error.response?.status;

    if (status >= 500) {
      // vm.$snotify.error("Something went wrong! 🖕")
      vm.$snotify.error('Oops, something went wrong!')
    }

    if (status === 401 && store.getters['auth/check']) {
      Swal.fire({
        type: 'warning',
        title: i18n.t('token_expired_alert_title'),
        text: i18n.t('token_expired_alert_text'),
        reverseButtons: true,
        confirmButtonText: i18n.t('ok'),
        cancelButtonText: i18n.t('cancel')
      }).then(() => {
        store.commit('auth/LOGOUT')

        router.push({ name: 'login' })
      })
    }

    return Promise.reject(error)
  })
}
