<template>
    <div class="relative">
        <icon
            name="icons/gear"
            ref="toggleTableToolsBtn"
            title="Table settings"
            :class="settingsIconsOutline ? 'outline-default' : 'bg-transparent'"
            @click="$emit('show', !toggleTableTools)"
        />

        <transition name="slide">
            <div
                v-if="toggleTableTools"
                class="absolute pl-2 pt-2 -mr-1 border rounded arrow-box dropdown w__320"
                :class="[
                    horizontalPosition === 'right' ? 'left-0 arrow-box_right' : 'right-0',
                    verticalDirection === 'bottom' ? 'top-12' : 'bottom-12 arrow-box_top',
                ]"
                style="z-index: 101"
                v-closable="{
                    exclude: ['toggleTableToolsBtn'],
                    handler: 'onClose'
                }"
            >
                <h5 class="pb-2 pl-2 mr-2 text-body border-b flex flex__justify-between">
                    <span>Table settings</span>
                    <button class="pr-2" @click="$emit('show', !toggleTableTools)">
                        <svg-importer icon-name="icons/close" />
                    </button>
                </h5>

                <draggable v-model="toggleableColumnsComputed" @update="$emit('click')" class="dropdown_scroll list-group" handle=".handle">
                    <template v-for="(col, cIndex) in toggleableColumnsComputed">
                        <div v-if="isDisplayColumn(col)" :key="cIndex" class="mr-2 pl-2 pr-2 border__bottom-grey_not-last">
                            <label class="flex flex__justify-between items-center" style="height: 56px">
                                <span class="flex items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        class="form-checkbox"
                                        :checked="col.toggled"
                                        :disabled="(cIndex < 3 && col.pin) || (disabledToggled && col.toggled)"
                                        @change="$emit('update', col)"
                                    />
                                    <span class="ml-2">{{ col.title }}</span>
                                </span>

                                <span class="flex items-center">
                                    <button v-if="cIndex < 3" @click="updatePin(col, cIndex)" class="btn-pin">
                                        <fa :icon="['fal','thumbtack']" style="width: 20px; height: 20px;" :class="{ 'opacity-25': !col.pin }" />
                                    </button>

                                    <button class="handle ml-5">
                                        <fa :icon="['fal','grip-lines']" style="width: 20px; height: 20px;" />
                                    </button>
                                </span>
                            </label>
                        </div>
                    </template>
                </draggable>
            </div>
        </transition>
    </div>
</template>
<script>
import Draggable from 'vuedraggable';

export default {
    components: {
        Draggable,
    },
    props: {
        toggleableColumns: {
            type: Array,
            default: () => [],
        },
        toggleTableTools: {
            type: Boolean,
            default: false,
        },
        settingsIconsOutline: {
            type: Boolean,
            default: true,
        },
    },
    model: {
        prop: 'toggleableColumns',
        event: 'update:toggleableColumns',
    },
    computed: {
        toggleableColumnsComputed: {
            get() {
                return this.toggleableColumns;
            },
            set(value) {
                this.$emit('update:toggleableColumns', value);
            },
        },
        disabledToggled() {
            const result =  this.toggleableColumns.filter(item => {
                return item.toggled;
            });

            return result.length <= 3;
        },
    },
    data() {
        return {
            horizontalPosition: 'right',
            verticalDirection: 'bottom',
        }
    },
    methods: {
        isDisplayColumn(column) {
            return column.key != 'actions' && (!('toggleable' in column) || column.toggleable);
        },
        onClose() {
            this.$emit('show', false);
        },
        async updatePin(col, colIndex) {
            col.pin = !col.pin;

            if (col.pin) {
                col.toggled = true;
            }

            if (col.pin) { //true
                this.toggleableColumnsComputed = this.toggleableColumnsComputed.map((item, index) => {
                    if (index <= colIndex) {
                        item.pin = true;
                        item.toggled = true;
                    }

                    return item;
                });
            }

            if (!col.pin) {
                this.toggleableColumnsComputed = this.toggleableColumnsComputed.map((item, index) => {
                    if (index >= colIndex) {
                        item.pin = false;
                    }

                    return item;
                });
            }

            this.trigger++;
        },
    },
    mounted() {
        if (window.innerWidth - this.$el.offsetLeft < 340) {
            this.horizontalPosition = 'left';
        }

        const body = document.body;
        const html = document.documentElement;

        const documentHeight = Math.max(
            body.scrollHeight,
            body.offsetHeight, 
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
        );

        const scrollTop = window.pageYOffset || html.scrollTop || body.scrollTop;
        const clientTop = html.clientTop || body.clientTop || 0;
        const top = Math.round(this.$el.getBoundingClientRect().top +  scrollTop - clientTop);

        if (documentHeight - top < 500) {
            this.verticalDirection = 'top';
        }
    },
}
</script>