import Vue from 'vue'
import Card from './Card'
import Child from './Child'
import Checkbox from './Checkbox'
import VCheckbox from './VCheckbox'
import Button from './Button'
import PageHeader from './PageHeader'
import CountryFlag from 'vue-country-flag'
import MegaModal from './MegaModal'
import Modal from './Modal'
import TSelect from './TSelect'
import Caret from './Caret'
import TSwitch from './TSwitch'
import ResellerSelect from './ResellerSelect'
import { HasError, AlertError, AlertSuccess } from 'vform'
import Multiselect from '~/components/FormMultiselect';
import Alert from './Alert'
import ModalField from './ModalField'
import DetailModalField from './DetailModalField'
import OrderRow from './OrderRow'
import InvoiceRow from './InvoiceRow'
import Chart from './Chart'
import Gallery from './Gallery'
import Tab from './Tab'
import TrendIndicator from './TrendIndicator'
import MapBox from './MapBox'
import EmojiPopup from './EmojiPopup'
import SpinningClock from './SpinningClock'
import Confirm from './Confirm'
import Dropdown from './Dropdown'
import AddSiteModal from './AddSiteModal'
import AddReportModal from './AddReportModal'
import AddUserModal from './AddUserModal'
import DeleteModal from './DeleteModal'
import MarkupModal from './markups/MarkupModal'
import AddMarkupModal from './markups/AddMarkupModal'
import VueTheMask from 'vue-the-mask'
import Gravatar from 'vue-gravatar';
import Gauge from './Gauge';
import Trend from './Trend';
import MultiSelect from './MultiSelect';
import TInput from './TInput';
import DateFilter from './DateFilter';
import AButtonDropdown from './ButtonDropdown';
import ModalContent from './ModalContent';
import EditReportModal from './EditReportModal'
import RadioInput from './RadioInput';

//layouts
import Basic from '~/layouts/basic';
import Default from '~/layouts/default';
import Login from '~/layouts/login';
import Static from '~/layouts/static';
import Empty from '~/layouts/empty';
import Placeholder from '~/layouts/placeholder';
import SvgImporter from './SvgImporter';
import Icon from './Icon';


Vue.component('basic', Basic);
Vue.component('default', Default);
Vue.component('login', Login);
Vue.component('static', Static);
Vue.component('empty', Empty);
Vue.component('placeholder', Placeholder);

import './vtable'

//import './table/main'
// Components that are registered globaly.
[
  Card,
  Child,
  Checkbox,
  Button,
  HasError,
  AlertError,
  AlertSuccess,
  PageHeader,
  CountryFlag,
  MegaModal,
  TSelect,
  Caret,
  TSwitch,
  ResellerSelect,
  Alert,
  ModalField,
  OrderRow,
  InvoiceRow,
  Chart,
  Gallery,
  Tab,
  TrendIndicator,
  Modal,
  AddSiteModal,
  AddReportModal,
  EditReportModal,
  AddUserModal,
  DeleteModal,
  MarkupModal,
  AddMarkupModal,
  MapBox,
  DetailModalField,
  AButtonDropdown,
  EmojiPopup,
  SpinningClock,
  Confirm,
  Dropdown,
  MultiSelect,
  Gauge,
  VueTheMask,
  Trend,
  TInput,
  ModalContent,
  VCheckbox,
  SvgImporter,
  Icon,
  RadioInput,
].forEach(Component => {
  Vue.component(Component.name, Component)
})

Vue.component('v-gravatar', Gravatar);
Vue.component('multiselect', Multiselect)
Vue.filter('date', DateFilter.date)
Vue.use(VueTheMask)
