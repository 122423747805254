<template>
  <div class="flex items-center">
    <label class="text-body color-text-secondary relative flex f-checkbox cursor-pointer">
      <app-spinner v-if="loading" class="f-checkbox__loader" :size="14"/>
      <input
        :id="id || name"
        :name="name"
        :checked="inverted ? !internalValue : internalValue"
        type="checkbox"
        :disabled="disabled"
        class="hidden"
        @change="emitChange($event)"
        @click="handleClick"
      >
      <div class="f-checkbox__toggle mr-3" :class="{'no-branding': !branding}">
        <svg-importer icon-name="icons/checkbox-check" />
      </div>

      <div class="color-text-primary f-checkbox__text text-draggable">
        <slot />
      </div>
    </label>
  </div>
</template>

<script>
import AppSpinner from '~/components/AppSpinner';

export default {
  name: 'Checkbox',

  components: {
    AppSpinner
  },

  props: {
    id: { type: [String, Number], default: null },
    name: { type: [String, Number], default: 'checkbox' },
    value: { type: [Boolean, Number,String], default: false },
    checked: { type: [Boolean, Number], default: false },
    disabled: { type: Boolean, default: false },
    branding: { type: Boolean, default: true, required: false },
    loading: { type: Boolean, default: false },
    inverted: { type: Boolean, default: false },
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data: () => ({
    internalValue: false
  }),

  watch: {
    value (val) {
      this.internalValue = val
    },

    checked (val) {
      this.internalValue = val
    },

    internalValue (val, oldVal) {
      if (val !== oldVal) {
        this.$emit('input', val)
      }
    }
  },

  created () {
    if ('checked' in this.$options.propsData) {
      this.internalValue = this.checked
    }
  },

  methods: {
    handleClick (e) {
      this.$emit('click', e)

      if (!e.isPropagationStopped) {
        this.internalValue = e.target.checked
      }
    },

    emitChange() {
        if (this.preventChange) {
          return;
        }
        
        this.$emit('change', this.internalValue);
    }
  }
}
</script>

<style lang="scss">
@import 'src/styles/components/_checkbox.scss';
</style>
