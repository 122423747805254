<template>
    <pre class="bg-gray-100 p-4">
    loading: {{ table.loading ? 1 : 0 }}
    sortKey: {{ table.sortKey }}
    sortOrder: {{ table.sortOrder }}
    page: {{ table.page }}
    pageSize: {{ table.pageSize }}
    endpoint: {{ table.endpoint }}
    rows: {{ table.rows.length }}
    currentPage: {{ table.currentPage }}
    loading: {{ table.loading }}
    queryParams: {{ table.queryParams }}
    urlPath: {{ table.urlPath }}
    filter: {{ table.filter }}
    exportKeys: {{ table.exportKeys }}
    router: {{ table.router ? 'loaded' : '!loaded' }}
    route: {{ table.route ? 'loaded' : '!loaded' }}
    checkedRows: {{ table.checkedRows.length }}
    </pre>
</template>

<script>
    export default {
        inject: ["table"]
    };
</script>