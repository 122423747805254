export const state = {
    list: [
        {
            id: 1,
            shipment_no: 627005,
            status: 'Done',
            register_date: '03-09-2024',
            waybill_no: 1063183805026580,
            importer: 'SE - Beijer Electronics (VChain)',
            hs_codes: [4560977, 857567],
            accompanying_shipments: 1,
            documents: [
                { name: '103907__73110011058_20240821112811.pdf', path: '/pdf/103907__73110011058_20240821112811.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
                { name: '103907__Beijer_20240821113133.pdf', path: '/pdf/103907__Beijer_20240821113133.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
            ],
            goods: [
                { id: 1, name: 'Item #1', weight: 84 },
                { id: 2, name: 'Item #2', weight: 82 },
            ],
        },
        {
            id: 2,
            shipment_no: 362850,
            status: 'Done',
            register_date: '26-08-2024',
            waybill_no: 1047462619541260,
            importer: 'SE - Beijer Electronics (VChain)',
            hs_codes: [7890345],
            accompanying_shipments: 1,
            documents: [
                { name: '103907__GOT22125795_20240822_20240828062016.pdf', path: '/pdf/103907__GOT22125795_20240822_20240828062016.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
                { name: '104746__2619541260_20240827_20240827073555.pdf', path: '/pdf/104746__2619541260_20240827_20240827073555.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
                { name: '104746_2619541260_DA70749057_MMA_20240826_113854_20240826113242.pdf', path: '/pdf/104746_2619541260_DA70749057_MMA_20240826_113854_20240826113242.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
                { name: '106318__3805026584_20240903_20240903091432.pdf', path: '/pdf/106318__3805026584_20240903_20240903091432.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
            ],
            goods: [
                { id: 1, name: 'Item #1', weight: 56 },
            ],
        },
        {
            id: 3,
            shipment_no: 593100,
            status: 'Review',
            register_date: '21-08-2024',
            waybill_no: 104431039078376,
            importer: 'SE - Beijer Electronics (VChain)',
            hs_codes: [4560977, 5834567, 5678904, 7890345],
            accompanying_shipments: 2,
            documents: [
                { name: '106318_3805026584_DA70885356_MMA_20240902_124022_20240903055418.pdf', path: '/pdf/106318_3805026584_DA70885356_MMA_20240902_124022_20240903055418.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
                { name: '103907__73110011058_20240821112811.pdf', path: '/pdf/103907__73110011058_20240821112811.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
            ],
            goods: [
                { id: 1, name: 'Item #1', weight: 76 },
                { id: 1, name: 'Item #2', weight: 47 },
                { id: 1, name: 'Item #3', weight: 62 },
                { id: 1, name: 'Item #4', weight: 78 },
            ],
        },
        {
            id: 4,
            shipment_no: 625059,
            status: 'Done',
            register_date: '20-08-2024',
            waybill_no: 1035552535049620,
            importer: 'SE - Beijer Electronics (VChain)',
            hs_codes: [4560977, 2954567, 5678904],
            accompanying_shipments: 1,
            documents: [
                { name: '106318__3805026584_20240903_20240903091432.pdf', path: '/pdf/106318__3805026584_20240903_20240903091432.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
            ],
            goods: [
                { id: 1, name: 'Item #1', weight: 91 },
                { id: 2, name: 'Item #2', weight: 49 },
                { id: 3, name: 'Item #3', weight: 63 },
            ],
        },
        {
            id: 5,
            shipment_no: 624154,
            status: 'Rejected',
            register_date: '26-08-2024',
            waybill_no: 1047462619541260,
            importer: 'SE - Beijer Electronics (VChain)',
            hs_codes: [6789230, 7890345],
            accompanying_shipments: 1,
            documents: [
                { name: '106318__3805026584_20240903_20240903091432.pdf', path: '/pdf/106318__3805026584_20240903_20240903091432.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
                { name: '103907__Beijer_20240821113133.pdf', path: '/pdf/103907__Beijer_20240821113133.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
                { name: '106318_3805026584_DA70885356_MMA_20240902_124022_20240903055418.pdf', path: '/pdf/106318_3805026584_DA70885356_MMA_20240902_124022_20240903055418.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
            ],
            goods: [
                { id: 1, name: 'Item #1', weight: 71 },
                { id: 2, name: 'Item #2', weight: 67 },
            ],
        },
        {
            id: 6,
            shipment_no: 103100,
            status: 'Done',
            register_date: '16-08-2024',
            waybill_no: 10378426159381288,
            importer: 'SE - Beijer Electronics (VChain)',
            hs_codes: [4560977, 782567, 5678904, 5678965, 6789230],
            accompanying_shipments: 1,
            documents: [
                { name: '103907__GOT22125795_20240822_20240828062016.pdf', path: '/pdf/103907__GOT22125795_20240822_20240828062016.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
                { name: '104746_2619541260_DA70749057_MMA_20240826_113854_20240826113242.pdf', path: '/pdf/104746_2619541260_DA70749057_MMA_20240826_113854_20240826113242.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
            ],
            goods: [
                { id: 1, name: 'Item #1', weight: 71 },
                { id: 2, name: 'Item #2', weight: 68 },
                { id: 2, name: 'Item #3', weight: 67 },
                { id: 2, name: 'Item #4', weight: 76 },
                { id: 2, name: 'Item #5', weight: 81 },
            ],
        },
        {
            id: 7,
            shipment_no: 605581,
            status: 'Done',
            register_date: '16-08-2024',
            waybill_no: 1030101996066860,
            importer: 'SE - Beijer Electronics (VChain)',
            hs_codes: [4560977, 3494567, 5678904],
            accompanying_shipments: 2,
            documents: [
                { name: '103907__73110011058_20240821112811.pdf', path: '/pdf/103907__73110011058_20240821112811.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
                { name: '104746__2619541260_20240827_20240827073555.pdf', path: '/pdf/104746__2619541260_20240827_20240827073555.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
                { name: 'Document_pdf 103907__GOT22125795_20240822_20240828062016.pdf', path: '/pdf/103907__GOT22125795_20240822_20240828062016.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
            ],
            goods: [
                { id: 1, name: 'Item #1', weight: 81 },
                { id: 2, name: 'Item #2', weight: 122 },
                { id: 2, name: 'Item #3', weight: 51 },
            ],
        },
        {
            id: 8,
            shipment_no: 651471,
            status: 'Done',
            register_date: '16-08-2024',
            waybill_no: 103007819817075,
            importer: 'SE - Beijer Electronics (VChain)',
            hs_codes: [5678904, 5678965],
            accompanying_shipments: 1,
            documents: [
                { name: '104746_2619541260_DA70749057_MMA_20240826_113854_20240826113242.pdf', path: '/pdf/104746_2619541260_DA70749057_MMA_20240826_113854_20240826113242.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
                { name: '104746__2619541260_20240827_20240827073555.pdf', path: '/pdf/104746__2619541260_20240827_20240827073555.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
            ],
            goods: [
                { id: 1, name: 'Item #1', weight: 91 },
                { id: 2, name: 'Item #2', weight: 95 },
            ],
        },
        {
            id: 9,
            shipment_no: 381291,
            status: 'Done',
            register_date: '16-08-2024',
            waybill_no: 10300673110011000,
            importer: 'SE - Beijer Electronics (VChain)',
            hs_codes: [4560977, 5214567, 5678904, 5678965, 6789230],
            accompanying_shipments: 1,
            documents: [
                { name: '106318__3805026584_20240903_20240903091432.pdf', path: '/pdf/106318__3805026584_20240903_20240903091432.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
                { name: '103907__73110011058_20240821112811.pdf', path: '/pdf/103907__73110011058_20240821112811.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
            ],
            goods: [
                { id: 1, name: 'Item #1', weight: 61 },
                { id: 2, name: 'Item #2', weight: 80 },
                { id: 3, name: 'Item #3', weight: 85 },
                { id: 4, name: 'Item #4', weight: 74 },
                { id: 5, name: 'Item #5', weight: 78 },
                { id: 6, name: 'Item #6', weight: 59 },
            ],
        },
        {
            id: 10,
            shipment_no: 510640,
            status: 'Done',
            register_date: '16-08-2024',
            waybill_no: 1030051996066860,
            importer: 'SE - Beijer Electronics (VChain)',
            hs_codes: [4560977, 5678965, 6789230, 7890345],
            accompanying_shipments: 1,
            documents: [
                { name: '103907__73110011058_20240821112811.pdf', path: '/pdf/103907__73110011058_20240821112811.pdf', type: 'pdf', date: '15:36, Sep-13-2022' },
            ],
            goods: [
                { id: 1, name: 'Item #1', weight: 77 },
                { id: 2, name: 'Item #2', weight: 83 },
                { id: 3, name: 'Item #3', weight: 96 },
                { id: 4, name: 'Item #4', weight: 70 },
            ],
        },
    ],
};

export const getters = {
    list: state => state.list,
};