<template>
    <span class="relative z-0 inline-flex" :class="className">
        <button type="button" @click="emitSubmit()" class="relative inline-flex items-center btn-primary rounded-r-none rounded-l-lg border-0">
            {{ activeOption }}
        </button>

        <span class="relative block">
            <button @click="dropdownState = ! dropdownState" type="button" class="btn-dropdown-select hover:text-white inline-flex items-center relative px-3 py-2 rounded-r-md bg-green-700 rounded-r-lg text-white focus:z-10 focus:outline-none " aria-label="Expand">
                <fa v-if="!loading" class="h-5 w-5 text-xl" :icon="['fal','angle-down']" />
                <fa v-if="loading" class="h-5 w-5 text-xl fa-spin" :icon="['fad','spinner-third']" />
            </button>

            <transition
                enter-active-class="transition ease-out duration-100"
                enter-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">

                <div v-show="dropdownState" class="origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-lg" :class="getPositionClass">
                    <div class="rounded-lg bg-white shadow-xs border">
                        <div class="py-1">
                            <span v-for="(option, index) in options" :key="index" @click="activateOption(option)" class="block cursor-pointer px-4 py-4 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
                                {{ option }}
                            </span>
                        </div>
                    </div>
                </div>
            </transition>
        </span>
    </span>
</template>


<script>
export default {
    name: 'VButtonDropdown',

    props: {
        loading: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: []
        },
        position: {
            required: false,
            type: String,
            default: 'bottom' // Supports "bottom" & "top"
        },
        color: {
            type: String,
            default: '',
        }
    },

    data() {
        return {
            dropdownState: false,
            activeOption: null,
        };
    },

    created() {
        this.activeOption = this.options[0];
    },

    computed: {
        getPositionClass() {
            if (this.position === 'top') {
                return 'bottom-12'
            }

            return ''; // Bottom
        },
        className() {
            if (!this.color) {
                return '';
            }

            return `btn-dropdown-${this.color}`;
        }
    },

    methods: {
        activateOption(option) {
            this.activeOption = option;
            this.dropdownState = false;

            this.emitSubmit();
        },

        emitSubmit() {

            if (this.loading) {
                return false;
            }

            this.$emit('submit', this.activeOption)
        }
    }
}
</script>
