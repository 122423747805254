<template>
    <div class="main-layout layout-placeholder">
        <div class="container-fluid">
            <div class="row">
                <sidebar v-if="initPage" @isMin="isMin = $event" />
                <div class="px-10 pt-6 pb-24 bg-gray-200 content content-page-mode flex flex__column flex__align-center flex__justify-center" :class="{ 'is-min': isMin }">
                    <img src="@/svg/placeholder-1.svg" class="mb-10"/>
                    <child class="relative z-1" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from "~/components/Sidebar";

import { adjustColor } from '~/utils/color.js';

export default {
    name: "PlaceholderLayout",

    data: () => ({
        initPage: false,
        isMin: false,
    }),

    computed: {
        user() {
            return this.$store.getters["auth/user"];
        },
    },

    mounted() {
        this.isMin = Boolean(+this.$cookies.get('is-min'));

        this.setBranding();

        setTimeout(()=> { this.initPage = true });
    },

    components: {
        Sidebar,
    },

    methods: {
        setBranding() {
            const color = this.$cookies.get('brand_color') || '#2E457C';
            const logo = this.$cookies.get('logo_url') || '';

            document.documentElement.style.setProperty('--brand-color', color);
            document.documentElement.style.setProperty('--brand-color-secondary', adjustColor(color));
            document.documentElement.style.setProperty('--logo', logo);
        },
    },
}
</script>

<style lang="scss">
.layout-placeholder {
    img {
        max-width: 100%;
    }

    p {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 850px;
        text-align: center;
    }
}
</style>