<template>
  <div class="pb-3 mb-5 leading-tight border-gray-600 app-page-header">
    <div class="flex items-center app-page-header__container">
      <div class="flex items-center">

        <button v-if="includeBack" @click="$router.go(-1)" class="flex items-center justify-center w-10 h-10 mr-2 text-2xl text-white rounded-full btn-back" :class="{'no-branding bg-blue-900': !branding }">
          <fa :icon="['fal','arrow-left']" />
        </button>

        <router-link v-if="routeName" :to="routeName">
          <span class="text-body color-text-secondary">{{ subtitle }}</span>
          <div class="flex flex__align-center">
            <h2 class="text-header-primary">
              {{ title }}
            </h2>
            
            <slot name="info"></slot>
          </div>
        </router-link>

        <div v-else>
            <span class="text-body color-text-secondary">{{ subtitle }}</span>
            <div class="flex flex__align-center">
              <h2 class="text-header-primary">
                {{ title }}
              </h2>
              
              <slot name="info"></slot>
            </div>
        </div>
      </div>

      <div class="ml-auto app-page-header__slot-button">
          <slot name="button" />
      </div>

      <div v-if="$slots.tabs" class="ml-auto header-tabs">
        <slot name="tabs" />
      </div>

      <div v-if="$slots.tools" class="ml-10 -mb-1">
        <slot name="tools" />
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: String,
    subtitle: String,
    noBorder: Boolean,
    includeBack: Boolean,
    button: String,
    branding: {
        type: Boolean,
        default: true
    },
    routeName: String,
  }
}
</script>

<style lang="scss" scoped>
    .header-tabs {
        @apply mt-auto flex;

        div, a {
            @apply inline-block mr-8 text-gray-500 pb-3 -mb-3 cursor-pointer;

            &.active {
                @apply border-b-2 border-gray-900 text-gray-900;
            }

            &:hover {
                @apply text-gray-600;

                text-decoration: none;
            }
        }

        div:last-child,a:last-child {
            @apply mr-0
        }
    }
</style>
