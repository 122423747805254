<template>
    <transition name="fade">
        <div v-if="show" class="overlay" @click.self="hide()">
            <transition name="fade">
                <div class="overflow-visible card modal" :class="{'modal-lg': size === 'md'}">
                    <div class="flex-grow-0 bg-white border-0 card-header">
                        <h2 class="w-full px-4 py-2 -mx-4 font-normal">Add new markup</h2>

                        <button class="ml-auto text-gray-500 close-modal-btn" @click="hide">
                            <svg-importer icon-name="icons/close" />
                        </button>
                    </div>

                    <div slot="body" class="flex flex-wrap p-6 border-t border-gray-500">
                        <div class="w-1/3">
                            <modal-field label="Carrier" class="mr-4">
                                <multiselect
                                    :value="carrier"
                                    :options="carrierOptions"
                                    label="name"
                                    track-by="id"
                                    @select="updateCarrier"
                                >
                                </multiselect>
                            </modal-field>
                        </div>
                        <div class="w-1/3">
                            <modal-field label="Carrier service" class="mr-4">
                                <multiselect
                                    v-model="carrierService"
                                    :options="carrierServiceOptions"
                                    multiple
                                    label="name"
                                    track-by="id"
                                    :disabled="!carrier"
                                    :class="{'outline-red-500' : submitter && $v.carrierService.$invalid }"
                                >
                                </multiselect>
                            </modal-field>
                        </div>

                        <div class="w-1/3">
                            <modal-field label="Fixed mark-up, SEK" class="mr-4">
                                <input type="number" :value="fixed" @change="updateFixed($event.target.value)">
                            </modal-field>
                        </div>

                        <div class="w-1/3 mt-6">
                            <label class="color-grey-65 f-size-14 flex flex__align-center cursor-pointer mr-4">
                                Available in PL&T and Bookings

                                <v-checkbox :value="bookingEnabled" class="ml-4" @click="updateBookingEnabled" />
                            </label>
                        </div>

                        <div class="w-1/3 mt-4">
                            <modal-field label="Mark-up rate, %" class="mr-4">
                                <input type="number" :value="rate" @change="updateRate($event.target.value)">
                            </modal-field>
                        </div>
                    </div>

                    <MarkupGeographySpecificsTable />

                    <div class="flex p-4 mt-auto">
                        <div slot="footer" class="w-full py-4 pr-6 text-right">
                            <button class="btn-primary" :disabled="loading" @click="save">
                                Add markup
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>


<script>
import VCheckbox from '~/components/VCheckbox.vue';
import MarkupGeographySpecificsTable from './MarkupGeographySpecificsTable.vue';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'AddMarkupModal',
    props: {
        show: Boolean,
        size: String,
        loading: Boolean,
        carrierServicesExclude: {
            type: Array,
            default: () => [],
        },
    },
    components: { VCheckbox, MarkupGeographySpecificsTable },

    validations: {
        carrierService: {
            required,
        },
    },

    data: () => ({
        submitter: false,
        carrierService: [],
    }),

    computed: {
        carrier() {
            return this.$store.getters['markups/carrier'];
        },
        carrierOptions() {
            // return only carriers with service options available
            return this.$store.getters['markups/carrierOptions'].filter(carrier => {
                return this.$store.getters['markups/carrierServiceAll']
                    .find(
                        service => service.carrier_id === carrier.id && !this.carrierServicesExclude.includes(service.id)
                    );
            });
        },
        carrierServiceState() {
            return this.$store.getters['markups/carrierService'];
        },
        carrierServiceOptions() {
            return this.$store.getters['markups/carrierServiceOptions']
                .filter(service => !this.carrierServicesExclude.includes(service.id));
        },
        bookingEnabled() {
            return this.$store.getters['markups/bookingEnabled'];
        },
        rate() {
            return this.$store.getters['markups/rate'];
        },
        fixed() {
            return this.$store.getters['markups/fixed'];
        },
        dispatch() {
            return this.$store.dispatch;
        },
    },

    watch: {
        show: {
            immediate: true,
            handler(value) {
                if (!value) {
                    return;
                }

                this.init();
            },
        },
        carrierService(newVal) {
            this.dispatch('markups/updateCarrierService', newVal);
        },
    },

    methods: {
        async init() {
            this.$store.dispatch('markups/initState');
        },
        async save() {
            this.submitter = true;

            if (this.$v.$invalid) {
                return;
            }

            this.$emit('save', {
                fixed: this.fixed,
                rate: this.rate,
                booking_enabled: this.bookingEnabled,
                carrier_service: this.carrierServiceState.map(service => service.id),
                geography_specific_rates: this.$store.getters['markups/geographySpecificRates'],
            });
        },
        updateRate(value) {
            this.dispatch('markups/updateRate', value);
        },
        updateFixed(value) {
            this.dispatch('markups/updateFixed', value)
        },
        updateCarrier(value) {
            this.dispatch('markups/updateCarrier', value);
        },
        updateBookingEnabled(value) {
            this.dispatch('markups/updateBookingEnabled', value);
        },
        clearForm() {
            this.carrierService = [];
            this.dispatch('markups/resetState');
        },
        hide() {
            this.submitter = false;
            this.clearForm();
            this.$emit('hide');
        },
    },
};
</script>
