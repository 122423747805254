<template>
    <svg width="96" height="96" viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M117.675 49.8027L121.175 46.1992C122.205 45.3984 122.205 43.7969 121.175 42.7959L120.146 41.7949C119.116 40.7939 117.469 40.7939 116.645 41.7949L112.939 45.1982C105.526 38.9922 96.2595 34.9883 85.7578 34.3877V27.7813H90.9057C92.3471 27.7813 93.3767 26.7803 93.3767 25.3789V23.7773C93.3767 22.5762 92.3471 21.375 90.9057 21.375H74.4325C73.197 21.375 71.9615 22.5762 71.9615 23.7773V25.3789C71.9615 26.7803 73.197 27.7813 74.4325 27.7813H79.1685V34.3877C55.4883 36.1895 36.75 55.4082 36.75 79.0313C36.75 103.855 57.3415 123.875 82.875 123.875C108.203 123.875 129 103.855 129 79.0313C129 67.8203 124.676 57.6104 117.675 49.8027ZM82.875 117.469C60.8421 117.469 43.3393 100.452 43.3393 79.0313C43.3393 57.8105 60.8421 40.5938 82.875 40.5938C104.702 40.5938 122.411 57.8105 122.411 79.0313C122.411 100.452 104.702 117.469 82.875 117.469ZM83.6987 91.8438C84.9342 91.8438 86.1696 90.8428 86.1696 89.4414V55.8086C86.1696 54.6074 84.9342 53.4063 83.6987 53.4063H82.0513C80.6099 53.4063 79.5804 54.6074 79.5804 55.8086V89.4414C79.5804 90.8428 80.6099 91.8438 82.0513 91.8438H83.6987Z" fill="currentColor"/>
        <circle cx="36.75" cy="36.75" r="35.875" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M46.2669 27.6819L44.9314 29.1388C44.1338 29.9364 43.104 29.8272 42.3692 29.4598L42.2454 29.3979L42.1412 29.3067C41.3907 28.65 40.3307 28.2576 39.2625 28.2576H33.8786C32.5356 28.2576 31.488 29.3543 31.488 30.5287C31.488 31.5791 32.2182 32.471 33.1832 32.7122L33.2103 32.719L41.8162 35.1099C45.407 36.0788 47.8788 39.3861 47.8788 43.091C47.8788 47.5901 44.2596 51.1043 39.7432 51.2255V54.9355C39.7432 56.216 38.6913 56.9716 37.7071 56.9716H35.7929C35.2774 56.9716 34.7384 56.8127 34.327 56.4013C33.9157 55.9899 33.7568 55.4509 33.7568 54.9355V51.2233C31.394 51.1511 29.144 50.3712 27.3577 48.9707C26.3466 48.2018 26.3375 46.7385 27.1135 45.9377L28.4489 44.4809C29.2466 43.6832 30.2764 43.7925 31.0112 44.1599L31.135 44.2218L31.2391 44.3129C31.9433 44.929 32.9771 45.2424 34.1179 45.2424H39.5018C40.8968 45.2424 41.8924 44.2158 41.8924 43.091C41.8924 42.0405 41.1621 41.1486 40.1972 40.9074L40.17 40.9006L31.5273 38.4995C27.9936 37.4122 25.5015 34.263 25.5015 30.5287C25.5015 26.0128 29.1617 22.336 33.7568 22.2721V18.5645C33.7568 17.5804 34.5123 16.5284 35.7929 16.5284H37.7071C38.2973 16.5284 38.7972 16.8127 39.1281 17.1436C39.459 17.4744 39.7432 17.9743 39.7432 18.5645V22.2824C42.0958 22.3915 44.2939 23.2932 46.0227 24.649C47.0337 25.4179 47.0428 26.8811 46.2669 27.6819ZM6 36.75C6 19.7632 19.7632 6 36.75 6C53.7367 6 67.5 19.7632 67.5 36.75C67.5 53.7367 53.7367 67.5 36.75 67.5C19.7632 67.5 6 53.7367 6 36.75ZM11.9864 36.75C11.9864 50.5111 22.9889 61.5136 36.75 61.5136C50.3947 61.5136 61.5136 50.5078 61.5136 36.75C61.5136 23.1086 50.3914 11.9864 36.75 11.9864C22.9922 11.9864 11.9864 23.1053 11.9864 36.75Z" fill="currentColor"/>
    </svg>

</template>

<script>
export default {
    name: 'LeadTimeIcon',
};
</script>
